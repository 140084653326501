export const headerData=
{    "A-IM":[
   "feed"
],
    "Accept": [
    "application/javascript",
    "application/json",
    "application/x-www-form-urlencoded",
    "application/xml",
    "application/zip",
    "application/pdf",
    "application/sql",
    "application/graphql",
    "application/ld+json",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoin",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.text",
    "audio/mpeg",
    "audio/vorbis",
    "multipart/form-data",
    "text/css",
    "text/html",
    "text/csv",
    "text/plain",
    "image/png",
    "image/jpeg",
    "image/gif"
    ],
    "Accept-Charset":["utf-8"],
    "Accept-Encoding":["gzip", "deflate"],
    "Accept-Language":["en-US"]
}

export const headerGridData={
    columnDefs: [
         
        {
          headerName: "Header",
          field: "header",
          //cellRenderer: 'headerSelectRenderer'
        },
        {
          headerName: "Value",
          field: "value",
          //cellRenderer: 'headerValueSelectRenderer'
        },
        {
          headerName: "Description", 
          field: "description",
          //cellRenderer: 'headerDescriptionRenderer',
          width: 275
        }


      ],
      rowData: [
        { check: "", header: "", value: "", description: "" },
      ],
      defaultColDef: {
        suppressKeyboardEvent: (event) =>  true
      }
    }



