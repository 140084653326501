import React, { Component } from 'react';
import './header.css';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Typeahead } from 'react-bootstrap-typeahead';
import Extension from '@material-ui/icons/Extension';
import Notifications from '@material-ui/icons/Notifications';
import Brightness7 from '@material-ui/icons/Brightness7';
import Brightness6 from '@material-ui/icons/Brightness6';
import LinearScale from '@material-ui/icons/Remove';
import ToggleOff from '@material-ui/icons/ToggleOff';
import ToggleOn from '@material-ui/icons/ToggleOn';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Group from '@material-ui/icons/Group';
import LiveHelp from '@material-ui/icons/LiveHelp';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Fullscreen from '@material-ui/icons/Fullscreen';
import Settings from '@material-ui/icons/SettingsRounded';
import Visibility from '@material-ui/icons/Visibility';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import MenuListComposition from '../../../rc-events/rc-events';
import { connect } from 'react-redux'
import { observer, inject } from 'mobx-react';
import {
  toggleEnvironmentPage,
  setEnv
} from '../../../rc-environment/redux'


const Header = inject('rootStore')(observer(
  class Header extends Component {

    state = {
      showMenu: false,
      hideHeader: false,
    };

    onExpand = () => {
      if (!document.webkitIsFullScreen) {
        var element = document.querySelector('.app');
        if (element.requestFullscreen)
          element.requestFullscreen();
        else if (element.mozRequestFullScreen)
          element.mozRequestFullScreen();
        else if (element.webkitRequestFullscreen)
          element.webkitRequestFullscreen();
        else if (element.msRequestFullscreen)
          element.msRequestFullscreen();

      } else {
        if (document.exitFullscreen)
          document.exitFullscreen();
        else if (document.mozCancelFullScreen)
          document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen)
          document.webkitExitFullscreen();
        else if (document.msExitFullscreen)
          document.msExitFullscreen();
      }
      this.setState({ fullscreen: !this.state.fullscreen });
    }

    createNewTab = () => {
      this.props.createNewTab();
    }

    onMenuClick = (e) => {
      const { rootStore: { commonStore } } = this.props;
      if (e.target.id === 'login') {
        commonStore.toggleLoginPage(true);
      }

      if (e.target.id === 'logout') {
        commonStore.signOut();
      }
    }

    handleMenuClose = () => {
      this.setState({ showMenu: false });
    }

    handleMenuOpen = () => {
      this.setState({ showMenu: true });
    }

    handleThemeToggle = () => {
      const { rootStore: { commonStore } } = this.props;
      commonStore.toggleNightMode(!commonStore.nightMode);
    }

    isNightModeEnabled() {
      const { rootStore: { commonStore: { nightMode } } } = this.props;
      return nightMode;
    }

    hideHeader = () => {
      this.setState({ hideHeader: !this.state.hideHeader });
    }

    onEnvironmentClick = () => {
      //const { rootStore: { environmentStore } } = this.props;
      const { showEnvironmentPage } = this.props;
      this.props.toggleEnvironmentPage(!showEnvironmentPage);
    }

    getTabList = () => {
      const { tabList } = this.props;
      return tabList;
    }
    onEnvChange = (data = []) => {

      if (data.length > 0) {
        this.props.setEnv(data);
      }
    }

    render() {
      const {
        emptyLabel,
        showMenu,
      } = this.state;

      const { rootStore: {
        commonStore: { user },
        selectedEnvByUser = []
      }, classes = {} } = this.props;

      const { showEnvironmentPage } = this.props;



      return (
        <div className={`rcp__header ${classes.container}`}>
          <div className={`top-menu  ${this.state.hideHeader ? classes.headerHidden : ''} `}>
            <div className="menu-section__left">
              <Button color="primary" variant="contained" size="medium" className="button" style={{ margin: '5px 0', height: '30px' }} onClick={this.createNewTab} >
                <span style={{ fontSize: '10px' }}>Creat New API</span>
              </Button>
              <Tooltip id="tooltip-icon" title="Toggle to dark theme" placement="top" >
                <div >
                  <IconButton aria-label="brightness" disableRipple disableTouchRipple disabled >
                    {this.isNightModeEnabled() ?
                      <Brightness7 style={{ color: "#999" }} className="icon" /> :
                      <Brightness6 style={{ color: "#ddd" }} className="icon" />
                    }
                  </IconButton>
                  <IconButton color={`${this.isNightModeEnabled() ? 'primary' : 'primary'}`} aria-label="brightness" onClick={this.handleThemeToggle} disableRipple disableTouchRipple >
                    {this.isNightModeEnabled() ?
                      <ToggleOn style={{ fontSize: '35px' }} className="icon" /> :
                      <ToggleOff style={{ fontSize: '35px' }} className="icon" />}
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <div className="menu-section__center">
              <div className="env">
                <Typeahead
                  {...this.state}
                  emptyLabel={emptyLabel ? '' : undefined}
                  labelKey="label"
                  options={this.getTabList() || []}
                  placeholder="Environment"
                  bsSize="small"
                  clearButton
                  defaultSelected={selectedEnvByUser}
                  id={'header-typehead'}
                  onChange={this.onEnvChange}

                />
                <IconButton aria-label="extension" className="env__icon" onClick={this.onEnvironmentClick}>
                  <Visibility />
                </IconButton>
              </div>
            </div>
            <div className="menu-section__right">
              <Tooltip id="tooltip-icon" title="Published collections" placement="top" >
                <IconButton aria-label="extension">
                  <LibraryBooks className="icon" />
                </IconButton>
              </Tooltip>

              <Tooltip id="tooltip-icon" title="Extensions" placement="top" >
                <IconButton aria-label="extension">
                  <Extension className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip id="tooltip-icon" title="Notifications" placement="top" >
                <IconButton aria-label="notifications">
                  <Notifications className="icon" />
                  <MenuListComposition open={false} />
                </IconButton>

              </Tooltip>

              <Tooltip id="tooltip-icon" title="Help" placement="top" >
                <IconButton aria-label="Help">
                  <LiveHelp className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip id="tooltip-icon" placement="top" title={`${this.state.fullscreen ? 'Exit' : 'Expand to'} full screen`} >
                <IconButton aria-label="Delete" onClick={this.onExpand}>
                  {this.state.fullscreen ? <FullscreenExit className="icon" /> : <Fullscreen className="icon" />}
                </IconButton>
              </Tooltip>
              <Tooltip id="tooltip-icon" title="Settings" placement="top" >
                <IconButton aria-label="settings">
                  <Settings className="icon" />
                </IconButton>
              </Tooltip>


              <Tooltip id="tooltip-icon" title="Click to login" placement="top" >
                <div style={{ display: 'inline', position: 'relative', }}>
                  <IconButton aria-label="Delete" onClick={this.handleMenuOpen}>
                    {user.photoURL ?
                      <Avatar
                        alt={user.displayName}
                        src={user.photoURL}
                        className={classes.avtar}
                      /> :
                      <Avatar
                        alt="Anonymous User"
                      //src= {'/static/images/user3.png'}
                      >AU
                      </Avatar>
                    }
                  </IconButton>
                  <div style={{ position: 'absolute', right: '85px', top: '20px' }}>
                    <ClickAwayListener onClickAway={this.handleMenuClose}  >
                      <Grow in={showMenu} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                        <Paper className="query__method-list" style={!showMenu ? { overflow: 'hidden', height: '0px' } : {}} >
                          {user.isAnonymous ?
                            <MenuList role="menu">
                              <MenuItem id={'login'} onClick={this.onMenuClick}>Login</MenuItem>
                            </MenuList>
                            :
                            <MenuList role="menu">
                              <MenuItem id={'logout'} onClick={this.onMenuClick} >Logout</MenuItem>
                            </MenuList>
                          }
                        </Paper>
                      </Grow>
                    </ClickAwayListener>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.headerToggle}>
            <Tooltip id="tooltip-icon" title="hide header" placement="top" >
              <IconButton aria-label="hide-header" color="primary" onClick={this.hideHeader} >
                <LinearScale style={{ color: '#999', opacity: '0.6' }} className="icon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      );
    }
  }));

const styles = theme => ({
  container: {
    backgroundColor: theme.custom.rightMenuBackground[theme.palette.type],
    borderBottom: theme.custom.border[theme.palette.type]
  },
  headerHidden: {
    overflow: 'hidden !important',
    height: '3px !important',
    padding: '0px 0 !important',
    visibility: 'hidden'
  },
  headerToggle: {
    left: "48%",
    button: "0px",
    bottom: "-13px",
    zIndex: "5",
    position: "absolute"
  },
  avtar: {
    border: '0'
  }
});

const HeaderWrap = inject("rootStore")(observer(Header));
//export default withStyles(styles)(HeaderWrap)

const state = (state) => {
  const { showEnvironmentPage, selectedEnvByUser, tabList } = state.environmentStore;
  return {
    showEnvironmentPage,
    selectedEnvByUser,
    tabList
  }
}
const mapDispatch = (dispatch) => {
  return {
    toggleEnvironmentPage: (d) => dispatch(toggleEnvironmentPage(d)),
    setEnv: (d) => dispatch(setEnv(d))
  }
}
export default connect(state, mapDispatch)(withStyles(styles)(HeaderWrap))

