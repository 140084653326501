
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const SubList = ({ open, data = [], style, onClick, onActionClick, item, index }) => {

  let inputRef = null;

  const handleKeyPress = (e,node) => {
    if (e.key === 'Enter') { 
      onActionClick(e, {id: node.parent_id}, inputRef.value, 'addAPI',node);
    }
  }

  return open ? (<div style={style} >
    <List component="div" disablePadding>
      {data.map((node, index) => {
        return !node.editable ? (
          <ListItem className="collections__subitem" key={index + 'item' + node.id} id={node.id} onClick={() => onClick(node, item)}>
            <div className="collections__api" draggable="true">
              <div className="api__method"  >{(node.request || {}).method || node.method}</div><Typography className="api__name" variant="body1">:{node.label}</Typography></div>
            <div className="action">
              <Icon id={node.id} className="action__icon" onClick={(e) => onActionClick(e, {id: node.parent_id}, "", "", node)} >more_horiz</Icon>
            </div>
          </ListItem>
        ) :
          <ListItem className="collections__subitem" key={index + 'item' + node.id}   >
            <input className={'editable_name'} defaultValue={node.label} ref={(v) => inputRef = v} onKeyPress={(e)=>handleKeyPress(e,node)} />
            <CheckIcon id={node.id} className="api_save_icon" onClick={(e) => onActionClick(e, {id: node.parent_id}, inputRef.value, 'addAPI', node)} />
          </ListItem>

      })}
    </List>
  </div>) : "";
}

export default SubList;