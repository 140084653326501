export const headerData = []

export const headerGridData = {
  columnDefs: [
    {
      headerName: "",
      field: "check",
      rowDrag: true,
      width: 50
    },
    {
      headerName: "Key",
      field: "key",
      editable: true
    },
    {
      headerName: "Value",
      field: "value",
      editable: true
    },
    {
      headerName: "Description",
      field: "description",
      editable: true,
      width: 275
    }

  ],
  rowData: [
    { id: new Date().getTime(), check: "", header: "", value: "", description: "" },
  ],
  defaultColDef: {
    suppressKeyboardEvent: (event) => true
  }
}



