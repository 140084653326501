import React, { useState } from "react";
import './code-editor.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import {ControlledEditor} from "@monaco-editor/react";
import { SwapSpinner as Loader } from "react-spinners-kit";
 
function NanoEditorWrapper(props) {
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState(props.mode);
  const [isEditorReady, setIsEditorReady] = useState(false);
 
  function handleEditorDidMount(instance) {
    setIsEditorReady(true);
      if(props.onLoad){
          console.log({instance})
         //props.onLoad(instance);
        }
  }
 
   const onChange = (changes,value) => {  
       
       if(props.onChange){
       props.onChange(value);
       }
    }
 
  return (
    <> 
      <ControlledEditor
        height={props.expanded?'90vh': (props.splitView?'50vh':`54vh`)}
        theme={theme}
        language={props.mode}
        //loading={<Loader size={40}  color="#43a047"/>}
        onChange={onChange}
        value={props.value}
        editorDidMount={handleEditorDidMount}
        options={{
          autoFindInSelection: 'multiline'
        }}
      />
    </>
  );
};
  

NanoEditorWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    expanded:  PropTypes.bool,
    theme:  PropTypes.string,
    mode:  PropTypes.string,
};


NanoEditorWrapper.defaultProps={
    mode:'json',
    expanded: false,
    theme: 'xcode'
}

const styles = theme =>{ 
    NanoEditorWrapper.defaultProps.theme=theme.custom.editorTheme[theme.palette.type];
    return {
    root: {
        display: 'flex',
        width: '100%',
    }
  };
}

export const CodeEditor= withStyles(styles)(NanoEditorWrapper);

