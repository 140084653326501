import { decorate, observable, computed, action, autorun, toJS, get, set } from "mobx";
import * as _ from 'lodash';
import treeLookup from '../services/tree-lookup';
import { dbService } from '../../rc-core';
import { uniqueIDService, httpClient, localDBService } from '../../rc-core';


class RcStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    menuOptionsValue = {};
    storeUpdated = ""

    teams = [];

    selectedTeam = {};

    teamPage = {};

    teamData = [];

    isLoadingTeamData = false;

    isLoading = false

    unsubscribeListenerOnSelectedTeam = null;

    setMenuOptions = (style) => {
        console.log({ style });
        this.menuOptionsValue = style;
    }

    get menuOptions() {
        return toJS(this.menuOptionsValue);
    }


    notifySidePanel = () => {
        this.storeUpdated = new Date().getTime();
    }

    getAPIbyParentId(pid) {

    }

    toggaleExpand(item, list) {
        if (list) {
            treeLookup.updateNode(list, item);
            //this.rootStore.commonStore.apiData=data;
            this.notifySidePanel();
        } else {
            const data = treeLookup.updateNode(this.rootStore.commonStore.apiData, item);
            this.rootStore.commonStore.apiData = data;
            this.notifySidePanel();
        }
    }

    addCollection(doc) {
        console.log({ doc })
        const data = treeLookup.updateCollection(this.rootStore.commonStore.apiData, doc);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
        const app_id = this.rootStore.commonStore.appID;
        const created = doc.created ? doc.created : new Date().getTime();
        const document = this.removeExtraFields({ app_id, ...doc, created });
        dbService.addCollection(document);//TODOD remove this
        localDBService.addCollection(document);
    }

    removeExtraFields(doc) {
        const extraFields = ['open', 'showData', 'children', 'data', 'cl   oned', 'editable', 'changed', 'pid'];
        let keys = _.keys(doc);
        keys = _.without(keys, ...extraFields);
        const obj = {};
        keys.map(key => {
            obj[key] = doc[key];
        });
        return obj;
    }

    addSubCollection(item, doc) {
        console.log({ item, doc });
        const data = treeLookup.addEmptySubCollection(this.rootStore.commonStore.apiData, item, doc);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
        //dbService.addCollection(doc);

    }

    onAddCollectionBulk(docs) {
        const app_id = this.rootStore.commonStore.appID;
        const created = new Date().getTime();
        (docs.children || []).map(doc => {
            doc.app_id = app_id;
            doc.created = created;
            doc.parent_id = docs.id;
            doc.data.map(api => {
                api.app_id = app_id;
                api.created = created;
                api.label = api.name;
                doc.collections_id = docs.id;
                doc.parent_id = docs.id;
                api.response = api.response || {}
                api.request.headers = [{
                    check: "", header: "Content-Type", value: "application/json", description: ""
                }]
            });
        });

        console.log({ data: this.rootStore.commonStore.apiData, docs });

        this.rootStore.commonStore.apiData.push(docs);

        this.prapareBulkData(_.cloneDeep(docs));
        this.notifySidePanel();

    }

    prapareBulkData(item) {
        const app_id = this.rootStore.commonStore.appID;

        let collectionList = [];
        let apiList = [];
        treeLookup.extractColectionAndApi(item, collectionList, apiList, app_id);


        collectionList = collectionList.map(doc => {
            const created = doc.created ? doc.created : new Date().getTime();
            const targetdoc = this.removeExtraFields({ app_id, ...doc, created })
            return targetdoc;
        });

        apiList = apiList.map(doc => {
            const created = doc.created ? doc.created : new Date().getTime();
            const targetdoc = this.removeExtraFields({ app_id, ...doc, created })
            return targetdoc;
        });

        dbService.addCollectionBatch(collectionList, 'collections');
        dbService.addCollectionBatch(apiList, 'api');

        localDBService.addCollection(collectionList);
        localDBService.addDocument(apiList);


    }

    prapareBulkDeleteData(item) {
        const app_id = this.rootStore.commonStore.appID;

        let collectionList = [];
        let apiList = [];
        treeLookup.extractColectionAndApi(item, collectionList, apiList, app_id);


        collectionList = collectionList.map(doc => {
            const created = doc.created ? doc.created : new Date().getTime();
            const targetdoc = this.removeExtraFields({ app_id, ...doc, created })
            return targetdoc;
        });

        apiList = apiList.map(doc => {
            const created = doc.created ? doc.created : new Date().getTime();
            const targetdoc = this.removeExtraFields({ app_id, ...doc, created })
            return targetdoc;
        });

        dbService.deleteCollectionBatch(collectionList, 'collections');
        dbService.deleteCollectionBatch(apiList, 'api');

        localDBService.deleteCollections(collectionList);
        localDBService.deleteDocuments(apiList);

    }

    updateSubCollection(doc) {

        const app_id = this.rootStore.commonStore.appID;
        const data = treeLookup.updateSubCollection(this.rootStore.commonStore.apiData, doc);
        this.rootStore.commonStore.apiData = data;

        const created = doc.created ? doc.created : new Date().getTime();

        const targetdoc = this.removeExtraFields({ app_id, ...doc, created });
        dbService.addCollection(targetdoc);
        localDBService.addCollection(targetdoc);
        this.notifySidePanel();
    }




    editCollection(item) {
        const data = treeLookup.editCollection(this.rootStore.commonStore.apiData, item);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
    }

    cloneCollection(item) {
        const clonedItem = treeLookup.cloneCollection(this.rootStore.commonStore.apiData, item);
        const data = treeLookup.updateClonedCollection(this.rootStore.commonStore.apiData, _.cloneDeep(clonedItem));

        this.rootStore.commonStore.apiData = data;
        this.prapareBulkData(_.cloneDeep(clonedItem));
        this.notifySidePanel();
    }

    dropCollection(item) {
        const container = treeLookup.fetchAndDropCollection(this.rootStore.commonStore.apiData, item);
        this.rootStore.commonStore.apiData = container.apiData;
        if (container.foundNode) {
            this.prapareBulkDeleteData(container.foundNode);
        }
        this.notifySidePanel();
    }

    addEmptyAPI(item) {
        const data = treeLookup.addEmptyAPI(this.rootStore.commonStore.apiData, item);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
    }


    addAPI(item, apiNode) {
        const data = treeLookup.addAPI(this.rootStore.commonStore.apiData, item, apiNode);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
        const app_id = this.rootStore.commonStore.appID;
        const created = apiNode.created ? apiNode.created : new Date().getTime();
        dbService.addDocument({ app_id, collections_id: item.id, ...apiNode, created });
        localDBService.addDocument({ app_id, collections_id: item.id, ...apiNode, created });
    }

    editAPI(item, apiNode) {
        const data = treeLookup.editAPI(this.rootStore.commonStore.apiData, item, apiNode);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
    }

    updateAPI(item, apiNode) {

        treeLookup.updateApiNode(this.rootStore.commonStore.apiData, { id: item.pid || item.parent_id }, apiNode);
        this.notifySidePanel();
        const app_id = this.rootStore.commonStore.appID;
        const targetdoc = this.removeExtraFields({ app_id, ...apiNode });
        //TOT remove pid stuff from doc payload
        dbService.addDocument(targetdoc);
        localDBService.addDocument(targetdoc);
    }

    cloneAPI(item, apiNode) {
        const data = treeLookup.cloneAPI(this.rootStore.commonStore.apiData, item, apiNode);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
    }

    deleteAPI(item, apiNode) {
        const data = treeLookup.deleteAPI(this.rootStore.commonStore.apiData, apiNode);
        this.rootStore.commonStore.apiData = data;
        this.notifySidePanel();
        dbService.deleteAPI(apiNode);
        localDBService.deleteAPI(apiNode);
    }

    async bookmarkAPI(item, apiNode) {
        await httpClient.addBookmark(apiNode);
    }

    async getBookmarks(item, apiNode) {
        return await httpClient.getBookmarks();
    }


    syncCollection(apiData) {
        this.rootStore.commonStore.apiData = apiData;
    }

    get collections() {
        return this.rootStore.commonStore.apiData || [];
    }

    async createTeam(team) {
        const user = this.rootStore.commonStore.getUser();
        const id = uniqueIDService.getUniqueID("team");
        const created = new Date().getTime();
        const data = { ...team, id, createdBy: user.uid, createdByUserName: user.displayName || user.email, created };

        const doc = await dbService.createOrUpdateTeam(data);
        if (doc) { return doc; }
        return data;
    }

    async loadTeams() {
        const user = this.rootStore.commonStore.getUser();
        this.isLoadingTeamData = true;
        const docs = await dbService.getTeam(user.uid);
        this.teams.length = 0;
        docs.forEach((doc) => {
            //console.log(doc.id, " => ", doc.data());
            this.teams.push(doc.data());

        });
        /**Set first team selected by default */
        if (this.teams.length > 0 && _.isEmpty(this.selectedTeam)) {
            this.setSelectedTeam(this.teams[0]);
        }

        this.isLoadingTeamData = false;
        return toJS(this.team);
    }

    setSelectedTeam = (team = {}) => {
        this.selectedTeam = _.find(this.teams, { id: team.id });
        this.teamData = [];
        this.notifySidePanel();
        this.syncSlelctedTeam(this.selectedTeam);
    }

    syncSlelctedTeam = (team = {}) => {
        const db = dbService.getDB();
        /**
         * Remove existing listener
         */
        if (this.unsubscribeListenerOnSelectedTeam) {
            this.unsubscribeListenerOnSelectedTeam();
        }

        if (_.isNil(team.collections)) {
            return;
        }
        /**
         * Add new listener
         */

        team.collections.map(collection => {
            db.collection("collections").where("id", "==", collection.id).orderBy("created").onSnapshot((collectionSnapshot) => {
                this.isLoadingTeamData = true;
                const colsData = collectionSnapshot.docs.map((data) => ({ ...data.data(), id: data.id }));
                setTimeout(() => {
                    this.loadData({ collection: colsData });
                }, 0);
                //console.log({colsData});

                collectionSnapshot.forEach(collection => {
                    db.collection("api").where("collections_id", "==", collection.id).orderBy("created").onSnapshot((apiSnapshot) => {
                        const api = apiSnapshot.docs.map((data) => ({ ...data.data(), id: data.id }));
                        setTimeout(() => {
                            this.loadData({
                                collection: { ...collection.data(), id: collection.id, data: api },
                                api
                            });
                        }, 0);
                        this.isLoadingTeamData = false;
                    })
                });
            });
        });

    }

    loadData = ({ collection }) => {
        this.rootStore.commonStore.processData(this.teamData, { collection });
        this.notifySidePanel();
    }

    getSelectedTeam = () => {
        return toJS(this.selectedTeam);
    }

    getTeams = () => {
        return toJS(this.teams);
    }

    getTeamData = () => {
        return toJS(this.teamData);
    }

    updateTeam = async (team) => {
        const findIndex = _.findIndex(this.teams, { id: team.id });
        if (findIndex > -1) {
            this.teams[findIndex] = team;
            this.setSelectedTeam(team);
        } else {
            this.teams.push(team);
        }


        try {
            const doc = await dbService.createOrUpdateTeam(team);
            if (doc) { return doc; }
        } catch (e) { console.log(e) }
        return team;
    }

    setTeamPageProps = (props) => {
        this.teamPage = { ...this.teamPage, ...props };
    }

    setLoading = (status) => {
        this.isLoading = status || false;
    }

    filterSelected = (view, action) => {
        this.rootStore.commonStore.filterSelected(view, action);
    }

}

decorate(RcStore, {
    // apiData: observable,
    menuOptionsValue: observable,
    isLoadingTeamData: observable,
    isLoading: observable,
    setMenuStyle: action,
    collections: computed,
    setCollection: action,
    menuOptions: computed,
    storeUpdated: observable,
    updateAPI: action,
    notifySidePanel: action,
    teams: observable,
    teamPage: observable,
    selectedTeam: observable,
    loadTeams: action,
    getSelectedTeam: action,
    setSelectedTeam: action,
    getTeams: action,
    bookmarkAPI: action,
    filterSelected: action
});


export const RcSidePanelStore = RcStore;