import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import {observer, inject} from "mobx-react";
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
 
const MenuOptions = inject('rootStore')(observer(({rootStore, classes, onOptionSelect}) => {
    
    let  { open, item, apiNode }= rootStore.sidePanel.menuOptions;
   
    const getStyle=()=>{
        return {
           position: 'absolute',
           ...rootStore.sidePanel.menuOptions.menuStyle,
           zIndex: 999
        }
    }

    const handleClose=(e) =>{
        const isNotAction=(e.target.getAttribute("class")|| "").indexOf('action__icon')<0
        if(open && isNotAction){
         rootStore.sidePanel.setMenuOptions({open: false});
        }
    }

    const onClick=(e,id)=>{ 
        onOptionSelect(id, item, apiNode);
        rootStore.sidePanel.setMenuOptions({open: false}); 
    }
  
    return   (
        <ClickAwayListener onClickAway={handleClose} >
        <div  className={classes.menuRoot}  style={getStyle()}>
                <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}  >
                    <Paper className="query__method-list" style={!open ? { overflow: 'hidden'} : {}}  >
                        {apiNode?
                        <MenuList role="menu">
                            <MenuItem id={'editAPI'}    onClick={(e)=>onClick(e,'editAPI')} >
                            <ListItemIcon className={classes.icon}>
                                <Icon>edit</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset }} inset secondary="Edit API" />
                            </MenuItem>
                            <MenuItem id={'bookmarkAPI'} onClick={(e)=>onClick(e,'bookmarkAPI')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>bookmark</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary , inset: classes.textItemInset }} inset secondary="Bookmark" />
                            </MenuItem>  
                            <MenuItem id={'shareAPI'} onClick={(e)=>onClick(e,'shareAPI')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>share</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Share" />
                            </MenuItem>
                            <MenuItem id={'cloneAPI'} onClick={(e)=>onClick(e,'cloneAPI')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>file_copy</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Clone" />
                            </MenuItem>
                            <MenuItem id={'deleteAPI'} onClick={(e)=>onClick(e,'deleteAPI')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>delete</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Delete" />
                            </MenuItem>
                        </MenuList>
                        :
                        <MenuList role="menu">
                            <MenuItem id={'addFolder'} onClick={(e)=>onClick(e,'addFolder')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>create_new_folder</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary , inset: classes.textItemInset }} inset secondary="Add Folder" />
                            
                            </MenuItem>
                            <MenuItem id={'addAPI'}  onClick={(e)=>onClick(e,'addAPI')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>add</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary , inset: classes.textItemInset }} inset secondary="Add API" />
                            
                            </MenuItem>  
                            <MenuItem id={'editFolder'}  onClick={(e)=>onClick(e,'editFolder')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>edit</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Edit" />
                            </MenuItem>
                            <MenuItem id={'shareFolder'} onClick={(e)=>onClick(e,'shareFolder')}>
                            
                            <ListItemIcon className={classes.icon}>
                                <Icon>share</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary , inset: classes.textItemInset }} inset secondary="Share" />
                            
                            </MenuItem>
                            <MenuItem id={'cloneFolder'} onClick={(e)=>onClick(e,'cloneFolder')}>
                            
                            <ListItemIcon className={classes.icon}>
                                <Icon>file_copy</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Clone" />
                            
                             </MenuItem>
                            <MenuItem id={'deleteFolder'} onClick={(e)=>onClick(e,'deleteFolder')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>delete</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary, inset: classes.textItemInset  }} inset secondary="Delete" />
                            
                            </MenuItem>
                            <MenuItem id={'downloadFolder'} onClick={(e)=>onClick(e,'downloadFolder')}>
                            <ListItemIcon className={classes.icon}>
                                <Icon>cloud_download</Icon>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary , inset: classes.textItemInset }} inset secondary="Download" /> 
                            </MenuItem>
                        </MenuList>
                        }
                    </Paper>
                </Grow>
            
        </div>
        </ClickAwayListener>
    );
}));

const styles = theme => ({
    menuRoot: {
      margin: 0,
    },
    menuItemRoot: {
        height: '20px',
        minHeight: '20px',
    },
    textItemInset : {
        paddingLeft: 0
    }
  });

export default withStyles(styles)(MenuOptions);