import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CreateNew } from './create-new/create-new';
import FileUpload from './file-upload/file-uload';
import { Modal } from '../../../rc-core';
import Button from '@material-ui/core/Button';
import LiveCollection from './live-collection/live-collection';





const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: '0',
    color: 'inherit'
  },
  rootTabPanel: {
    minHeight: '400px',
    minWidth: '500px'
  },
  muiTabRoot: {
    fontSize: '0.7rem'
  },
  button: {
    fontSize: '0.7rem'
  },
  titleRoot: {
    display: 'flex',
    width: '100%',
    padding: '6px',
    justifyContent: 'space-between'
  },
  closeButton: {
    fontSize: '0.7rem',
    padding: '5px',
    margin: 0
  },
  closeButtonLabel: {
    fontSize: '1rem',
    fontWeight: 600
  }
}));

function AddCollectionTabPanel(props) {
  const { children, value, index } = props;
  const classes = useStyles();

  return (
    value === index && <div className={classes.rootTabPanel}>
      <Box p={3}>{children}</Box>
    </div>
  );
}

AddCollectionTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



export const AddCollection = function AddCollection(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onModalClose = (e) => {
    props.onModalClose(e);
  }

  const onAddCollectionConfirm = (data) => {
    props.onAddCollectionConfirm(data);
  }

  const onAddCollectionBulk= (data) =>{
      props.onAddCollectionBulk(data)
      props.onModalClose();
  }


  return (
    <Modal open={props.open}
      onClose={onModalClose}
      //classes={classes}
      title={<div /> }
      fullScreen={false}
  
    >
      <div className={classes.root}>

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Add Collection" icon={<AddCircleIcon />} className={classes.muiTabRoot} />
          <Tab label="Import Data" icon={<ImportExportIcon />} className={classes.muiTabRoot} />
          <Tab label="Live OpenAPI" icon={<DynamicFeedIcon />} className={classes.muiTabRoot} />
        </Tabs>

        <AddCollectionTabPanel value={value} index={0}>
          <CreateNew
            onAddCollectionConfirm={onAddCollectionConfirm}
            onModalClose={onModalClose}
          />
        </AddCollectionTabPanel>
        <AddCollectionTabPanel value={value} index={1}>
           <FileUpload  
           onAddCollectionBulk={onAddCollectionBulk}
           onModalClose={onModalClose}
           
           />
      </AddCollectionTabPanel>
        <AddCollectionTabPanel value={value} index={2}>
          <LiveCollection 
           onAddCollectionConfirm={onAddCollectionConfirm}
           onModalClose={onModalClose}
          />
      </AddCollectionTabPanel>

      </div>
    </Modal>
  );
}
