import React, { Component } from "react";
import "./app.css";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import RCPlatform from "./rc-platform/components/rc-platform";
import Grid from "@material-ui/core/Grid";
import { Login } from "./rc-login";
import { RCFooter } from "./rc-footer/rc-footer";
import theme from "./theme";
import { observer, inject } from "mobx-react";
import { Environment } from "./rc-environment";
import { Provider } from "react-redux";
import configureStore from "./configure-store";
import { httpClient, webSocketService } from "./rc-core";
import { isNil } from "lodash";

const store = configureStore();

const muitheme = (nightMode) => createMuiTheme(theme(nightMode));

const getStyle = (theme) => ({
  backgroundColor: theme.palette.background[theme.palette.type],
});

const App = inject("rootStore")(
  observer(
    class App extends Component {
      state = { hostLoaded: undefined };

      componentDidMount() {
        httpClient.locateHosts().then(() => {
          this.setState({ hostLoaded: true });
          //console.log({ host: httpClient.CLIHost });
          if (httpClient.CLIHost) {
            webSocketService.connect(httpClient.CLIHost);
          }
        });
      }

      render() {
        const {
          rootStore: {
            commonStore: { nightMode },
          },
        } = this.props;
        const theme = muitheme(nightMode);
        return (
          <React.Fragment>
            <CssBaseline />
            <Provider store={store}>
              <MuiThemeProvider theme={theme}>
                <Router>
                  <div
                    className={`app theme-${theme.palette.type}`}
                    style={getStyle(theme)}
                  >
                    {!isNil(this.state.hostLoaded) && (
                      <Grid>
                        <RCPlatform />
                        <Login />
                        <Environment />
                      </Grid>
                    )}
                  </div>
                </Router>
              </MuiThemeProvider>
            </Provider>
          </React.Fragment>
        );
      }
    }
  )
);

export default App;
