import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Highlight from "react-highlight.js";

const styles = (theme) => ({
  root: {
    padding: 0,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(1 / 2),
  },
  resetContainer: {
    padding: theme.spacing(1),
  },
});

function getSteps() {
  return ["Select campaign settings", "Create an ad group", "Create an ad"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return {
        heading:
          "Install nodejs, Skip in case you already have node environment setup.",
        content: (
          <span>
            install LTS version of nodejs from{" "}
            <a href="https://nodejs.org/en/download/" target="__blank">
              https://nodejs.org/en/download.
            </a>{" "}
            Verify using below command.
          </span>
        ),
        cmd: "npm --version",
        content2: "",
      };
    case 1:
      return {
        heading: "Install api sailor using below command ",
        content: "",
        cmd: "npm install -g apisailor-cli  --unsafe-perm=true --allow-root",
        content2:
          "Please go to troubleshooting section in case encounter any error.",
      };
    case 2:
      return {
        heading: "Start apisailor",
        content: "",
        cmd: "apisailor start",
        content2:
          "Please visit github page to know about all CLI related features.",
      };
    default:
      return "Unknown step";
  }
}

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {};

  handleBack = () => {};

  handleReset = () => {};

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div>
        <h4>
          Apisailor required CLI to operate from your browser.You can use
          desktop app in case you are having difficulty using CLI{" "}
          <a href="https://apisailor.com/download" target="__blank">
            https://apisailor.com/download{" "}
          </a>{" "}
          but we will recommend to stick with CLI.
        </h4>
        <Stepper
          activeStep={-1}
          orientation="vertical"
          connector={null}
          className={classes.root}
        >
          {steps.map((label, index) => {
            const data = getStepContent(index);
            return (
              <Step key={data.heading} active={true}>
                <StepLabel>{data.heading}</StepLabel>
                <StepContent>
                  {data.content && <Typography>{data.content}</Typography>}
                  {data.cmd && (
                    <Highlight
                      language={"javascript"}
                      className={"cli-box-main"}
                    >
                      <div>{data.cmd}</div>
                    </Highlight>
                  )}
                  {data.content2 && <Typography>{data.content2}</Typography>}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
