
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const PRIME_COLOR='#37A000';//#607D8B
const SECOND_COLOR='#37A000';
const DARK_BACKGROUND_COLOR='#252526'// '#212121';//'#022144'


const getPrimaryColor=(weight)=> green[weight];
const getSecondrayColor=(weight)=> grey[weight];

const theme= (type)=> ({
    "palette": {
        "common": {
            "black": DARK_BACKGROUND_COLOR,
            "white": "#fff"
        },
        "background": {
            "default": "#fff",
            "light": "#fff",
            "dark": DARK_BACKGROUND_COLOR
        },
        "primary": {
            "light": getPrimaryColor(200),
            "main": getPrimaryColor(600),
            "dark": getPrimaryColor(900),
            "contrastText": "#fff"
        },
        "secondary": {
            "light": getSecondrayColor(300),
            "main": getSecondrayColor(800),
            "dark": getSecondrayColor(900),
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": type==='light'? "#555":"#fff",
            "secondary": type==='light'? "#999": "#eeee",
            "disabled": "#ddd",
            "hint": getPrimaryColor(400),
            "contrastText": "#fff",
        },
        "paper": {
          "light": getPrimaryColor(200),
            "main": getPrimaryColor(600),
            "dark": getPrimaryColor(900),
      },
    }
})

const getType=(nightMode)=> (nightMode?'dark': 'light');

export default (nightMode)=> ({
    palette: {
      ...theme(getType(nightMode)).palette,
      type: getType(nightMode)
    },
    custom: {
      sidePanelBackground: {
        "light": "#f3f3f3",
        "dark": DARK_BACKGROUND_COLOR,
      },
      sidePanelScrollbar: {
        "light":  getPrimaryColor(700),
        "dark": '#eee',
      }
      ,
      rightMenuBackground: {
        "light": "#F9F9F9",
        "dark": DARK_BACKGROUND_COLOR,
      },
      border: {
        dark: "solid 1px #565151",
        light: "solid 1px #E0E0E0"     
      },
      editorTheme: {
        dark:'vibrant_ink',
        light: 'xcode'
      },
      gridTheme: {
        dark:'ag-theme-balham-dark',
        light: 'ag-theme-balham' 
      }
    },
    overrides: {
      MuiButton: { // Name of the component ⚛️ / style sheet
        root: { // Name of the rule
          minHeight: '28px',
          minWidth: '40px',
          padding: '3px 10px',
          margin:'0 2px',
          borderRadius: '1px',
        },
        contained: {
          color: '#fff'
        },
        fab: {
          width: '30px',
          height: '30px',
          backgroundColor: PRIME_COLOR
        },
        sizeSmall: {
          padding: '5px 8px',
          /* min-width: 61px; */
          fontSize: '12px',
          minHeight: '25px',
          //color: '#fff'
        }
      },
      MuiIconButton: {
        root: { 
          color: 'inherit',
           margin: '0 12px',
           padding: 0,
        },
        label:{
           padding: '5px'
        }
      },
      MuiIcon: { // Name of the component ⚛️ / style sheet
        root: { // Name of the rule
          //color: 'white', // Some CSS
          fontSize: '20px'
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: '22px',
        }
      },
      MuiAvatar: {
        root: {
          width: '35px',
          height: '35px',
          fontSize: '0.8rem',
          border: '1px solid #ddd',
        },
        colorDefault: {
          color: PRIME_COLOR,
          //backgroundColor: '#cecece'
        }
      },
      MuiTypography: {
        root: {
          fontSize: '0.9rem'
        },
        subheading: {
          //fontSize: '0.9rem !important',
          //fontWeight: 400
          color: 'red',
          fontSize: '0.9rem'
        }
      },
      MuiListItemText: {
        primary: {
          fontSize: '0.8rem',
          fontWeight: 500
        },
        root: {
          fontSize: '0.8rem',
          fontWeight: 500,
          padding: '4px 16px'
        },
        secondary: {
          fontSize: '0.6rem',
          fontWeight: 500,
        },
        inset: {
          '&:first-child': {
            paddingLeft: '10px'
          }
        }
      },
      MuiListItem: {
        default: {
  
        }
      },
      MuiMenuItem: {
        root: {
          width: 'auto',
          color: 'rgba(0, 0, 0, 0.87)',
          height: '20px',
          overflow: 'hidden',
          fontSize: '12px',
          boxSizing: 'content-box',
          fontWeight: '500',
          lineHeight: '1em',
          minHeight: '20px'
        }
      },
      
      MuiInput:{
        root:{
         fontSize:'14px'
        }
      },
      MuiTypography: {
        title:{
          fontSize:'16px'
         }
      },
      MuiPaper: {
        root:{
          //backgroundColor:nightMode?getSecondrayColor(500):'#fff'
        }
      },
      ScrollableTabsButtonAuto: {
        tabRoot : {
          minWidth: '100px'
        }
      }
    }
});