import React, { Component } from 'react';
import './finder.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ArrowRight from '@material-ui/icons/PlayArrow';
import Search from '@material-ui/icons/Search';
 


class Finder extends Component {
    state = {  expandedFilter: false};
    onFilterExpand=()=>{
        this.setState({expandedFilter: !this.state.expandedFilter});
      }
    
    
      getReplaceStyle() {
        return !this.state.expandedFilter? {
          height:0
        }:{};
      }
      
      onChange(...props) {
        console.log({change: props});
      }

      onSearch(...props) {
            console.log({searchP: props});   
      }

      onInputChange=(value)=> {
          this.props.onChange(value)
      }

    render() {

        const { classes = {} } = this.props
        return (
            <div className="filter__container">
 
                    <div className="filter" style={{}}>
                        <Search className="filter__icon" />
                        <ArrowRight className="filter__expand_icon" onClick={this.onFilterExpand} />
                        <Typeahead
                            {...this.state}
                            //emptyLabel={''}
                            labelKey="name"
                            options={[]}
                            placeholder="Find"
                            id={'find-typehead'}
                            onChange={this.onChange}
                            onSearch={this.onSearch}
                            onInputChange={this.onInputChange}
                        />
                        <div style={this.getReplaceStyle()} className="filter__expanded">
                            <Typeahead
                                {...this.state}
                                //emptyLabel={''}
                                labelKey="name"
                                options={[]}
                                placeholder="Replace"
                                id={'replace-typehead'}
                                onChange={this.onChange}
                                onSearch={this.onSearch}
                                onInputChange={this.onInputChange}
                            />
                        </div>

                    </div>
                    <Scrollbars
                        style={{ height: 550, display: 'none' }}>
                        <div className={classes.root}>

                        </div>
                    </Scrollbars>
            </div>
        );
    }
}

export default Finder;
