import { decorate, observable, computed, action, autorun, toJS } from "mobx";
import {dbService} from '../services/db-service';
import * as _ from 'lodash';
 

class Store {

    constructor(props) {
       this.rootStore=props;
    }

    isAnonymous=false;
    showLoginPage=false;
    user={};

    setLoginInfo=({ user, isAnonymous}) => {
       this.isAnonymous=isAnonymous;
       this.isLogedIn=!_.isEmpty(user);
       this.user=user;

       if(this.isLogedIn){
         this.showLoginPage=false;
       }
    }

}

decorate(Store, {
    setLoginInfo: action,
});


export const dbStore = Store;