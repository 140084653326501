export const headerData =
{
  "A-IM": [
    "feed"
  ],
  "Accept": [
    "application/javascript",
    "application/json",
    "application/x-www-form-urlencoded",
    "application/xml",
    "application/zip",
    "application/pdf",
    "application/sql",
    "application/graphql",
    "application/ld+json",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoin",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.text",
    "audio/mpeg",
    "audio/vorbis",
    "multipart/form-data",
    "text/css",
    "text/html",
    "text/csv",
    "text/plain",
    "image/png",
    "image/jpeg",
    "image/gif"
  ],
  "Accept-Charset": ["utf-8"],
  "Accept-Encoding": ["gzip", "deflate"],
  "Accept-Language": ["en-US"],
  "Accept-Datetime": [],
  "Access-Control-Request-Method":[],
  "Access-Control-Request-Headers":[],
  "Authorization":[],
  "Cache-Control":[],
  "Connection":[],
  "Content-Length":[],
  "Content-MD5":[],
  "Content-Type": [
    "application/javascript",
    "application/json",
    "application/x-www-form-urlencoded",
    "application/xml",
    "application/zip",
    "application/pdf",
    "application/sql",
    "application/graphql",
    "application/ld+json",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoin",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.text",
    "audio/mpeg",
    "audio/vorbis",
    "multipart/form-data",
    "text/css",
    "text/html",
    "text/csv",
    "text/plain",
    "image/png",
    "image/jpeg",
    "image/gif"
  ],
  "Cookie":[],
  "Date":[],
  "Expect":[],
  "Forwarded":[],
  "From":[],
  "Host":[],
  "If-Match":[],
  "If-Modified-Since":[],
  "If-None-Match":[],
  "If-Range":[],
  "If-Unmodified-Since":[],
  "Max-Forwards":[],
  "Origin":[],
  "Pragma":[],
  "Proxy-Authorization":[],
  "Range":[],
  "Referer":[],
  "TE":[],
  "User-Agent":[],
  "Upgrade":[],
  "Via":[],
  "Warning":[],
  "Upgrade-Insecure-Requests":[],
  "X-Requested-With":[],
  "DNT":[],
  "X-Forwarded-For":[],
  "X-Forwarded-Host": [],
  "X-Forwarded-Proto":[],
  "Front-End-Https":[],
  "X-Http-Method-Override":[],
  "X-ATT-DeviceId":[],
  "X-Wap-Profile":[],
  "Proxy-Connection":[],
  "X-UIDH":[],
  "X-Csrf-Token":[],
  "X-Request-ID":[],
  "X-Correlation-ID":[],
  "Save-Data":[],
  "Access-Control-Allow-Origin":[],
  "Access-Control-Allow-Credentials":[],
  "Access-Control-Expose-Headers":[],
  "Access-Control-Max-Age":[],
  "Access-Control-Allow-Methods":[],
  "Access-Control-Allow-Headers":[],
  "Accept-Patch":[],
  "Accept-Ranges":[],
  "Age":[],
  "Allow":[],
  "Alt-Svc":[],
  "Cache-Control":[],
  "Connection":[],
  "Content-Disposition":[],
  "Content-Encoding":[],
  "Content-Language":[],
  "Content-Length":[],
  "Content-Location":[],
  "Content-MD5":[],
  "Content-Range":[],
  "Date":[],
  "Delta-Base":[],
  "ETag":[],
  "Expires":[],
  "IM":[],
  "Last-Modified":[],
  "Link": [],
  "Location": [],
  "P3P": [],
  "Pragma": [],
  "Proxy-Authenticate": [],
  "Public-Key-Pins": [],
  "Retry-After": [],
  "Server": [],
  "Set-Cookie": [],
  "Strict-Transport-Security": [],
  "Trailer":[],
  "Transfer-Encoding":[],
  "Tk":[],
  "Upgrade": [],
  "Vary":[],
  "Via":[],
  "Warning":[],
  "WWW-Authenticate":[],
  "X-Frame-Options": [],
  "Content-Security-Policy":[],
  "X-Content-Security-Policy":[],
  "X-WebKit-CSP":[],
  "Refresh":[],
  "Status":[],
  "Timing-Allow-Origin":[],
  "X-Content-Duration":[],
  "X-Content-Type-Options":[],
  "X-Powered-By":[],
  "X-Request-ID":[],
  "X-Correlation-ID":[],
  "X-UA-Compatible":[],
  "X-XSS-Protection":[]


}

export const headerGridData = {
  columnDefs: [
    {
      headerName: "",
      field: "check",
      rowDrag: true,
      checkboxSelection: true,
      width: 70
    },
    {
      headerName: "Header",
      field: "header",
      cellRenderer: 'headerSelectRenderer'
    },
    {
      headerName: "Value",
      field: "value",
      cellRenderer: 'headerValueSelectRenderer'
    },
    {
      headerName: "Description",
      field: "description",
      cellRenderer: 'headerDescriptionRenderer',

      width: 275
    }


  ],
  rowData: [
    { check: "", header: "", value: "", description: "" },
  ],
  defaultColDef: {
    suppressKeyboardEvent: (event) => true
  }
}



