import React, { Component } from 'react';
import './rc-footer.css';
import { Modal, RcGrid } from '../rc-core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';

import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
 

const RCFooterClass= inject('rootStore')(observer(
    class RCFooterInner extends Component {

        state = { }
 
        render() {
            const { classes } = this.props;
            const { rootStore: {  } }= this.props;
            return (
                <div className={`rc-footer ${classes.root}`}>
                     

                </div>
            );
        }
    }));

const styles = theme => ({
    container: {
        borderBottom: theme.custom.border[theme.palette.type]
    },
    root: {
         height: '100%',
    },
    tabContainer: {
        display: 'flex'
    }

});

RCFooterClass.propTypes = {
    classes: PropTypes.object,
};



export  const RCFooter = withStyles(styles)(RCFooterClass);

