import React, { Component } from 'react';
import './rc-platform.css';
import SidePanel from '../../rc-side-panel/components/rc-side-panel';
import RcPlaygound from '../../rc-playground/components/rc-playground';
import Resizable from 're-resizable';
import ArrowBackward from '@material-ui/icons/ArrowBackIos';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import {RCFooter} from '../../rc-footer/rc-footer';


class RCPlatform extends Component {
  constructor(props) {
    super(props);
    this.prevWidth = 375;
  }

  MIN_WIDTH = 375;
  MAX_WIDTH = 700;

  state = {
    width: this.MIN_WIDTH,
    showSidePanel: true
  }

  getMainPanelStyle() {
    return {
      width: `calc(100% - ${this.state.width}px)`,
      position: 'relative',
      height: '90vh'
    }
  }

  onPanelToggle = () => {
    const { showSidePanel } = this.state;
    this.setState({
      showSidePanel: !showSidePanel,
      width: !showSidePanel ? this.prevWidth : 0
    });
  }

  render() {

    const { showSidePanel } = this.state;
    return <div  style={{
      display:'block',
      width: '100%'
    }} >
      <div className="rcp">
        <Resizable
          className="re-sizable"
          minWidth={showSidePanel ? this.MIN_WIDTH : 0}
          maxWidth={this.MAX_WIDTH}
          height='100%'
          ref={c => { this.resizable = c; }}
          size={{ width: this.state.width, height: this.state.height }}
          onResize={(e, direction, ref, d) => {
            const width = e.x;//this.state.width + d.width;
            if (width >= this.MIN_WIDTH && width <= this.MAX_WIDTH) {
              this.prevWidth = width;
              this.setState({
                width: e.x//this.state.width + d.width
              });

            }
          }}
        >

          <SidePanel />
          <IconButton
            aria-label="left-arrow"
            className={'rcp__panel_toggle-btn'}
            onClick={this.onPanelToggle}
          >
            <ArrowBackward className={'rcp__panel_toggle-icon'} />
          </IconButton>
        </Resizable>
        <div style={this.getMainPanelStyle()}>
          <IconButton
            aria-label="left-arrow"
            className={'rcp__panel_toggle-btn'}
            style={{ left: 0 }}
            onClick={this.onPanelToggle}
            style={{ display: showSidePanel ? 'none' : 'block', left: 0 }}
          >
            <ArrowForward className={'rcp__panel_toggle-icon'} />
          </IconButton>
          <RcPlaygound />
        </div>
        
      </div>
      <div className="rcp__footer">
       <RCFooter />
       </div>
      </div>
  }
}

export default RCPlatform;
