import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
       
    },
    button: {
        margin: '10px 5px',
        color: '#fff !important',
    }, 
    cardRoot: {

    }
}));


export const CreateNew = function CreateNew(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        name:"",
        description:"",
        serverUrl:"",
        version:"",
    });


    const handleNameChange = (e) => {
        const value = e.target.value;
        setState({...state,name: value});
    }

    const handleDescChange = (e) => {
        const value = e.target.value;
        setState({...state,description: value});
    }

    const handleServerChange = (e) => {
        const value = e.target.value;
        setState({...state,serverUrl: value});
    }

    const handleVersionChange = (e) => {
        const value = e.target.value;
        setState({...state,version: value});
    }

    const onAddCollectionConfirm = (e) => {
        const {name,description, serverUrl, version} =state;
        props.onAddCollectionConfirm({ collectionName:name, baseURL:serverUrl ,description,version});
        props.onModalClose(e);
    }
 

    return (
        <div className={classes.root}> 
                    <TextField
                        id="name"
                        label="Enter collection name"
                        className={classes.textField}
                        value={state.name}
                        onChange={handleNameChange}
                        fullWidth
                        margin="normal"
                    />

                  <TextField
                        id="Description"
                        label="Description"
                        className={classes.textField}
                        value={state.description}
                        onChange={handleDescChange}
                        fullWidth
                        margin="normal"  
                    />
             
                     <TextField
                        id="Server"
                        label="Server"
                        className={classes.textField}
                        value={state.serverUrl}
                        onChange={handleServerChange}
                        fullWidth
                        margin="normal"
                    />
                    
                     <TextField
                        id="Version"
                        label="Version"
                        className={classes.textField}
                        value={state.version}
                        onChange={handleVersionChange}
                        fullWidth
                        margin="normal"
                  
                    />
                
                    <Button
                        className={classes.button}
                        onClick={onAddCollectionConfirm}
                        variant="contained"
                        color="primary"
                    > Save </Button>
 
        </div>
    );
}

CreateNew.propTypes = {
    onAddCollectionConfirm: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
};