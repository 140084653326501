import React, { Component } from 'react';
import './script-view.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import AspectRatio from '@material-ui/icons/AspectRatio';
import SearchIcon from '@material-ui/icons/Search';
import { RcSelect,CodeEditor } from '../../../../rc-core';
import vkbuitify from 'vkbeautify';

import * as _ from 'lodash';
const CONTENT_TYPE = { JSON: "json", XML: "xml", HTML: 'html', TEXT: "text", JAVASCRIPT: 'javascript' }


class ResponseView extends Component {
    state = {
        expanded: false,
        mode:'text'
    };

    componentDidMount() {

    }

    toggleExpand = (e) => {
        e.stopPropagation();
        this.setState({ expanded: !this.state.expanded }, () => {
            window.document.scrollingElement.style.overflowY = this.state.expanded ? 'hidden' : 'auto';
        });
    }

    onLoad = (editor) => {
        this.editor = editor;
        
    }

    onModeChange = (mode) => {
        this.setState({ mode: mode.value },()=>{
            this.editor.setOption("wrap", true);
        }); 
    }

    onFormatt = () => {
        try{
        const session = this.editor.session;
        const mode=this.state.mode;
        if (mode=== CONTENT_TYPE.JSON) {
           
            let value =  session.getValue();
            value=this.format(value);
            session.setValue(value);

        } else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
            
        }
        else {
             
        }
        
       }catch(e){}
    }

    format = (value) => {try {

        const mode=this.state.mode  || this.getMode();
       
        if (mode=== CONTENT_TYPE.JSON) {
            const data = JSON.parse(value);
            const formattedData=JSON.stringify(data, null, '\t');
           
            return formattedData;
        } else if (mode=== CONTENT_TYPE.HTML) {
            /* TODO add formatter */
            const formattedData=vkbuitify.xml(value, '\t');
            
            return formattedData
        }
        else if (mode=== CONTENT_TYPE.XML) {
              /* TODO add formatter */
            const formattedData=vkbuitify.xml(value, '\t');
            return formattedData
        }
        else if (mode=== CONTENT_TYPE.TEXT) {
              /* TODO add formatter */
            const formattedData=value;
            return formattedData
        }
        else {
             return value;
        }
        

     }catch(e){ return value}}


    onJSONView = () => {
        this.clearView();
        this.setState({ showJsonView: !this.state.showJsonView });

    }

    clearView() {

    }

    onChange = (value) => {
        const type = this.getMode();
        this.props.setBody(value, type)
    }

    getMode() {
        let header = this.props.getPayloadData('request.headers');
        header = _.find(header, { header: 'content-type' }) || { value: "" }

        if (header.value.indexOf('application/json') > -1) {
            return CONTENT_TYPE.JSON
        } else if (header.value.indexOf('text/html') > -1) {
            return CONTENT_TYPE.HTML
        }
        else if (header.value.indexOf('text/plain') > -1) {
            return CONTENT_TYPE.TEXT
        }
        else if (header.value.indexOf('text/xml') > -1) {
            return CONTENT_TYPE.XML
        }
        else {
            return CONTENT_TYPE.TEXT
        }
    }

    getValue() {
        const value = "";
        return value;
    }

    onNodeAdd = (value) => {
    }

    getPreview() {
        const session = this.editor.session;
        const mode = this.state.mode;
        if (mode === CONTENT_TYPE.JSON) {


        } else if (mode === CONTENT_TYPE.HTML) {

        }
        else if (mode === CONTENT_TYPE.HTML) {

        }
        else if (mode === CONTENT_TYPE.HTML) {

        }
        else {

        }
    }
    
    getOptions=() => {
        const keys=_.keys(CONTENT_TYPE);
        const options= keys.map(key=> ({label:key, value:CONTENT_TYPE[key]}));
        return options;
    }

    onSearch=()=>{
        
        //this.editor.searchBox && this.editor.searchBox.show();
        this.editor.execCommand("find");
    }

    onRaw=()=> {
        this.setState({ mode: 'text'}, ()=>{
            this.editor.setOption("wrap", true)
        });  
    }
   
    onSave=()=>{


    }

    render() {
        const { classes } = this.props;
        const { expanded } = this.state;

        return (<div className={`${!expanded ? "script-view" : "script-view script-view--expanded"} ${classes.panel}`} >
            <div className={`r_panel`}>
                <div className="r_panel__header">
                    <RcSelect onChange={this.onModeChange} value={this.state.mode} options={this.getOptions()} />
                    <Button  size="small" className={  classes.button } onClick={this.onFormatt} >
                        <span style={{ fontSize: '10px'}}>Format</span>
                    </Button>
                    <Button   size="small" className={  classes.button }   onClick={this.onRaw} >
                        <span style={{ fontSize: '10px'}}>Raw</span>
                    </Button>
                    <IconButton aria-label="save" >
                        <SaveIcon className="r_panel__header__icon" onClick={this.onSave} />
                    </IconButton>
                    <IconButton aria-label="search" onClick={this.onSearch}  >
                        <SearchIcon className="r_panel__header__icon" />
                    </IconButton>
                    <IconButton aria-label="aspect" onClick={this.toggleExpand}    >
                        <AspectRatio className="r_panel__header__icon" />
                    </IconButton>
                </div>
                <div className="r_panel__body">
                    <CodeEditor
                        mode={this.state.mode}
                        onChange={this.onChange}
                        value={this.getValue()}
                        expanded={expanded}
                        onLoad={this.onLoad}
                    />
                </div>
            </div>
        </div>);
    }
}


ResponseView.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    button: {
        padding: '2px 0px',
        minWidth: '50px',
        fontSize: '12px',
        minHeight: '18px',
        background: '#e0e0e0',
        //backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
        margin: '3px 2px'
  },
  panel:{
    backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
  }
});

export default withStyles(styles)(ResponseView);

