import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import * as _ from 'lodash';

import EditButton from '@material-ui/icons/Edit';

const styles = theme => ({
  root: {
    marginTop: '20px',
    width: '100%',
    backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  description: {
    marginRight: '30px',
    minHeight:'50px',
    textAlign: 'left'
  },
  editIcon : {
    padding: '2px 15px',
    position:'absolute',
    right: '15px'
  },
 inputTextArea: {
  width: '100%',
  marginRight: '63px',
  minHeight: '100px'
 },
 editButton: {
  right: '10px',
  position: 'absolute'
 }

});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: true,
    editable: false
  };

  handleChange = (event) => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  onDescriptionEdit=() => {
    this.setState({editable: true});
  }

  componentWillReceiveProps(nextProps) {
    const  description=_.get(this.props, 'payload.description');
    if(description) {
      this.setState({description})
    }

  }

  onChange=(e) => {
    this.props.onDescriptionUpdate(this.textArea.value)
  }

  onDescriptionSave=() => {
      this.setState({editable: false});
      this.props.onSave();
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <ExpansionPanel expanded={expanded} onChange={this.handleChange}  className={classes.root}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {'Description'}
            </Typography>
            
          </ExpansionPanelSummary>

          {this.state.editable? 
          <ExpansionPanelDetails className={classes.container}>
            <textarea  onChange={this.onChange} className={classes.inputTextArea} ref={(v)=> this.textArea=v} 
            defaultValue={this.props.payload.description|| this.props.payload.label}
            />
            <Button  variant="contained" size="medium" color="primary"  className={classes.editIcon} onClick={this.onDescriptionSave} >
            {"Save"}
            </Button>
          </ExpansionPanelDetails>
          :
          <ExpansionPanelDetails className={classes.container}>
            <div className={classes.description} >
              {this.props.payload.description|| this.props.payload.label}
            </div>
            <Button className={classes.editButton} variant="contained" size="medium" color="primary"  onClick={this.onDescriptionEdit} >
              <EditButton className="icon" />
            </Button>
          </ExpansionPanelDetails>
           }
        </ExpansionPanel>   
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.object,
};

ControlledExpansionPanels.defaultProps = {
  payload: {}
};

export default withStyles(styles)(ControlledExpansionPanels);