import React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export class CreateNewTeam extends React.Component  {

    state = {
        newTeam: {}
    }

    onChange = (e) => {
        const obj = {};
        obj[e.target.id] = e.target.value;
        const newTeam = { ...this.state.newTeam, ...obj };
        this.setState({ newTeam });
    }

    onCreateNewTeam = () => {
        this.props.onCreateNewTeam(this.state.newTeam)
    }

    render() {
        const { classes } = this.props;
        const { onChange, onCreateNewTeam } = this;

        return (<div>
            <TextField
                id="name"
                label="Team Name"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder="Team Name"
                onChange={onChange}
                fullWidth
                margin="normal"
            />
            <TextField
                id="logo"
                label="Logo URL"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder="Logo url"
                onChange={onChange}
                fullWidth
                margin="normal"
            />
            <TextField
                id="organization"
                label="Organization"
                InputLabelProps={{
                    shrink: true,
                }}

                placeholder="Enter Organization Name "
                fullWidth

                margin="normal"
                onChange={onChange}
            />
            <TextField
                id="Description"
                label="Team Description"
                InputLabelProps={{
                    shrink: true,
                }}
                placeholder="Team Description"
                fullWidth
                margin="normal"
                onChange={onChange}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classNames(classes.button, classes.fbRoot)}
                onClick={onCreateNewTeam}
            >
                Create Team
          </Button>
        </div>);
    }
}
