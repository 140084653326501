import * as _  from 'lodash';
import rcStore from '../../store';

class FileIOServiceInner {

    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
        document.body.removeChild(element);
    }
    
}

export  const FileIOService=new FileIOServiceInner();
