//import './bookmarks.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { httpClient } from "../../../rc-core"
import SubList from '../list/sub-list';
import * as _ from 'lodash';

class Bookmarks extends Component {

    state = {
        bookmarks: []
    }

    async  componentDidMount() {
        const bookmarks = await httpClient.getBookmarks();
        this.setState({bookmarks: bookmarks||[] })
    }
    onDataListItemClick=() => {

    }

    openTabByValue=(node, pid)=> {
       
       const newNode={...node,t:1};
       console.log({newNode});
       this.props.openTabByValue(_.omit(newNode, ['_id']));
    }

    onDrop=(node, pid) => {

    }

    onDragover=()=>{

    }

    sortData() {
        const {filterBy="name", order="desc", valueFilter}= this.props.filters || {};
        return _.chain(this.state.bookmarks)   
         .orderBy([filterBy], [order])
         .filter(value=>  valueFilter?value.name.indexOf(valueFilter)>-1: true)
         .value() || [];
    }

    render() {
        const bookmarks= this.sortData();
        return (
            <SubList
            style={{ paddingLeft: `${20}px` }}
            key={`sub-bookmark`}
            item={bookmarks}
            data={bookmarks}
            open={true}
            editable={false}
            onClick={this.openTabByValue}
            onActionClick={this.props.onActionClick}
            onDrop={this.onDrop}
            onDragover={this.onDragover}
        />);
    }
}

const styles = theme => ({});

Bookmarks.propTypes = {
    classes: PropTypes.object,
    openTab: PropTypes.func,
    handleClick: PropTypes.func,
    onActionClick: PropTypes.func,
};

export default withStyles(styles)(Bookmarks);


