import React, { Component } from 'react';
import './rc-side-panel.css';
import { observer, inject } from 'mobx-react';
import Finder from './finder/finder';
import Header from './header/header';
import CollectionList from './list/list';
import SubList from './list/sub-list';
import MenuOptions from './menues/menues';
import * as _ from 'lodash';
import { uniqueIDService } from '../../rc-core';
import { withStyles } from '@material-ui/core/styles';
import Team from './team/team';
import { Scrollbars } from 'react-custom-scrollbars';
import History from './history/history';
import Bookmarks from './bookmarks/bookmarks';
import LinearProgress from '@material-ui/core/LinearProgress';

import Filters from './filters/filters';
import Skelton from './loader-skelton/loader-skelton';

class RCSidePanel extends Component {

  state = {
    expandedFilter: false,
    data: this.getData(),
    selectedView: 'list',
    orderDesc: 'desc',
    filterBy: 'date',
  };

  getStore() {
    return this.props.rootStore;
  }

  handleClick = (e, item, index) => {
    this.getStore().sidePanel.toggaleExpand(item);
  };

  onActionClick = (e, item, updatedValue, eventName, apiNode) => {

    if (!_.isEmpty(updatedValue) && !_.isEmpty(eventName)) {
      if (eventName === 'addFolder') {
        item.name = updatedValue;
        this.getStore().sidePanel.updateSubCollection(item);
      }
      else if (eventName === 'addAPI') {
        apiNode.name = updatedValue;
        apiNode.label = updatedValue;
        this.getStore().sidePanel.addAPI(item, apiNode);
      }
      return
    }
    this.setMenuOptions({ e, item, apiNode, eventName });

  }

  openTab = (node, pid) => {
    this.getStore().playground.openTab(node, pid);
  }

  openTabByValue = (tabValue) => {
    this.getStore().playground.openTabByValue(tabValue);
  }

  setMenuOptions({ e, item, apiNode, eventName }) {

    const menuOptions = {
      menuStyle: this.offset(e.target, eventName),
      showMenu: true,
      item: { id: item.id, name: item.name, level: item.level },
      open: true,
      apiNode
    };

    e.stopPropagation();
    e.preventDefault();
    this.getStore().sidePanel.setMenuOptions(menuOptions);
  }

  getData() {
    return (this.getStore().sidePanel && this.getStore().sidePanel.collections) || [];
  }

  onFilterExpand = () => {
    this.setState({ expandedFilter: !this.state.expandedFilter });
  }

  getReplaceStyle() {
    return !this.state.expandedFilter ? {
      height: 0
    } : {};
  }

  onAddCollection = ({ collectionName, baseURL }) => {
    if (collectionName) {
      const doc = {
        "id": uniqueIDService.getUniqueID(),
        "app_id": this.getStore().commonStore.appID,
        "name": collectionName,
        "baseUr": baseURL || "",
        "root": true,
        "level": 1,
        "data": [],
        parent_id: null
      }
      this.getStore().sidePanel.addCollection(doc);
    }
  }

  onAddCollectionBulk= (docs)=> {
    this.getStore().sidePanel.onAddCollectionBulk(docs);
  }

  offset(el, eventName) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.body.scrollHeight;
    const widgetHeight = (eventName === "") ? 140 : 210;//TODO Find menu hight dynamiclly
    const top = (scrollHeight <= (rect.top + scrollTop + widgetHeight)) ? (rect.top + scrollTop - widgetHeight) : (rect.top + scrollTop - 40);
    return { top: top, left: rect.left + scrollLeft - 130 }
  }

  onDownloadCollection = () => {

  }

  onShowCollection = (e, v) => {
    console.log('...onShowCollection', { e, v });
    this.setState({ selectedView: v });
  }

  onSortCollection = (e, v) => {
    console.log('...onSortCollection', { e, v });
    this.setState({ selectedView: v });
  }

  onShowHistory = (e, v) => {
    console.log('...onShowHistory', { e, v });
    this.setState({ selectedView: v });
  }

  onShowBookmarked = (e, v) => {
    console.log('...onShowBookmarked', { e, v });
    this.setState({ selectedView: v });
  }

  onShowTeam = (e, v) => {
    console.log('...onShowTeam', { e, v });
    this.setState({ selectedView: v });
  }



  onAddSubCollection = (newCollectionName) => {
    if (newCollectionName) {
      this.getStore().sidePanel.setCollection(newCollectionName, this.state.data);
    }
  }

  isLoading = () => {
    return this.getStore().sidePanel.isLoading;
  }

  onOptionSelect = (eventName, item, apiNode) => {

    if (_.isEmpty(item.id)) {
      item.id = apiNode.parent_id;
    }

    if (eventName === 'addFolder') {
      const doc = {
        app_id: this.getStore().commonStore.appID,
        id: item.id || uniqueIDService.getUniqueID(),
        name: "",
        level: (item.level || 0) + 1,
        editable: true,
        parent_id: item.id
      }
      this.getStore().sidePanel.addSubCollection(item, doc);
    }
    else if (eventName === 'editFolder') {
      this.getStore().sidePanel.editCollection(item);
    }
    else if (eventName === 'cloneFolder') {
      this.getStore().sidePanel.cloneCollection(item);
    }
    else if (eventName === 'deleteFolder') {
      this.getStore().sidePanel.dropCollection(item);
    }
    else if (eventName === 'addAPI') {
      this.getStore().sidePanel.addEmptyAPI(item);
    }
    else if (eventName === 'editAPI') {
      this.getStore().sidePanel.editAPI(item, apiNode);
    }
    else if (eventName === 'cloneAPI') {
      this.getStore().sidePanel.cloneAPI(item, apiNode);
    }
    else if (eventName === 'deleteAPI') {
      this.getStore().sidePanel.deleteAPI(item, apiNode);
    }
    else if (eventName === 'bookmarkAPI') {
      this.getStore().sidePanel.bookmarkAPI(item, apiNode);
    }

  }

  onFilterChange = (viewType, filterBy) => {
    this.setState({ filterAction: viewType, filterBy, orderDesc: !this.state.orderDesc });
  }

  onHistoryLoad = () => {
    //TODO 
    this.getStore().sidePanel.getHistory();
  }

  onBookmarkLoad = () => {
    this.getStore().sidePanel.getBookmarks();
  }

  isUpdated() {
    return this.getStore().sidePanel.storeUpdated;
  }
  onSearchFilterChange = (value) => {
    this.setState({ valueFilter: value })
  }

  flattenApiData(filterBy) {
    const { valueFilter, orderDesc } = this.state;
    let apiList = [];

    const getNodes = (bulkData) => {
      bulkData.map((collection) => {

        if (!_.isEmpty(collection.data)) {
          apiList = apiList.concat(collection.data);
        }

        if (_.get(collection, 'children', []).length > 0) {
          return getNodes(collection.children);
        }
      })
    }
    getNodes(this.getData());


    return _.chain(apiList)
      .orderBy([filterBy], [this.state.orderDesc ? 'desc' : 'asc'])
      .filter(value => (valueFilter ? value.name.indexOf(valueFilter) > -1 : true))
      .value() || [];
  }

  sortGroupData(filterBy) {
    const { data, valueFilter, orderDesc } = this.state;

    return _.chain(this.state.data)
      .orderBy([filterBy], [this.state.orderDesc ? 'desc' : 'asc'])
      .filter(value => (valueFilter ? value.name.indexOf(valueFilter) > -1 : true))
      .value() || [];
  }

  getPanelView() {

    if (this.state.selectedView === 'list') {
      if (this.state.filterAction === 'all') {
        const apiList = this.flattenApiData(this.state.filterBy);
        return (
          <Scrollbars style={{ height: '75vh' }} renderThumbVertical={this.renderVthumb} >
            <SubList
              style={{ paddingLeft: `${20}px` }}
              key={`sub-455`}
              item={apiList}
              data={apiList}
              open={true}
              editable={true}
              onClick={this.openTabByValue}
              onActionClick={this.onActionClick}
              onDrop={() => { }}
              onDragover={() => { }}
            />
          </Scrollbars>);
      }
      const data = this.sortGroupData(this.state.filterBy);
      return (<Scrollbars
        style={{ height: '75vh' }}
        renderThumbVertical={this.renderVthumb} >
        <CollectionList
          data={data}
          openTab={this.openTab}
          handleClick={this.handleClick}
          onActionClick={this.onActionClick}
          filterAction={this.state.filterAction}
        />
      </Scrollbars>);
    }
    else if (this.state.selectedView === 'recent') {
      const { filterBy, valueFilter } = this.state;
      return (<Scrollbars
        style={{ height: '75vh' }}
        renderThumbVertical={this.renderVthumb}>
        <History
          openTabByValue={this.openTabByValue}
          handleClick={this.handleClick}
          onActionClick={this.onActionClick}
          filters={{
            filterBy,
            order: this.state.orderDesc ? 'desc' : 'asc',
            valueFilter
          }}
        />
      </Scrollbars>);
    }
    else if (this.state.selectedView === 'bookmarked') {
      const { filterBy, valueFilter } = this.state;
      return (<Scrollbars
        style={{ height: '75vh' }}
        renderThumbVertical={this.renderVthumb}>
        <Bookmarks
          openTabByValue={this.openTabByValue}
          handleClick={this.handleClick}
          onActionClick={this.onActionClick}
          filters={{
            filterBy,
            order: this.state.orderDesc ? 'desc' : 'asc',
            valueFilter
          }}
        />
      </Scrollbars>);

    }
    else if (this.state.selectedView === 'team') {
      return <Team style={{ height: '75vh' }} />
    }
  }

  renderVthumb = ({ style, props }) => {
    const { classes = {} } = this.props
    return (<div {...props} style={{ ...style }} className={classes.vthumb} />);
  }

  render() {
    const { classes = {} } = this.props
    return (
      <div className={`rcsp ${classes.container}`}>
        <Finder
          onChange={this.onSearchFilterChange}
        />
        <Header
          onShowCollection={this.onShowCollection}
          onSortCollection={this.onSortCollection}
          onShowTeam={this.onShowTeam}
          onShowHistory={this.onShowHistory}
          onAddCollection={this.onAddCollection}
          onShowBookmarked={this.onShowBookmarked}
          onAddCollectionBulk={this.onAddCollectionBulk}
        />
        <div className={"collections " + this.isUpdated()}>
          {
            /*<LinearProgress
            style={{ display: !this.isLoading() && 'none' }}
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary,
            }
          /> */
          }
          <Skelton isLoading={this.isLoading()} />
          <Filters
            onFilterChange={this.onFilterChange}
            isLeftFilterEnabled={this.state.selectedView === 'list'}
          />

          {this.getPanelView()}
        </div>
        <MenuOptions
          onOptionSelect={this.onOptionSelect}
        />

      </div>
    );
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
  },
  vthumb: {
    backgroundColor: theme.custom.sidePanelScrollbar[theme.palette.type],
  },
  linearColorPrimary: {
    backgroundColor: '#252526',
    height: '95vh',
    opacity: 0.1
  },
  linearBarColorPrimary: {
    backgroundColor: '#ddd',
    height: '30px',
    width: '90%',
    height: '70vh',
    opacity: 0.2
  },
});

const RCSidePanelWrap = inject("rootStore")(observer(RCSidePanel));
export default withStyles(styles)(RCSidePanelWrap)
