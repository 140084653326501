import * as  _ from 'lodash';
import { uniqueIDService } from '../../rc-core';
class TreeLookup {

    findNode() {

    }


    //TODO use id instead of Name as name can be duplicate
    updateNode(tree, item) {

        const data = tree;// _.cloneDeep(tree);

        data.map((node) => {

            if (node.id === item.id) {

                if (node.children && node.children.length > 0) {
                    node.children = this.updateChildTree(node.children);
                }

                node.showData = !node.showData;
                if (_.isNil(node.data)) {
                    node.data = [];
                }

                return;
            }

            if (node.children && node.children.length > 0) {
                node.children = this.updateNode(node.children, item);
            }

        });
        return data;

    }

    updateChildTree(data, hideAll = false, keepOpen) {

        data.map(node => {
            node.open = hideAll ? false : (keepOpen || !node.open);
            //node.showData = hideAll ? false : (keepOpen || !node.showData);
            //node.showData = hideAll ? false : (keepOpen || !node.showData);
            if (node.open == false && node.children && node.children.length > 0) {
                node.children = this.updateChildTree(node.children, true);
            }

        });

        return data;

    }

    addEmptySubCollection(data, item, doc) {
        const editableCollection = {
            id: uniqueIDService.getUniqueID(),
            name: "",
            level: (item.level || 0) + 1,
            editable: true,
            parent_id: item.id,
        }



        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {

                if (node.children && node.children.length > 0) {
                    node.children.push(editableCollection);
                    node.children = this.updateChildTree(node.children, false, true);

                } else {
                    node.children = [editableCollection];
                    node.children = this.updateChildTree(node.children, false, true);
                }
                if (node.data && node.data.length > 0) {
                    node.showData = true;
                }

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.addEmptySubCollection(node.children, item);
            }

        });

        return data;
    }

    updateSubCollection(data, item) {



        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                node.name = item.name;
                node.editable = false;

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.updateSubCollection(node.children, item);
            }

        });
        return data;
    }


    updateClonedCollection(data, item) {

        if (_.isEmpty(item.parent_id)) {
            data.push(_.cloneDeep(item));
            return data;
        }

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.parent_id) {
                const foundIndex = _.findIndex(node.children, { id: item.id });
                if (foundIndex >= 0) {
                    node.children[foundIndex].name = item.name;
                    node.children[foundIndex].editable = false;
                    return node;
                } else {
                    if (!node.children) {
                        node.children = [];
                    }
                    node.children.push(_.cloneDeep(item));
                    return node;
                }
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                this.updateSubCollection(node.children, item);
            }

        });
        return data;
    }


    cloneCollections(collection) {
        const data = _.cloneDeep(collection);
        data.id = uniqueIDService.getUniqueID();
        return this.cloneIDs(data, data.parent_id);
    }

    updateCollection(data, item) {

        if (_.isNull(item.parent_id)) {

            /**
             * Root collection 
             */
            data.push(item);
            return data;
        }

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                node.name = item.name;
                node.editable = false;

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.updateSubCollection(node.children, item);
            }

        });
        return data;
    }

    editCollection(data, item) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                // node.name=item.name;
                node.editable = true;

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.editCollection(node.children, item);
            }

        });
        return data;
    }

    cloneCollection(dataList, item) {



        const findNode = (data, container) => {
            data.map((node) => {
                if (node.id === item.id) {
                    container.foundNode = node;
                }
                /**Search in child node */
                if (node.children && node.children.length > 0) {
                    findNode(node.children, container);
                }

            });
        }

        const cloneItem = (dataItem, parent_id) => {
            const result = dataItem.map((node) => {
                node.id = uniqueIDService.getUniqueID();
                node.parent_id = parent_id;

                /**Search in child node */

                if (node.data && node.data.length > 0) {
                    node.data = node.data.map(d => ({ ...d, id: uniqueIDService.getUniqueID(), parent_id: node.id, collections_id: node.id }))
                }

                if (node.children && node.children.length > 0) {
                    node.children = cloneItem(node.children, node.id);
                }

                return { ...node };

            });

            return result;
        }



        const container = {};
        findNode(dataList, container);



        const { foundNode } = container;
        const clonedItem = _.cloneDeep(foundNode);

        //clonedItem.editable = true;
        //clonedItem.open=false;
        //clonedItem.showData=false;
        clonedItem.id = uniqueIDService.getUniqueID();
        clonedItem.cloned = true;
        clonedItem.name = clonedItem.name + ' copy'

        if (clonedItem.data) {
            clonedItem.data = clonedItem.data.map(d => ({ ...d, id: uniqueIDService.getUniqueID(), parent_id: clonedItem.id, collections_id: clonedItem.id }))
        }

        if (clonedItem.children) {
            clonedItem.children = cloneItem(clonedItem.children, clonedItem.id)
        }

        /* const pushNode = (data,item,clonedItem) => {
             
             data.map((node) => {
                 
                 if (node.id===item.id) {
                     container.foundNode=node; 
                      
                     const found=_.find(data, {id: clonedItem.id});
                     if(!found){
                       data.push(clonedItem);
                     }
                     
                    return;
                 }
                 
                 
                 if (node.children && node.children.length > 0) {
                       pushNode(node.children,container);
                 }
 
             });
         }
 
         dataList=pushNode(dataList,item,clonedItem);*/



        //dataList=this.updateSubCollection(dataList,clonedItem);

        return clonedItem;
    }

    cloneIDs(data, parentId) {

        data.map((node) => {
            node.id = uniqueIDService.getUniqueID();
            node.parent_id = parentId;
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.cloneIDs(node.children, node.id);
            }
            if (node.data && node.data.length > 0) {
                node.data = this.cloneIDs(node.data, node.id);
            }
            return data
        });

        return data;
    }


    cloneCollection1(data, item) {


        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                // node.name=item.name;
                let clonedItem = { ...item };
                clonedItem.editable = true;
                //clonedItem.open=true;
                clonedItem.id = uniqueIDService.getUniqueID();
                clonedItem.cloned = true;
                clonedItem = this.cloneIDs(clonedItem);
                //clonedItem.showData=true; 
                clonedItem.data = node.data;
                clonedItem.children = node.children;
                //clonedItem.showData=true;
                //console.log({node, clonedItem})
                data.push(clonedItem);
                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.cloneCollection(node.children, item);
            }

        });

        return data;
    }

    iteRcloneIDs(data) {
        if (_.isArray(data)) {
            data.map((node) => {
                node.id = uniqueIDService.getUniqueID();
                /**Search in child node */
                if (node.children && node.children.length > 0) {
                    node.children = this.cloneIDs(node.children);
                }
                if (node.data && node.data.length > 0) {
                    node.data = this.cloneIDs(node.data);
                }

            });
            return data;
        }
        data.id = uniqueIDService.getUniqueID();
        return data;
    }

    fetchAndDropCollection(data, item) {

        const findAndRemoveNode = (data, item, container) => {

            const findIndex = _.findIndex(data, { id: item.id });
            const record = _.find(data, { id: item.id });
            if (record) {
                data.splice(findIndex, 1);
                container.foundNode = record;
                return;
            }


            data.map(node => {
                if (node.children && node.children.length > 0) {
                    findAndRemoveNode(node.children, item, container);
                }

            });


        }

        const container = {};

        findAndRemoveNode(data, item, container);
        container.apiData = data;

        return container;
    }


    addEmptyAPI(data, item) {
        const newAPI =
        {
            id: uniqueIDService.getUniqueID(),
            name: "Unknown",
            label: "Unknown",
            method: 'GET',
            editable: true,
            description: "",
            request: {
            },
            response: {
            },
            roles: {},
            parent_id: item.id
        };



        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                if (node.data && node.data.length > 0) {
                    node.data.push(newAPI);
                    node.showData = true;
                } else {
                    node.data = [newAPI];
                    node.showData = true;
                }

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.addEmptyAPI(node.children, item);
            }

        });

        return data;
    }


    addAPI(data, item, newApiNode) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                node.data.map(apiData => {
                    if (apiData.id === newApiNode.id) {
                        apiData.name = newApiNode.name;
                        apiData.label = newApiNode.label;
                        apiData.editable = false;
                    }
                });

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.addAPI(node.children, item, newApiNode);
            }

        });
        return data;
    }

    editAPI(data, item, apiNode) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                node.data.map(apiData => {
                    if (apiData.id === apiNode.id) {

                        apiData.editable = true;
                    }
                    return apiData;
                });

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.editAPI(node.children, item, apiNode);
            }

        });

        return data;
    }

    cloneAPI(data, item, apiNode) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                node.data = node.data || [];
                node.data.push({ ...apiNode, id: uniqueIDService.getUniqueID(), editable: true });

                return;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.cloneAPI(node.children, item, apiNode);
            }

        });

        return data;
    }

    deleteAPI(data, apiNode) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.data) {

                node.data = _.reject(node.data, { id: apiNode.id });
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.deleteAPI(node.children, apiNode);
            }

        });

        return data;
    }

    findCollection(data, collection = []) {
        data.map((node) => {
            const { id, name, label } = node;
            collection.push({ id, name })
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.findCollection(node.children, collection);
            } else {

                return;
            }

        });

        return data;
    }


    findAllAPIList(data1) {
        let foundNode = [];
        const findNode = (data) => {
            data.map((node) => {
                if (node.data && node.data.length > 0
                ) {
                    foundNode = foundNode.concat(node.data);
                    return;
                }
                /**Search in child node */
                if (node.children && node.children.length > 0) {
                    this.findNode(node.children);
                }

            });
        }
        findNode(data1);

        return foundNode;
    }

    updateApiNode(data, item, updatedNode) {
        return data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            if (node.id === item.id) {
                let isFound = false;
                node.data = node.data ? node.data.map(apiData => {
                    if (apiData.id === updatedNode.id) {
                        isFound = true;
                        return updatedNode;
                    }
                    return apiData;
                }) : [];

                if (!isFound) {
                    node.data = (node.data || []);
                    node.data.push(updatedNode);
                }
                node.showData = true;

                return node;
            } else {

            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.updateApiNode(node.children, item, updatedNode);
            }
            return node;
        });
    }

    findNodeByParentId(data1, parent1, api1) {
        let foundNode = {};
        const findNode = (data, parent, api) => {
            data.map((node) => {
                /** Node found , append an empty collection in editable mode */
                if (node.id === parent.id) {
                    node.data.map(apiData => {
                        if (apiData.id === api.id) {
                            foundNode = apiData;
                        }

                    });


                } else {

                }
                /**Search in child node */
                if (node.children && node.children.length > 0) {
                    findNode(node.children, parent, api);
                }

            });
        }
        findNode(data1, parent1, api1);

        return foundNode;
    }

    syncCollection(data, collection) {

        let isFound = false;

        data.map((node) => {

            if (node.id === collection.id) {

                isFound = true;
                const keys = _.keys(collection);
                keys.map(key => {
                    node[key] = collection[key];

                });
            }

            return data;


        });

        if (!isFound) {

            data.push(collection);
        }

        return data;
    }

    syncSubCollection(data, collection) {

        data.map((node) => {
            /** Node found , append an empty collection in editable mode */
            //console.log('******************-->',{f:node.id === collection.parent_id,node, collection});
            if (node.id === collection.parent_id) {

                const children = node.children || [];

                const found = _.findIndex(children, { id: collection.id });
                if (found > -1) {

                    children[found] = { ...children[found], ...collection };
                }
                else {
                    children.push({ ...collection });
                }
                node.children = children;
                //console.log('parent found',{collection,node});
                return data;
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.syncSubCollection(node.children, collection);
            }
            return node;
        });
        return data;

    }


    extractColectionAndApi(node, collectionList, apiList, appID) {

        collectionList.push({ ...node, children: [], data: [], app_id: appID });
        if (node.data && node.data.length > 0) {

            node.data.map(api => apiList.push({ ...api }));
        }

        if (node.children && node.children.length > 0) {
            node.children = this.iterateAndextractColectionAndApi(node.children, collectionList, apiList, appID, node.id);
        }
    }


    iterateAndextractColectionAndApi(data, collectionList, apiList, appID, parent_id) {
        data.map((node) => {
            collectionList.push({ ...node, children: [], data: [] });
            if (node.data && node.data.length > 0) {

                node.data.map(api => apiList.push({ ...api }));
            }
            /**Search in child node */
            if (node.children && node.children.length > 0) {
                node.children = this.iterateAndextractColectionAndApi(node.children, collectionList, apiList, appID, node.id);
            }
            return node;
        });
    }
}


export default new TreeLookup();