import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

class ResponsiveDialog extends React.Component {
    state = {
        open: this.props.open,
        showClose: this.props.showClose || true
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const { fullScreen, maxWidth, fullWidth, classes = {} } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    {this.props.title && <DialogTitle
                        id="responsive-dialog-title"
                        disableTypography={true}
                        classes={{
                            root: classes.titleRoot,
                            label: classes.titleLabel
                        }}
                    >
                        {this.props.title}
                        {this.state.showClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon className={classes.closeButtonLabel} />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    }
                    <DialogContent>
                        {this.props.children}
                    </DialogContent>
                    <DialogActions>
                        {this.props.actionNode}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
    titleRoot: {
        display: 'flex',
        width: '100%',
        padding: '6px',
        justifyContent: 'space-between'
    },
    closeButton: {
        fontSize: '0.7rem',
        padding: '5px',
        margin: 0
    },
    closeButtonLabel: {
        fontSize: '1rem',
        fontWeight: 600
    }
}));

export const Modal = (withMobileDialog()((props)=>{
    const classes = useStyles();
    const prop = { ...props, classes }
    return <ResponsiveDialog  {...prop} />
}));;