import React, { Component } from 'react';
import './body-view.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SaveIcon from '@material-ui/icons/Save';
import AspectRatio from '@material-ui/icons/AspectRatio';
import SearchIcon from '@material-ui/icons/Search';
import {RcSelect} from '../../../../rc-core';
import * as _ from 'lodash';
import vkbuitify from 'vkbeautify';
import { CodeEditor } from '../../../../rc-core';
import { SwapSpinner  } from "react-spinners-kit";
const CONTENT_TYPE = { JSON: "json", XML: "xml", HTML: 'html', TEXT: "text", JAVASCRIPT: 'javascript' }


const loaderStyle = theme => ({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    loader: {
      position: 'absolute',
      zIndex: '3',
      padding: '5px',
      top: '28%',
      left: '46%'
    } , 
    hide: {
        display: 'none'
    },
    loadingCover: {
        opacity: '0.5',
        pointerEvents: 'none'
    }
});

const LoaderInner= (props) => {
    const {classes, isLoading}=props;
    return (
    <div className={classes.root +" "+ (isLoading && classes.loadingCover)} >
       <div className={classes.loader+ " "+ (!isLoading && classes.hide)}> <SwapSpinner color="#43a047" /> </div>
        {props.children}
    </div>);
};
 
const Loader= withStyles(loaderStyle)(LoaderInner);

class ResponseView extends Component {
    state = {
        expanded: false,
        mode: 'text'
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.payload){
        this.setState({ 
            mode: this.findMode(nextProps) 
        });
     }
    }

    componentDidMount() {
        
    }

    toggleExpand = (e) => {

        e.stopPropagation();
   
        this.setState({ expanded: !this.state.expanded }, ()=>{
            //this.editor.resize();
            window.document.scrollingElement.style.overflowY=this.state.expanded ? 'hidden':'auto';
        });
    }


    onLoad = (editor) => {
        //this.editor = editor;
    }

    onModeChange = (mode) => {
        this.setState({ mode: mode.value },()=>{
            //this.editor.setOption("wrap", true);
        }); 
    }

    onFormatt = () => {
        try{
       // const session = this.editor.session;
        const mode=this.state.mode;
        if (mode=== CONTENT_TYPE.JSON) {
           
            //const value = JSON.parse(session.getValue());
            //const formattedData=JSON.stringify(value, null, '\t')
            //session.setValue(formattedData);
            //this.onChange(formattedData);
            
        } else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
            
        }
        else {
             
        }
        
       }catch(e){}
    }

    onRaw=()=> {
        this.setState({ mode: 'text'}, ()=>{
            //this.editor.setOption("wrap", true)
        });  
    }

    format = (value) => {try {

        const mode=this.state.mode  || this.findMode();
       
        if (mode=== CONTENT_TYPE.JSON) {
            const data = JSON.parse(value);
            const formattedData=JSON.stringify(data, null, '\t');
           
            return formattedData;
        } else if (mode=== CONTENT_TYPE.HTML) {
            /* TODO add formatter */
            const formattedData=vkbuitify.xml(value, '\t');
            
            return formattedData
        }
        else if (mode=== CONTENT_TYPE.XML) {
              /* TODO add formatter */
            const formattedData=vkbuitify.xml(value, '\t');
            return formattedData
        }
        else if (mode=== CONTENT_TYPE.TEXT) {
              /* TODO add formatter */
            const formattedData=value;
            return formattedData
        }
        else {
             return value;
        }
        

     }catch(e){ return value}}

    onJSONView = () => {
        this.clearView();
        this.setState({ showJsonView: !this.state.showJsonView });

    }

    clearView() {

    }

    onChange = (value) => {
        const type = this.findMode();
        this.props.setBody(value, type)
    }

    findMode(nextProps) {
        const  contentTypeHeader = _.get((nextProps||this.props).payload,"response.headers['content-type']")|| '';
        if (contentTypeHeader.indexOf('application/json') > -1) {
            return CONTENT_TYPE.JSON
        } else if (contentTypeHeader.indexOf('text/html') > -1) {
            return CONTENT_TYPE.HTML
        }
        else if (contentTypeHeader.indexOf('text/plain') > -1) {
            return CONTENT_TYPE.TEXT
        }
        else if (contentTypeHeader.indexOf('text/xml') > -1) {
            return CONTENT_TYPE.XML
        }
        else {
            return CONTENT_TYPE.TEXT
        }
    }

    getValue() {
        const value = _.get(this.props,'payload.response.data', "");
        return this.format(value)
    }

    onNodeAdd=(value)=>{
      console.log({value});
    }

    getPreview() {
        const mode=this.state.mode;
        if (mode=== CONTENT_TYPE.JSON) {

        } else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
             
        }
        else if (mode=== CONTENT_TYPE.HTML) {
            
        }
        else {
             
        }    
    }

    onSearch=()=>{
        //this.editor.execCommand("find");
    }

    onSave=()=>{


    }

    getEditor() {
        const { expanded } = this.state;
        return (
         <Loader isLoading={this.props.isExecuting} > 
           <CodeEditor
            mode={this.state.mode}
            value={this.getValue()}
            expanded={expanded}
            onLoad={this.onLoad}
            splitView={this.props.splitView}  
          /> 
        </Loader>
          );
    }

    getOptions=() => {
        const keys=_.keys(CONTENT_TYPE);
        const options= keys.map(key=> ({label:key, value:CONTENT_TYPE[key]}));
        return options;
    }

    render() {
        const { classes } = this.props;
        const { expanded } = this.state;

        return (<div className={`${!expanded ? "body-view" : "body-view body-view--expanded"}`} >
            <div className={`r_panel ${classes.panel}`}>
                <div className="r_panel__header">
                    <RcSelect onChange={this.onModeChange} value={this.state.mode} options={this.getOptions()} />
                    <Button  size="small" className={classes.button} onClick={this.onFormatt} >
                        <span style={{ fontSize: '10px'}}>Format</span>
                    </Button>
                    <Button size="small" className={classes.button}  onClick={this.onRaw} >
                        <span style={{ fontSize: '10px'}}>Raw</span>
                    </Button>
                    <IconButton aria-label="save" onClick={this.onSave} >
                        <SaveIcon className="r_panel__header__icon" />
                    </IconButton>
                    <IconButton aria-label="search" onClick={this.onSearch} >
                        <SearchIcon className="r_panel__header__icon" />
                    </IconButton>
                    <IconButton aria-label="aspect" onClick={this.toggleExpand}   >
                        <AspectRatio className="r_panel__header__icon" />
                    </IconButton>
                </div>
                <div className="r_panel__body">
                    {this.getEditor()}
                </div>
            </div>

        </div>);
    }
}


ResponseView.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    button: {
        padding: '2px 0px',
        minWidth: '50px',
        fontSize: '12px',
        minHeight: '18px',
        background: '#e0e0e0',
        //backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
        margin: '3px 2px'
  },
  panel:{
    backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
  }
});

export default withStyles(styles)(ResponseView);

