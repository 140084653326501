import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
 
 
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
 

const useStyles = makeStyles((theme) => ({
  root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
  },
      button: {
        margin: '10px 5px',
        color: '#fff !important',
    }, 
}));

const LiveCollection = (props) => {

   const classes = useStyles();

    const [state, setState] = React.useState({name: "", url: ""});

    const handleNameChange = (e) => {
        const value = e.target.value;
        setState({...state,name: value});
    }

    const handleURLChange = (e) => {
        const value = e.target.value;
        setState({...state,url: value});
    }

    const onAddCollectionConfirm = (e) => {
        const {name,url} =state;
        console.log({name, url});
        
       props.onAddCollectionConfirm({ collectionName:name, baseURL:url });
       props.onModalClose(e);
 
    }
 

  return (
      <div className={classes.root}>
         <TextField
                        id="name"
                        label="Enter collection name"
                        className={classes.textField}
                        value={state.name}
                        onChange={handleNameChange}
                        fullWidth
                        margin="normal"
                    />
        <TextField
                        id="enter_url"
                        label="Enter Open API URL"
                        className={classes.textField}
                        value={state.url}
                        onChange={handleURLChange}
                        fullWidth
                        margin="normal"
                    />

            <Button
                        className={classes.button}
                        onClick={onAddCollectionConfirm}
                        variant="contained"
                        color="primary"
                    > Save </Button>
      </div>
  );
}


 export default LiveCollection;