import React, { Component } from 'react';
import './header-select.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as _ from 'lodash';

class HeaderSelect extends Component {

    onInputChange = (value) => {
      
    }

    onChange = (value) => {

    }

    onSearch = (value) => {
        
    }

    onBlur = (event) => {

        if(this.props.getValue()!==event.target.value){
            this.props.setValue(event.target.value);
            this.props.api.valueService.setValue(this.props.node, 'value', "");   
        }
    }

    getOptions() {
        return _.keys(this.props.context.headerData) || [];
    }

    getDefaultSelected() {
     return this.props.getValue()?[this.props.getValue()]:[];
    }

    render() {
        return (
            <div className="header-select">
                <Typeahead
                    {...this.state}
                    //emptyLabel={''}
                    labelKey="name"
                    placeholder="Key"
                    bsSize="small"
                    clearButton
                    positionFixed={true}
                    options={this.getOptions()}
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    onInputChange={this.onInputChange}
                    onBlur={this.onBlur}
                    id={'header-select-typeahead'}
                    selected={[this.props.getValue()]}
                />
            </div>
        )
    }

}


export default HeaderSelect