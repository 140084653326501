 import { httpClient } from "./http-client";

class LocalDBService {
 
  constructor() {
    
  }
  
  registerSyncEvent({ setLoginInfo, syncData }) {
  
  }

  signInAnonymously () {
   

  }

  initApp(syncEvents)  {

    

  }

  getUserInfo=(user) => {
    
  }

  /**
   * Observe changes
   */
  checkAppExistForUser = (user, syncEvents) => {
    
  }


  async  addCollection(doc) {
   return await httpClient.addCollection(doc);
  }




  addCollectionBatch(collectionList=[], tableName) {
    
  }

  deleteCollectionBatch(collectionList=[], tableName) {
    //const batch = firebase.firestore().batch();
  }



  addAPIBatch(apiList) {

  }


  async deleteAPI(doc) {
    return await httpClient.deleteDocuments(doc);
  }


  async addDocument(doc) {
    return await httpClient.addDocument(doc);
  }

  async getDocuments() {
    return await httpClient.getDocuments();
  }

  async getCollections() {
    return await httpClient.getCollections();
  }

  async deleteCollections(collections) {
    return await httpClient.deleteCollections(collections);
  }

  async deleteDocuments(documents) {
    return await httpClient.deleteDocuments(documents);
  }

  async addCollections(collections) {
    return await httpClient.addCollections(collections);
  }

  async addDocuments(documents) {
    return await httpClient.addDocuments(documents);
  }

  login = (data) => {
    
  }

  signup = (data) => {
    
  }

  createOrUpdateTeam= async (doc) => {
     
  }

  getTeam= async (userId) => {
    
  }

  getDB() {
    
  }

  googleSignInWithPopup(){
   
}

facebookSignInWithPopup (){
  
}

phoneSignInWithPopup() {
   
}

twitterSignInWithPopup() {
 
}

githubSignInWithPopup() {
   
}

sighInWithPopUp=async (provider)=> {

 

   return ; 
}


 sighOut=async ()=>{
   
   
}



}

export const localDBService = new LocalDBService();

