import React, { Component } from 'react';
import './header-view.css';
import { headerData, headerGridData } from './header-data';
import * as _ from 'lodash';
import { RcGrid } from '../../../../rc-core';
import { HeaderContainer } from 'ag-grid';



class Headers extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    ...headerGridData
  }

  componentWillMount() {
    this.getHeaders();
  }

  componentWillReceiveProps(nextProps) {
    const { label } = this.props.payload;
    if (nextProps.payload.label !== label) {
      //refresh data from store
      this.getHeaders(nextProps);
    }

  }

  onGridReady = (params) => {
    this.api = params.api;
    this.api.sizeColumnsToFit();
  }


  onRowSelected = (params) => {
    const { header, value } = params.data;

    if (params.node.lastChild) {

      params.api.updateRowData({
        add: [
          { check: "", header: "", value: "", description: "" },
        ]
      });

      return;
    }
  }

  onCellValueChanged = (params) => {
    const { header, value } = params.data;
    this.syncHeaders(params);
  }

  syncHeaders(params) {
    const headers = [];
    params.api.forEachNode(function (rowNode, index) {
      headers.push(rowNode.data);
    });

    this.props.setHeaders(headers);
  }

  getHeaders(nextProps) {
    const props = nextProps || this.props;
    let def = { check: "", header: "", value: "", description: "" };
    const headers = _.get(props.payload, 'request.headers') || [def];
    this.setState({ rowData: headers });
  }

  getHeaderContainer=()=>{
    return this.headerContainer;
  }

  render() {
    return (
      <div className="header-view">
        <div
          className="ag-theme-balham"
          style={{ width: '100%', height: '200px', marginTop: '10px' }}
        >
          <RcGrid
            columnDefs={this.state.columnDefs || []}
            defaultColDef={this.state.defaultColDef}
            autoColumnDef={this.state.autoGroupColumnDef}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData || []}
            gridOptions={{
              onRowSelected: this.onRowSelected,
              onCellValueChanged: this.onCellValueChanged,
              context: {
                headerData,
                getHeaderContainer: this.getHeaderContainer
              }
            }}
          />
          <div  ref={(v)=> this.headerContainer=v} /> 
        </div>
      </div>
    );
  }
}

export default Headers;