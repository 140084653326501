import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typeahead } from 'react-bootstrap-typeahead';
import { observer, inject } from 'mobx-react';
import * as _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import SelectTeam from './select-team';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Scrollbar } from '../../../../rc-core';
import { users } from '../team-data';
import treeLookupService from '../../../services/tree-lookup';



class ManageTeamMembers extends Component {

    state = {
        team: this.props.team,
        selectedMembers: [],
        selectedCollections:[]
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ team: nextProps.team });
    }

    onTeamNameEdit = (team) => {
        this.setState({ team }, () => {
            this.props.onChange(team);
        });

    }

    handleClick = (e, index) => {
        this.setState(state => {
            const team = _.cloneDeep(state.team);
            const value = _.get(team, `participants[${index}].open`, false);
            _.set(team, `participants[${index}].open`, !value);
            return { team };
        });
    };

    onRoleEdit = (e, index, role) => {
        this.setState(state => {
            const team = _.cloneDeep(state.team);
            const value = _.get(team, `participants[${index}].roles[${role}]`);
            _.set(team, `participants[${index}].roles[${role}]`, !value);
            return { team };
        }, () => {
            this.props.onChange(this.state.team);
        });
    }

    handleAddMember = () => {
        this.setState(state => {
            const { participants = [] } = state.team;
            this.state.selectedMembers.map(participant => {
                const data = { ...participant, roles: { read: true, write: true, delete: true } };
                participants.push(data);
            });
            const team = { ...state.team, participants };
            return { team, selectedMembers: [] };
        }, () => {
            this.props.onChange(this.state.team);
            this.setState({selectedMembers:[]});
        });
    }

    deleteMember = (item, index) => {
        this.setState(state => {
            const list = _.get(state.team, `participants`);
            const data = _.reject(list, { id: item.id });
            return { team: { ...state.team, participants: data } };
        }, () => {
            this.props.onChange(this.state.team);
        });
    }

    onSelectMembers = (list) => {
        this.setState({ selectedMembers: list });
    }

    onTeamChange = (team) => {
        this.setState({ team },
            () => {
                this.props.onChange(this.state.team);
            }
        );
    }

    getTeamMembers=()=> {
        return _.get(this.state.team, "participants", []);
    }

    getCollectionOptions() { 
        const apiData = this.props.rootStore.playground.getApiData();
        const collection = []
        treeLookupService.findCollection(apiData, collection);
        return collection;
    }

    onCollectionSelect=(data)=>{
        console.log('selected data', data);
        if(!_.isNil(data)){
          this.setState({selectedCollections:data});
        }
    }

    getCollectionList=()=> {
        return _.get(this.state.team, "collections", []);
    }


    onAddCollection = () => {
        this.setState(state => {
            const { collections = [] } = state.team;
            this.state.selectedCollections.map(participant => {
                const data = { ...participant, roles: { read: true, write: true, delete: true } };
                collections.push(data);
            });
            const team = { ...state.team, collections };
            return { team, selectedCollections: [] };
        }, () => {
            this.props.onChange(this.state.team);
        });
    }

    deleteCollection = (item, index) => {
        this.setState(state => {
            const list = _.get(state.team, `collections`);
            const data = _.reject(list, { id: item.id });
            return { team: { ...state.team, collections: data } };
        }, () => {
            this.props.onChange(this.state.team);
        });
    }

    render() {
        const { classes = {}, options } = this.props;
        return (
            <div className={classes.editTeamContainer}>
                <SelectTeam team={this.state.team} onChange={this.onTeamNameEdit} />
                <div className={classes.headerContainer}>
                    <Typography color="secondary" className={classes.heading}>Participants({this.getTeamMembers().length})</Typography>
                </div>
                <div className={classes.searchBox}>
                    <Typeahead
                        showMenu={false}
                        hideHeader={false}
                        labelKey="name"
                        options={users || []}
                        onChange={this.onSelectMembers}
                        placeholder="Search members"
                        bsSize="small"
                        multiple
                        clearButton
                        selected={this.state.selectedMembers}
                        id={'edit-typehead'}
                    />
                    <IconButton variant="fab" aria-label="Comments" onClick={this.handleAddMember}>
                        <AddIcon />
                    </IconButton>
                </div>
                <div className={classes.participants}>
                    <Scrollbar
                        style={{ height: '200px' }}
                        onScroll={this.handleScroll}
                    >
                        <List >
                            {this.getTeamMembers().map((item, index) => {
                                return (
                                    <React.Fragment key={"key" + item.name + index}>
                                        <ListItem button onClick={(e) => this.handleClick(e, index)} >
                                            <Avatar>
                                                <BeachAccessIcon />
                                            </Avatar>
                                            <ListItemText primary={item.name}
                                                secondary={(
                                                    <span className={classes.editRoles}>
                                                        {item.timestamp}<a>, Click to edit Roles</a>
                                                    </span>
                                                )}
                                            />
                                            <IconButton aria-label="expand" onClick={(e) => this.handleClick(e, index)}>
                                                {item.open ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>

                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="delete" onClick={(e) => this.deleteMember(item, index)}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Collapse in={item.open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding className={classes.listRoot}>
                                                <ListItem className={classes.listItemNested}>
                                                    <ListItemText inset
                                                        primary={<div className={item.roles.read ? classes.activeText : classes.inActiveText}>Read</div>}
                                                        onClick={e => this.onRoleEdit(e, index, 'read')}
                                                    />
                                                    <ListItemText inset
                                                        primary={<div className={item.roles.write ? classes.activeText : classes.inActiveText}>Write</div>}
                                                        onClick={e => this.onRoleEdit(e, index, 'write')}
                                                    />
                                                    <ListItemText inset
                                                        primary={<div className={item.roles.delete ? classes.activeText : classes.inActiveText}>Delete</div>}
                                                        onClick={e => this.onRoleEdit(e, index, 'delete')}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </Scrollbar>
                </div>
                <div className={classes.sharedCollections}>
                    <div className={classes.headerContainer}>
                        <Typography color="secondary" className={classes.heading}>Shared collections({this.getCollectionList().length})</Typography>
                    </div>
                    <div className={classes.searchBox}>
                        <Typeahead
                            showMenu={false}
                            hideHeader={false}
                            labelKey="name"
                            options={this.getCollectionOptions() || []}
                            placeholder="Search collection"
                            onChange={this.onCollectionSelect}
                            bsSize="small"
                            multiple
                            clearButton
                            selected={this.state.selectedCollections}
                            id={'edit-team-typehead'}
                        />
                        <IconButton variant="fab" aria-label="Comments" onClick={this.onAddCollection}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <div className={classes.sharedCollections}>
                    <Scrollbar
                        style={{ height: '200px' }}
                        onScroll={this.handleScroll}
                    >
                        <List >
                            {this.getCollectionList().map((item, index) => {
                                return (
                                    <React.Fragment key={"key" + item.name + index}>
                                        <ListItem  >
                                            <Avatar>
                                                <BeachAccessIcon />
                                            </Avatar>
                                            <ListItemText primary={item.name}
                                                secondary={(
                                                    <span className={classes.editRoles}>
                                                        {item.timestamp}
                                                    </span>
                                                )}
                                            />
                                        
                                            <ListItemSecondaryAction>
                                                <IconButton aria-label="delete" onClick={(e) => this.deleteCollection(item, index)}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </Scrollbar>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    editTeamContainer: {
        minWidth: '500px',
        paddingLeft: 0

    },
    heading: {
        //paddingLeft: '25px'
    },
    logoContainer: {
        background: '#ddd',
        display: 'flex',
        justifyContent: 'center',
        padding: '6px'
    },
    avatar: {
        width: '50px',
        height: '50px',
        background: '#43a047',
        color: '#fff'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 0px 0px 25px',
        maxWidth: '400px',
        //minHeight: '200px'
    },
    editRoles: {
        cursor: 'pointer'
    },
    searchBox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 25px',
        maxWidth: '400px',
        height: 'auto'
    },
    participants: {
        margin: '10px 0'
    },

    sharedCollections: {
        margin:'30px 0 0 0'

    },
    activeText: {
        backgroundColor: theme.palette.primary['dark'],
        margin: '0 40px',
        color: '#fff',
        margin: '2px',
        borderRadius: '5px',
        maxWidth: '55px',
        textAlign: 'center',
        fontSize: '0.8rem',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    inActiveText: {
        backgroundColor: theme.palette.secondary['light'],
        margin: '0 40px',
        margin: '2px',
        borderRadius: '5px',
        maxWidth: '55px',
        textAlign: 'center',
        fontSize: '0.8rem',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    listRoot: {
        padding: 0,
        margin: 0
    },
    listItemNested: {
        padding: 0,
        margin: 0
    }

});

ManageTeamMembers.propTypes = {
    classes: PropTypes.object,
};

const ManageTeamMembersWrap = inject("rootStore")(observer(ManageTeamMembers));
export default withStyles(styles)(ManageTeamMembersWrap);

