import { config } from './db-config'; 
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/analytics";

firebase.initializeApp(config);
const settings = {/* your settings... */ };
firebase.firestore().settings(settings);
firebase.firestore().enablePersistence()
  .catch(function (err) {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

class DbConnectionService {
    getFirebase() {
      return firebase;
    } 
}

export  default new DbConnectionService();