import connection from './db-connection-service';
const firebase=connection.getFirebase();

class DBclient {

  syncEvents = { }

  loginUser={};

  constructor() {
    //this.initApp(this.syncEvents);
    //this.getDocuments();
   }
  
  registerSyncEvent({ setLoginInfo, syncData }) {
    this.syncEvents.setLoginInfo = setLoginInfo;
    this.syncEvents.syncData = syncData;
    this.syncEvents.checkAppExistForUser = this.checkAppExistForUser
  }

  signInAnonymously () {
  
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(function() {
        firebase.auth().signInAnonymously().catch(function (error) { console.error(error);});
    })
    .catch(function(error) { console.log({error});});

  }

  initApp(syncEvents)  {

    firebase.auth().onAuthStateChanged( (userObj)=> {
      if (userObj) {

        //var isAnonymous = userObj.isAnonymous;
        var uid = userObj.uid;
        const user=this.getUserInfo(userObj);
       /*if(isAnonymous && syncEvents) {
         
         syncEvents.setLoginInfo({ isAnonymous, user })
         syncEvents.checkAppExistForUser(user, syncEvents)
       }*/

        console.log('user logged in ', { uid, user });
        syncEvents && !user.isAnonymous && syncEvents.checkAppExistForUser(user, syncEvents);
      } else {
         console.log('user is not logged in signing in anonymously');
         //this.signInAnonymously();
      }

    });

  }

  getUserInfo=(user) => {
    const {isAnonymous,uid,displayName,email,emailVerified,phoneNumber,photoURL,u}= user;
    return {isAnonymous,uid,displayName,email,emailVerified,phoneNumber,photoURL,u}
  }

  isUserLoggedIn() {
     return firebase.auth().currentUser? true: false;
  }

  /**
   * Observe changes
   */
  checkAppExistForUser = (user, syncEvents) => {
    const db = firebase.firestore();
    db.collection("apps").where("uid", "==", user.uid).onSnapshot((querySnapshot) => {
       console.log('querySnapshot.docs', querySnapshot.docs);
      if (querySnapshot.docs.length == 0) {

        const app = {};
        app['owner'] = user.email;
        app['date'] = (new Date).getTime();
        app['uid'] = user.uid;
        db.collection("apps").add(app).then(function (docRef) {
          console.log("app  create with ID: ", docRef.id);
          syncEvents.setLoginInfo({ user,appID: docRef.id });
        })
          .catch(function (error) {
            console.error("Error while creating app: ", error);
          });
      }
      else {
        //Listen collection and api docunments
        const appID = querySnapshot.docs[0].id;
        syncEvents.setLoginInfo({ user,appID });
        db.collection("collections").where("app_id", "==", appID).orderBy("created").onSnapshot((collectionSnapshot) => {

          const colsData = collectionSnapshot.docs.map((data) => ({ ...data.data(), id: data.id }));
          setTimeout(() => {
            syncEvents.syncData({ collection: colsData });
          }, 0);
          //console.log({colsData});

          collectionSnapshot.forEach(collection => {

            db.collection("api").where("collections_id", "==", collection.id).orderBy("created").onSnapshot((apiSnapshot) => {

              const api = apiSnapshot.docs.map((data) => ({ ...data.data(), id: data.id }));
              setTimeout(() => {
                syncEvents.syncData({
                  collection: { ...collection.data(), id: collection.id, data: api },
                  api
                });
              }, 0);
            })
          });
        });
      }
    });
  }


  addCollection(doc) {

    console.log({uder: this.isUserLoggedIn()});
    
    if(!this.isUserLoggedIn()) { return ;}

    firebase.firestore().collection("collections").doc(doc.id).set(doc)
      .then(() => {
        console.log("Document written with ID: ", doc);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  addCollectionBatch(collectionList, tableName) {
    if(!this.isUserLoggedIn()) { return ;}

    const batch = firebase.firestore().batch();

    collectionList.map(col => {
      const nycRef = firebase.firestore().collection(tableName).doc(col.id);
      batch.set(nycRef, col);
    });

    batch.commit().then(function () {
      console.log('insert commit sucess');
    });
  }

  deleteCollectionBatch(collectionList, tableName) {
    if(!this.isUserLoggedIn()) { return ;}

    const batch = firebase.firestore().batch();
    collectionList.map(col => {
      const nycRef = firebase.firestore().collection(tableName).doc(col.id);
      batch.delete(nycRef);
    });

    batch.commit().then(function () {
      console.log('delete commit sucess');
    });
  }



  addAPIBatch(apiList) {

  }


  deleteAPI(doc) {
    if(!this.isUserLoggedIn()) { return ;}

    firebase.firestore().collection("api").doc(doc.id).delete()
      .then(() => {
        console.log("Document deleted", doc);
      })
      .catch((error) => {
        console.error("Error deleting document: ", error);
      });
  }


  addDocument(doc) {
    if(!this.isUserLoggedIn()) { return ;}

    firebase.firestore().collection("api").doc(doc.id).set(doc)
      .then((docRef) => {
        console.log("addDocument: Document written", doc);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

  }

  getDocuments() {
    if(this.isUserLoggedIn()) { return ;}

    firebase.firestore().collection("apidata").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`docs`, { doc });
      });
    });

  }

  login = (data) => {
    const { email, password } = data;
    firebase.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  signup = (data) => {
    const { email, password } = data;
    firebase.auth().createUserWithEmailAndPassword(email, password).catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });
  }

  createOrUpdateTeam= async (doc) => {
    if(this.isUserLoggedIn()) { return ;}

     const team={...doc}
     team.participants_ids=(team.participants|| []).map(data=> data.id)||[];
     team.collections_ids=(team.collections|| []).map(data=>data.id)|| [];
     return await firebase.firestore().collection("teams").doc(team.id).set(team);
  }

  getTeam= async (userId) => {
    if(!this.isUserLoggedIn()) { return ;}
    return await firebase.firestore().collection("teams").where("createdBy", "==", userId).get();
  }

  getDB() {
    return firebase.firestore();
  }

  googleSignInWithPopup(){
  var provider = new firebase.auth.GoogleAuthProvider();
            
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
        'login_hint': 'user@example.com'
      });
     this.sighInWithPopUp(provider);
}

facebookSignInWithPopup (){
  var provider = new firebase.auth.FacebookAuthProvider();
  provider.setCustomParameters({
    'display': 'popup',
  });
  this.sighInWithPopUp(provider);
}

phoneSignInWithPopup() {
  var provider = new firebase.auth.TwitterAuthProvider();
}

twitterSignInWithPopup() {
  var provider = new firebase.auth.TwitterAuthProvider();
  this.sighInWithPopUp(provider);
}

githubSignInWithPopup() {
  var provider =new firebase.auth.GithubAuthProvider();
  provider.addScope('repo');
  this.sighInWithPopUp(provider);
}

sighInWithPopUp=async (provider)=> {


  
  try{
     await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);//.then(function() {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
        //const result= await firebase.auth().signInWithPopup(provider);//.then(function(result) {
        firebase.auth().signInWithRedirect(provider);
        const result= await firebase.auth().getRedirectResult();
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = result.credential.accessToken;
        var secret = result.credential.secret;
        // The signed-in user info.
        var user = result.user;
        console.log({user});
        return result;
        // ...
      //}).catch(function(error) {
        
        // ...
     // });
    //})
    //.catch(function(error) { console.log({error});});
  }catch(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
  }

   return ; 
}


 sighOut=async ()=>{
   try{
     await firebase.auth().signOut();
     //document.location.reload();
   }
   catch(e){
     console.log({e});
   }
   
}



}

export const dbService = new DBclient();

