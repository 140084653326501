import React, { useState } from "react";
import './filters.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Sort from '@material-ui/icons/Sort';
 

function FilterWrappers(props) {

    const [type, setType] = useState(props.type);
    const [filter, setFilter] = useState(props.filter);

    const { classes } = props;
    const onFilterChange = (e, filter) => {
        setFilter(filter)
        if (props.onFilterChange) {
            props.onFilterChange(type, filter);
        }
    }

    const onViewTypeChange = (e, viewType) => {
        setType(viewType)
        if (props.onFilterChange) {
            props.onFilterChange(viewType, filter);
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.filterSections +" "+ (!props.isLeftFilterEnabled && classes.hidden )}>
                <Tooltip id="tooltip-icon" title="Grouped" placement="top" >
                    <Button
                        size={props.size}
                        color={props.color}
                        classes={{
                            root: classes.buttonRoot,
                            label: (type === 'group' ? classes.buttonLabelActive : classes.buttonLabel)
                        }}
                        onClick={(e) => onViewTypeChange(e, "group")}>
                        Grouped
                    </Button>
                </Tooltip >
                <Tooltip id="tooltip-icon" title="All" placement="top" >
                    <Button
                        size={props.size}
                        color={props.color}
                        classes={{
                            root: classes.buttonRoot,
                            label: (type === 'all' ? classes.buttonLabelActive : classes.buttonLabel)
                        }}
                        onClick={(e) => onViewTypeChange(e, "all")}>
                        All
                    </Button>
                </Tooltip >
            </div>
            <div className={classes.filterSections}>
                
                <Tooltip id="tooltip-icon" title="Sort by name" placement="top" >
                    
                    <Button
                        size={props.size}
                        color={props.color}
                        classes={{
                            root: classes.buttonRoot,
                            label: (filter === 'name' ? classes.buttonLabelActive : classes.buttonLabel)
                        }}
                        onClick={(e) => onFilterChange(e, "name")}
                        >
                        <Sort classes={{root: classes.buttonIconRoot}} />
                        Name
                </Button>
                </Tooltip >
                <Tooltip id="tooltip-icon" title="Sort by date" placement="top" >
               
                    <Button
                        size={props.size}
                        color={props.color}
                        classes={{
                            root: classes.buttonRoot,
                            label: (filter === 'created' ? classes.buttonLabelActive : classes.buttonLabel)
                        }}
                        onClick={(e) => onFilterChange(e, "created")}>
                     <Sort classes={{root: classes.buttonIconRoot}} />
                       Date
                    </Button>
                </Tooltip >
            </div>
        </div>
    );
};


FilterWrappers.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string,
    onFilterChange: PropTypes.func,
};


FilterWrappers.defaultProps = {
    type: 'group',
    filter: 'date',
    color: 'secondary',
    size: 'small',
    isLeftFilterEnabled: false
}

const styles = theme => {

    return {
        root: {
            display: 'flex',
            width: '100%',
            padding: '0px 6px',
            justifyContent: 'space-between'
        },
        buttonRoot: {
            lineHeight: '15px'
        },
        buttonLabel: {
            fontSize: '0.6rem',
            opacity: 0.6
        },
        buttonLabelActive: {
            fontSize: '0.6rem',
            fontWeight: 600,
            opacity: 0.8
        },
        filterSections: {
           
        },
        buttonIconRoot: {
            opacity: '0.5',
            fontSize: '0.9rem',
            fontWeight: '600',
            margin: '5px 5px'
        },
        hidden: {
            visibility: 'hidden'
        }
    };
}

export default withStyles(styles)(FilterWrappers);

