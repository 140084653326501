
import React, { useState } from "react";
import * as _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function SkeltonWrappers(props) {


    const { classes } = props;

    return (
            <div className={classes.root} style={{ display: !props.isLoading && 'none' }} >
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="text" height={40} />
                <Skeleton variant="rect" height={118} />
            </div>
    );
};


SkeltonWrappers.propTypes = {
    classes: PropTypes.object.isRequired,
    type: PropTypes.string,
    onFilterChange: PropTypes.func,
};


SkeltonWrappers.defaultProps = {
    type: 'group',
    filter: 'date',
    color: 'secondary',
    size: 'small'
}

const styles = theme => {

    return {
        root: {
            
            width: '100%',
            padding: '20px',
 
        },

    };
}

export default withStyles(styles)(SkeltonWrappers);