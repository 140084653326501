
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import * as _ from 'lodash';
import { OpenTab } from '../selected-environment';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import './index.scss';


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


class TabList extends React.Component {

    state = {
        openSaveConfirm: false,
        mouseEvent: {},
        selectedCollection: undefined,
        editMode: {}
    }

    componentWillReceiveProps(nextProps) {

        if (!nextProps.selectedTab && nextProps.list.length > 0) {
            this.props.setSelectedTab(0);
        }
    }

    handleChange = (e, index) => {
        const mouseEvent = {
            id: e.target.id,
            name: e.target.innerHTML,
            pid: (e.target.attributes.pid && e.target.attributes.pid.nodeValue)
        }

        const { id, name } = mouseEvent;
        if (name === 'cancel') {
            this.onTabClose({ id });
        }
        else if (name === 'edit') {
            this.toggaleEditMode(index, true);
        }
        else {
            this.props.setSelectedTab(index);

            if (this.state.editMode[index]) {
                //Make all other tab uneditabke in case of focus lost.
                this.toggaleEditMode(index, true);
            } else {
                this.toggaleEditMode(index, false);
            }

        }

    };

    getAttrData(e) {
        const mouseEvent = {
            id: e.target.id,
            name: e.target.innerHTML,
            pid: (e.target.attributes.pid && e.target.attributes.pid.nodeValue)
        };
        return mouseEvent;
    }


    onTabClose = ({ id }) => {
        this.props.onTabClose({ id })
    }



    onEditLabel = (e, val) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onEditLabel(val);
    }

    getActiveTabValue = () => {
        const { selectedTab } = this.props;

        if (_.isNil(selectedTab)) { return 0; }

        const index = _.findIndex(this.props.list, (value) => {
            return value.id === selectedTab.id
        });
        return index >= 0 ? index : 0
    }

    onTabSelected = (e) => {

    }

    onMouseEnter = (e) => {

    }

    onMouseLeave = (e) => {

    }

    onSave = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.updateSelected('label', this.inputRef.value);
        this.toggaleEditMode(index, false);
    }

    onCancel = (e, index) => {

        e.stopPropagation();
        e.preventDefault();
        const { id } = this.getAttrData(e);
        this.onTabClose({ id });
    }

    onEdit = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        this.toggaleEditMode(index, true);
    }

    onInputKeyPress = (e, index) => {
        if (e.key == 'Enter') {
            this.onSave(e, index);
        }
        if (e.key == 'Esc') {
            this.toggaleEditMode(index, false);
        }
    }

    toggaleEditMode = (index, flag) => {
        const obj = {};
        obj[index] = flag;
        this.setState({ editMode: obj });
    }

    setEnvironmentValues = (values) => {
        this.props.setValues(this.props.selectedTab, values);
    }
    getAdjecentClass(index) {
        const { classes } = this.props;

        if (index - 1 === this.getActiveTabValue()) {
            return " " + classes.tabRightToActive;
        }
        else if (index + 1 === this.getActiveTabValue()) {
            return " " + classes.tabLeftToActive;
        }

        else if (index === this.getActiveTabValue()) {
            return " " + classes.tabActive;
        }
        return "";

    }



    render() {
        const { classes } = this.props;

        if (this.props.list.length == 0) {
            return "";
        }

        return (
            <div className={classes.root} onClick={this.onTabSelected}>
                <AppBar position="relative" color="default" classes={{ root: classes.appBar }}>
                    <Tabs
                        value={this.getActiveTabValue()}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        fullwidth="true"
                        classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                            scrollButtons: classes.scrollButtons
                        }}
                    >
                        {
                            (this.props.list.slice() || []).map((tab, index) => {
                                return <Tab
                                    classes={{
                                        root: classes.tabRoot + this.getAdjecentClass(index),
                                        selected: classes.tabSelected,
                                        wrapper: this.getActiveTabValue() === index ? classes.tabWrapperActive : classes.tabWrapper
                                    }}
                                    key={"env-" + tab.id}
                                    color="primary"
                                    label={
                                        !this.state.editMode[index] ?
                                            <div className={classes.tabContainer}>
                                                <div className={classes.tabLabel}>
                                                    {tab.label}
                                                </div>
                                                <div className={classes.tabActions}>
                                                    {this.getActiveTabValue() === index &&
                                                        <Icon
                                                            className={classes.tabCloseIcon + " " + (tab.changed ? classes.tabCloseIconChanges : "")}
                                                            id={tab.id}
                                                            pid={tab.pid}
                                                            onClick={(e) => this.onEdit(e, index)}
                                                        >edit
                                                    </Icon>
                                                    }
                                                    <Icon
                                                        className={classes.tabCloseIcon + " " + (tab.changed ? classes.tabCloseIconChanges : "")}
                                                        id={tab.id}
                                                        pid={tab.pid}
                                                        onClick={(e) => this.onCancel(e, index)}
                                                    >cancel
                                                     </Icon>
                                                </div>
                                            </div>
                                            :
                                            <div className={classes.tabContainer}>
                                                <input defaultValue={tab.label}
                                                    className={classes.tabInBox}
                                                    ref={(v) => this.inputRef = v}
                                                    onKeyPress={(e) => this.onInputKeyPress(e, index)}
                                                />
                                                <div className={classes.tabActions}>
                                                    <Icon
                                                        className={classes.tabCloseIcon}
                                                        id={tab.id}
                                                        pid={tab.pid}
                                                        onClick={(e) => this.onSave(e, index)}
                                                    >check_circle
                                               </Icon>
                                                </div>
                                            </div>

                                    }

                                >
                                </Tab>
                            })
                        }

                    </Tabs>
                    <IconButton variant="fab" onClick={this.props.onAdd}  >
                        <Add color="primary" />
                    </IconButton>

                </AppBar>
                {this.props.selectedTab && <TabContainer>
                    <OpenTab
                        selectedTab={this.props.selectedTab}
                        setEnvironmentValues={this.setEnvironmentValues}
                    />
                </TabContainer>}
            </div>
        );
    }
}

TabList.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array,
    value: PropTypes.number,
    selectedTab: PropTypes.object,
    onTabClose: PropTypes.func,
    onLabelEdit: PropTypes.func,
};

const styles = theme => {
    return ({
        tabWrapper: {
            lineHeight: '25px',
            minWidth: '100px',
            padding: '0 10px',
            backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
        },

        tabActive: {
            //background: '#fff',
            border: 0
        },
        tabLeftToActive: {
            borderRight: 0,
            borderRadius: '0px 0px 10px 0px'
        },
        tabRightToActive: {
            borderRadius: '0px 0px 0px 10px'
        },
        tabWrapperActive: {
            backgroundColor: '#fff',
            borderRadius: '5px 5px 0px 0px',
            lineHeight: '25px',
            minWidth: '150px',
            padding: '0 10px',
        },
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background[theme.palette.type],
        },
        tabsRoot: {
            minHeight: '30px'
        },
        tabRoot: {
            padding: '0',
            position: 'relative',
            overflow: 'hidden',
            maxWidth: '264px',
            minWidth: '72px',
            fontSize: '0.8rem',
            minHeight: '30px',
            fontWeight: '400',
            flexShrink: 0,
            textTransform: 'capitalize',
            backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
            borderRight: '1px solid #ddd',
            borderBottom: 0,

        },

        tabLabel: {
            whiteSpace: 'nowrap',
            //position:'relative'
        },
        appBar: {
            boxShadow: 'none',
            borderBottom: 0,//theme.custom.border[theme.palette.type],
            //backgroundColor: '#fff',
            flexDirection: 'row',
            backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
        },
        scrollButtons: {
            flex: '0 0 23px !important'
        },
        tabContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        tabInBox: {
            width: '100%',
            border: '1px solid #ddd',
            height: '24px',
            outline: 'none',
            border: 0,
            borderBottom: '1px solid #ddd'
        },
        tabActions: {
            padding: 0
        },
        tabCloseIcon: {
            fontSize: '15px !important',
            padding: '0 1px',
            width: '20px',
            height: '20px',
            color: 'inherit !important',
            zIndex: '1350',
            verticalAlign: 'middle'
        },
        tabCloseIconChanges: {
            color: '#1b4c02 !important'
        }
    }
    );
}

export default withStyles(styles)(TabList);