 
 
 

const initialState = {
    
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CREATE_POST": {
       
    }
    default:
      return state
  }
}