import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

class ButtonNavigation extends Component {

    state = {
        value: 0,
        navList: this.props.navList
    }

    onHandleClick=(e, index, item)=>{
        this.setState({value:index});
        if(item.onClick){ item.onClick(e, item.id)}
    }
    
    render() {
        const { classes = {} } = this.props
        return (
            <div className={classes.container}>
            {
                this.state.navList.map((item, index)=>(
                    <Tooltip id="tooltip-icon" key={item.id} title={item.title} placement="top" >
                    <Button tabIndex={index} 
                      size="small"  
                      color="secondary" 
                      className={classes.button} 
                      data-active={this.state.value==index} 
                      onClick={(e)=>this.onHandleClick(e,index, item)} 
                      >
                        {item.icon}
                    </Button>
                    </Tooltip> 
                ))                                                                                                                                                                                                                                                                                                                                                    
            }
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        //borderBottom: theme.custom.border[theme.palette.type]
        //boxShadow: '0px 1px 5px 0px #ddd, 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
    },
    button: {
        padding: '3px 0px',
        minWidth: '50px',
        color: theme.palette.text.secondary,
        '&:hover':{
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
        },
        '&[data-active=true]':{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.dark
        }
    },
    icon: {
        userSelect: 'none',
        pointerEvents: 'none',
    }

});

ButtonNavigation.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(ButtonNavigation);


