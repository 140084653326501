import React, { Component } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { headerData, headerGridData } from '../../config';
import { RcGridSL } from '../../../rc-core';
import * as _ from 'lodash';

class OpenTabInner extends Component {

    state = {
        columnDefs: headerGridData.columnDefs,
        rowData: headerGridData.rowData
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps);
    }

    onGridReady = (params) => {
        this.api = params.api;
        this.api.sizeColumnsToFit();
    }

    onRowSelected = (params) => {
    }

    loadData(nextProps) {
        const props = nextProps;
        const rowData = _.cloneDeep(_.get(props.selectedTab, 'data', []));
        //add empty row
        const isEmptyRowExist = _.find(rowData, { check: '', key: '', value: '', description: '' })
        if (!isEmptyRowExist) {
            rowData.push({ id: new Date().getTime(), check: '', key: '', value: '', description: '' });
        }

        this.setState({
            rowData
        });
    }

    valueSetter = ({ newValue, oldValue, data, colDef, ...params }) => {
        console.log({ rowData: this.state.rowData, newValue, oldValue, data, colDef, params });
        const rowData = _.cloneDeep(this.state.rowData || []);
        const existingRow = _.find(rowData, { id: data.id });
        if (existingRow) {
            existingRow[colDef.field] = newValue;
        } else {
            rowData.push({ ...data })
        }

        this.props.setEnvironmentValues(rowData);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={`open-tab ${classes.root}`}>
                <RcGridSL
                    columnDefs={(this.state.columnDefs || []).map(d => ({ ...d, valueSetter: this.valueSetter }))}
                    onGridReady={this.onGridReady}
                    rowData={this.state.rowData}
                    gridOptions={{
                        onRowSelected: this.onRowSelected,
                        //onCellValueChanged: this.onCellValueChanged,
                        context: {
                            headerData
                        }
                    }}
                />
            </div>
        );
    }
};

const styles = theme => ({
    container: {
        borderBottom: theme.custom.border[theme.palette.type]
    },
    root: {
        flexGrow: 1,
        minWidth: 700,
        padding: theme.spacing(2),
    },

});

OpenTabInner.propTypes = {
    classes: PropTypes.object,
};

export const OpenTab = withStyles(styles)(OpenTabInner);

