import React, { useEffect } from 'react';
import './index.scss';
import { Modal } from '../../../rc-core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TabList from '../environment-list';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEnvironments,
    toggleEnvironmentPage,
    createNewTab,
    updateSelected,
    setSelectedTab,
    closeTab,
    setValues
} from '../../redux';


const RcEnvironment = (props) => {

    const dispatch = useDispatch();

    const { tabList = [], selectedTab, isLoading } = useSelector(state => state.environmentStore);

    const onModalClose = (d) => {
        dispatch(toggleEnvironmentPage(false));
    }

    const onSetSelectedTab = (d) => {
        dispatch(setSelectedTab(d));
    }

    const onUpdateSelected = (key, value) => {
        dispatch(updateSelected({ key, value }));
    }

    const onCloseTab = (params) => {
        dispatch(closeTab(params));
    }

    const onSetValues = (selectedEnv, envData) => {
        //Save to store
        dispatch(setValues({ selectedEnv, envData }));
        //Save to local server

    }

    const onCreateNewTab = () => {
        dispatch(createNewTab());
    }

    useEffect(() => {
        if (isLoading === false) {
            dispatch(getEnvironments());
        }

    },[isLoading]);

    const { classes } = props;
    const { showEnvironmentPage } = useSelector(state => state.environmentStore)
    return (
        <div className={`rc-login ${classes.root}`}>
            <Modal open={showEnvironmentPage}
                onClose={onModalClose}
                maxWidth="md"
                fullWidth
                classes={classes.root}
                actionNode={(
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={onModalClose} color="primary"> Close </Button>
                    </div>
                )}>
                <div className="modal-body" style={{ margin: '10px', height: '450px' }}>
                    <Grid container >
                        <Grid item xs={12}>
                            <div className={classes.tabContainer}>
                                <TabList
                                    list={tabList}
                                    selectedTab={selectedTab}
                                    setSelectedTab={onSetSelectedTab}
                                    updateSelected={onUpdateSelected}
                                    onTabClose={onCloseTab}
                                    setValues={onSetValues}
                                    onAdd={onCreateNewTab}
                                />
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </Modal>
        </div>
    );
}


const styles = theme => ({
    container: {
        borderBottom: theme.custom.border[theme.palette.type]
    },
    root: {

    },
    tabContainer: {
        display: 'flex'
    }

});

RcEnvironment.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(RcEnvironment);