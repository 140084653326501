import { decorate, observable, computed, action, autorun, toJS } from "mobx";
import * as _ from 'lodash';
import { httpClient } from '.././../rc-core';
import treeLookup from '../../rc-side-panel/services/tree-lookup';


class RcStore {

    openedTabs = [];
    selectedAPI = {
        request: {},
        response: {}
    };
    isExecuting = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        autorun((r) => {
            r.trace()
            console.log(this);
        });
    }

    openTab = (value, item) => {

        const object = _.find(this.openedTabs, (tab) => {
            return tab.id === value.id
        });
        if (!object) {
            const val = treeLookup.findNodeByParentId(this.rootStore.commonStore.apiData, item, value);
            const newTab = { ...val, pid: item.id }
            this.openedTabs.push(newTab);
            this.selectedAPI = newTab;

        } else {
            this.selectedAPI = object;
        }
    }

    onTabSelected = (id) => {
        // this.selectedTab.push(id);
    }

    setSelectedTab = (index) => {

        this.selectedAPI = this.openedTabs[index]
    }

    closeTab = ({ id }) => {
        const item = _.find(this.openedTabs, (value) => {
            return value.id === id
        });

        this.openedTabs.remove(item);
    }

    saveTabChanges = ({ id, label, parent_id }) => {
        const data = toJS(this.selectedAPI);

        data.parent_id = parent_id;
        data.changed = undefined;

        this.rootStore.sidePanel.updateAPI({ id, parent_id }, data);

        const index = _.findIndex(this.openedTabs, { id });
        this.openedTabs[index] = data;

    }

    discardTabChanges = ({ id, label }) => {
        const item = _.find(this.openedTabs, (value) => {
            return value.id === id
        });

        this.openedTabs.remove(item);
    }

    onLabelEdit = () => {

    }

    switchApi = () => {

    }

    onMethodChange = () => {

    }

    updateSelected = (key, value) => {
        let data = this.selectedAPI
        data = _.set(data, key, value);
        this.selectedAPI = { ...this.selectedAPI, ...data, changed: true };
        //TODO can be refator in future
        const index = _.findIndex(this.openedTabs, { id: this.selectedAPI.id });
        this.openedTabs[index] = this.selectedAPI;

    }

    parse(object) {
        let data = JSON.stringify(object);
        const envVariables = {};// //this.rootStore.environmentStore.getEnvironmentVariables();

        (envVariables.data || []).map(item => {
            data = data.replace(new RegExp('{' + item.key + '}'), item.value);
        });
        return JSON.parse(data);
    }

    onExecute = () => {
        this.isExecuting = true;
        let data = toJS(this.selectedAPI);
        data = _.set(data, 'request.url', data.name);
        const commonProps = {
            //baseURL: 'http://localhost:3001',
            //method: 'post'
        };
        //data = data { ...commonProps, ...data.request }
        data = this.parse(data);
        data.response = {};
        //data.responseType = '*';
        httpClient.execute(data).then(this.onExecuteSucess).catch(this.onExecuteError);
    }

    onExecuteSucess = (result) => {
        this.isExecuting = false;
        if (result.statusText !== 'ok') {
            this.onExecuteError(result);
            return;
        }

        const { data, headers, status, statusText } = result;
        const response = { data, headers, status, statusText }
        this.selectedAPI = { ...this.selectedAPI, response, changed: true }
        //TODO can be refator in future
        const index = _.findIndex(this.openedTabs, { id: this.selectedAPI.id });
        this.openedTabs[index] = this.selectedAPI;

    }

    onExecuteError = (response) => {
        this.isExecuting = false;
        this.selectedAPI = { ...this.selectedAPI, response }
        //TODO can be refator in future
        const index = _.findIndex(this.openedTabs, { id: this.selectedAPI.id });
        this.openedTabs[index] = this.selectedAPI;
    }

    getPayloadData = (key) => {
        const value = _.get(this.selectedAPI, key);

        return value ? toJS(value) : '';
    }

    createNewTab = () => {
        const newTab =
        {
            id: _.uniqueId(new Date().getTime()),
            name: "",
            label: "/",
            method: 'GET',
            description: "",
            isNew: true,
            request: {},
            response: {},
            roles: {}
        };

        this.openedTabs.push(newTab);
        this.selectedAPI = this.openedTabs[this.openedTabs.length - 1];

    }

    openTabByValue = (tabValue) => {

        const index = _.findIndex(this.openedTabs, { id: tabValue.id });
        console.log({ index });
        if (index > -1) {

            this.selectedAPI = this.openedTabs[index];
        } else {
            console.log({ index, tabValue });
            this.openedTabs.push(tabValue);
            this.selectedAPI = tabValue;
        }

    }

    getApiData = () => {
        return this.rootStore.commonStore.apiData;
    }
}

decorate(RcStore, {
    openedTabs: observable,
    selectedAPI: observable,
    isExecuting: observable,
    //elapsedTime: computed,
    onTabSelected: action,
    openTab: action,
    switchApi: action,
    onMethodChange: action,
    updateSelected: action,
    onExecute: action,
    onExecuteSucess: action.bound,
    onExecuteError: action.bound,
    createNewTab: action,
    openTabByValue: action
});


export const RcPlaygroundStore = RcStore;