import React, { Component } from 'react';
import './header-view.css';
import { headerData, headerGridData } from './header-data';
import * as _ from 'lodash';
import { RcGrid } from '../../../../rc-core';

class Headers extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    ...headerGridData
  }

  onGridReady = (params) => {
    this.api = params.api;
    this.api.sizeColumnsToFit();

  }

  getHeaders() {
    const headers = _.get(this.props.payload, 'response.headers') || []
    return _.reduce(headers, (result, value, key) => {
      result.push({ header: key, value });
      return result;
    }, [])
  }

  render() {
    return (
      <div className="rhv">
        <div
          className="ag-theme-balham"
          style={{ width: '100%', height: '200px', marginTop: '10px' }}
        >
          <RcGrid
            columnDefs={this.state.columnDefs || []}
            defaultColDef={this.state.defaultColDef}
            autoColumnDef={this.state.autoGroupColumnDef}
            onGridReady={this.onGridReady}
            rowData={this.getHeaders() || []}
            gridOptions={{
              onRowSelected: this.onRowSelected,
              onCellValueChanged: this.onCellValueChanged,
              context: {
                headerData
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default Headers;