import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

class SelectTeam extends React.Component {
    state = {
        enableEdit: false,
        selectedIndex: 0,
        team: this.props.team || {}
    };

    componentWillReceiveProps(nextProps) {
        this.setState({team: nextProps.team}) 
     }

    onChange = (e) => {
        const value = e.target.value;
        const team = { ...this.state.team, name: value };
        this.setState({ team });
        
    }

    onUpdate = event => {
        this.setState({ enableEdit: !this.state.enableEdit },
            ()=>{
                this.props.onChange(this.state.team);
            }
        );
    };

    getView() {
        if (this.state.enableEdit) {
            return (<div>
                <input
                    type="text"
                    style={{ width: '100%' }}
                    value={this.state.team.name|| ""}
                    onChange={this.onChange}
                    className={this.props.classes.input}
                />
            </div>)
        }

        return this.state.team.name;

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <List component="div">
                    <ListItem>
                        <ListItemText
                            primary={this.getView()}
                            secondary={`Created By ${this.state.team.createdByUserName}`}
                            component="div"
                        />
                        <ListItemSecondaryAction>
                            <IconButton aria-label="icon" onClick={this.onUpdate}>
                                {this.state.enableEdit ? <CheckIcon  /> : <EditIcon />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </div>
        );
    }
}

SelectTeam.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    input: {
        color: '#555',
        fontSize: '0.9rem',
        fontWeight: '400',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: '1.5em',
        border: 0,
        borderBottom: '1px solid',
        outline: 'none'
    }
});

export default withStyles(styles)(SelectTeam);