import React, { Component } from 'react';
import './scroll-bar.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from '@material-ui/core/styles';

class ScrollbarInner extends Component {

    renderVthumb = ({ style, props }) => {
        const { classes = {} } = this.props
        return (<div {...props} style={{ ...style }} className={classes.vthumb} />);
    }

    render() {
        return (<div className="scroll-bar">
            <Scrollbars 
                      {...this.props} 
                      renderThumbVertical={this.renderVthumb}
                      >
                        {this.props.children}
            </Scrollbars>
        </div>);

    }
}


const styles = theme => ({
    container: {
    },
    vthumb: {
        backgroundColor: theme.custom.sidePanelScrollbar[theme.palette.type],
    }
});

export const Scrollbar= withStyles(styles)(ScrollbarInner);