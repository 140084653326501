import React, { Component } from 'react';
import './save-payload-modal.css';
import * as _ from 'lodash';
import { Modal } from '../../../rc-core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';

class SavePayloadModal extends Component {

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open || false, collection: nextProps.selectedCollection });
    }

    state = {
        open: this.props.open || false,
        collectionName: [],
        collection: this.props.selectedCollection
    }

    onChange = (data) => {
        this.setState({ collection: data });
        this.props.onChange(data);
    }

    getCollectionList() {
        return this.props.options;
    }

    onModalClose = () => {
        this.setState({ open: false, collection: [] });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    onDiscard = () => {
        this.props.onDiscard();
        this.onModalClose();
    }

    onSaveApiConfirm = () => {
        const { id } = this.state.collection[0]
        this.props.onSaveApiConfirm(id);
        this.onModalClose();
    }

    render() {
        return (
            <Modal open={this.state.open}
                onClose={this.onModalClose}
                title={'Save changes'}
                actionNode={(
                    <div>
                        <Button size="small" onClick={this.onDiscard} color="primary">
                            Discard
                    </Button>

                        <Button size="small" onClick={this.onSaveApiConfirm} color="primary">
                            Save
                    </Button>
                        <Button size="small" onClick={this.onModalClose} color="primary">
                            Close
                    </Button>
                    </div>
                )}
            >
                <div className="modal-body" style={{ width: '300px', minHeight: '300px', position: 'relative' }}>

                    <Typeahead
                        disabled={false}
                        dropup={false}
                        flip={false}
                        highlightOnlyResult={false}
                        minLength={0}
                        selectHintOnEnter={false}
                        //emptyLabel={''}
                        onChange={this.onChange}
                        options={this.getCollectionList() || []}
                        selected={this.state.collection}
                        onInputChange={this.onInputCollectionChange}
                        placeholder="Choose collection"
                        style={{ position: 'absolute', height: '100px' }}
                        allowNew={true}
                        labelKey={'name'}
                        id={'save-payment-typehead'}
                    />
                </div>
            </Modal>
        )
    }


}

SavePayloadModal.propsType = {
    options: PropTypes.array,
    open: PropTypes.bool,
    onDiscard: PropTypes.func,
    onSaveApiConfirm: PropTypes.func,
    onModalClose: PropTypes.func,
    onCollectionSelect: PropTypes.func,
    selectedCollection: PropTypes.array
}

SavePayloadModal.defaultProps = {
    options: []
}

export default SavePayloadModal;