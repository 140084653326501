import 'babel-polyfill';
//import 'proxy-polyfill/src/proxy';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import registerServiceWorker from './registerServiceWorker';
import rcStore from './store';
import { Provider } from 'mobx-react';

ReactDOM.render(
    <Provider rootStore={rcStore}>
        <App />
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();
