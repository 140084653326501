import React, { Component } from 'react';
import './header.css';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import SaveAlt from '@material-ui/icons/Archive';
import Add from '@material-ui/icons/Add';
import ListAlt from '@material-ui/icons/Assignment';
import PinDrop from '@material-ui/icons/BookmarkBorder';
import Group from '@material-ui/icons/Group';
import History from '@material-ui/icons/History';
import { Modal } from '../../../rc-core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {FileIOService} from '../../../rc-core/';
import { observer, inject } from 'mobx-react';
import ButtonNavigation from './button-navigation';
import {AddCollection} from '../add-collections';

const Header = inject('rootStore')(observer(
  class Header extends Component {

    state = {
        open: false,
        navList:[
            {id:"list", icon: <ListAlt  />, title:"List all collections", onClick: this.props.onShowCollection},
            {id:"recent", icon: <History />, title:"Recently used" , onClick:this.props.onShowHistory},
            {id:"bookmarked", icon: <PinDrop />,title:"Bookmarked", onClick: this.props.onShowBookmarked},
            //{id:"filter", icon: <FilterList />, title:"Filter" , onClick:this.props.onSortCollection},
            
            {id:"team", icon: <Group />,title:"Manage Team" , onClick: this.props.onShowTeam, allowToLoginUser: true},
        ]
    }


    onAddCollection = () => {
        this.setState({ open: true });
    }

    onDownloadCollection = () => {
        const {commonStore} = this.props.rootStore;
        FileIOService.download("collection_"+new Date().getTime()+".json", JSON.stringify(commonStore.apiData));
    }
 

    onModalClose = () => {
        this.setState({ open: false });
    }

    onAddCollectionConfirm = (data) => {
        const {collectionName,baseURL} = data;
        this.props.onAddCollection({ collectionName,baseURL});
        this.onModalClose();
    }

    onAddCollectionBulk=(data)=> {
      this.props.onAddCollectionBulk(data);
    }

    handleChange = (e) => {
        this.collectionName = e.target.value;
    }

    handleBaseUrlChange = (e) => {
        this.collectionBaseURL = e.target.value;
    }

    isAnonymousUser() {
        return this.getStore().commonStore.user.isAnonymous;
    }

    getNavList=() => {
        const navList= this.navList;
        const list=[];
        navList.map(nav=> {
            if(nav.allowToLoginUser===true){
                if(this.isAnonymousUser()){
                   list.map(nav);
                   return;
            }}

            list.map(nav);
            
        })
        return list;
    }

    render() {

        const { classes = {} } = this.props
        return (
            <div className={`top-menu ${classes.container}`}>

                <div className="menu-section__left">
                <Tooltip id="tooltip-icon" title="Download" placement="top" >
                    <Button  size="medium" color="primary" className="button" onClick={this.onDownloadCollection}>
                        <SaveAlt className="icon"  />
                    </Button>
                </Tooltip >
                </div>
                <div className="menu-section__center">
                   <ButtonNavigation 
                     navList={this.state.navList}
                   />
                </div>
                <div className="menu-section__right">
                <Tooltip id="tooltip-icon" title="Create new folder" placement="top" >
                    <Button  size="medium" color="primary" className="button" onClick={this.onAddCollection} >
                        <Add className="icon" />
                    </Button>
                </Tooltip>
                </div>

             
                     <AddCollection 
                        onAddCollectionConfirm={this.onAddCollectionConfirm}
                        onAddCollectionBulk={this.onAddCollectionBulk}
                        onModalClose={this.onModalClose}
                        open={this.state.open}
                     />
                       {/* <TextField
                        id="collection"
                        label="Enter collection name"
                        className={classes.textField}
                        value={this.state.collectionName}
                        onChange={this.handleChange}
                        margin="normal"
                        ref={this.inputRef}
                    />
                   
                
                      <TextField
                        id="collection"
                        label="Enter base url"
                        className={classes.textField}
                        value={this.state.collectionName}
                        onChange={this.handleBaseUrlChange}
                        margin="normal"
                        ref={this.baseUrlRef}
                    />
                     */}
               
            </div>
        );
    }
}
));

const styles = theme => ({
    container: {
        //borderBottom: theme.custom.border[theme.palette.type],
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    button: {
        margin: '0 5px',
        height:'30px'
    }

});


Header.propTypes = {
    classes: PropTypes.object,
    onShowCollection:PropTypes.func,
    onSortCollection:PropTypes.func,
    onShowTeam:PropTypes.func,
    onShowHistory:PropTypes.func,
    onShowBookmark: PropTypes.func,
    onAddCollectionBulk: PropTypes.func,
};

export default withStyles(styles)(Header);


