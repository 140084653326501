import * as _ from "lodash";
import store from "../../store";
class HttpClient {
  CLIHost = "";
  hostList = [
    { host: "https://internal.apisailor.com:9443", order: 1, located: false },
    { host: "http://localhost:3902", order: 2, located: false },
    //{ host: "http://localhost:3901", order: 3, located: false },
  ];
  availableHostMap = {};
  BASE_URL_PREFIX = "/sailor/api/v1";
  retryCount = 0;

  locateHosts = async () => {
    return await new Promise(async (resolve, reject) => {
      const hostList = await this.hostList.reduce(
        async (accumulation, item, index) => {
          const result = await this.isHostAvailable(item.host);
          const updatedList = await accumulation;
          updatedList.push({ ...item, located: result });
          return Promise.resolve(updatedList);
        },
        Promise.resolve([])
      );

      this.hostList = hostList;
      const activehost = this.getActiveHost();

      this.CLIHost = activehost.host;
      console.log({ activehost, hostList: this.CLIHost });

      return resolve(hostList);
    });
  };

  COMMON_PROPS = {
    crossDomain: true,
    "Content-Type": "application/json",
    mode: "cors",
    //credentials: "include",
  };

  execute(data) {
    const request = {
      // `url` is the server URL that will be used for the request
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/execute",
      // `method` is the request method to be used when making the request
      method: "POST",
      // `headers` are custom headers to be sent
      ...this.COMMON_PROPS,
      headers: {
        "Content-Type": "application/json",
        Referer: "workspace.apisailor.com",
        Host: "workspace.apisailor.com",
        "X-Requested-With": "XMLHttpRequest",
      },
      mode: "cors",
      //credentials: true,
      body: JSON.stringify(data),
    };

    return this.fetch(request.url, request);
  }

  checkCLIAvailability = () => {
    fetch(
      this.getCLIHost() + this.BASE_URL_PREFIX + "/health/",
      this.COMMON_PROPS
    )
      .then(async (res) => {
        if (!res.ok) {
          //this.isCLIAvailable=false;
          store.commonStore.setServerAvailable(false);
        }
        const body = await res.json();

        if (body.msg) {
          //this.isCLIAvailable=true;
          store.commonStore.setServerAvailable(true);
        }
      })
      .catch((e) => {
        //this.isCLIAvailable=false;
        store.commonStore.setServerAvailable(false);
      });
  };

  getActiveHost() {
    //const keys = _.keys(this.availableHostMap);
    const activeHost = this.hostList
      .sort((a, b) => a.order > b.order)
      .filter((item) => item.located === true);

    return activeHost.length > 0 ? activeHost[0] : "";
  }

  async isHostAvailable(url) {
    return await fetch(
      url + this.BASE_URL_PREFIX + "/health/",
      this.COMMON_PROPS
    )
      .then(async (res) => {
        if (!res.ok) {
          store.commonStore.setServerAvailable(false);
          return false;
        }
        const body = await res.json();

        if (body.msg) {
          store.commonStore.setServerAvailable(true);
          return true;
        }
        return false;
      })
      .catch((e) => {
        store.commonStore.setServerAvailable(false);
        return false;
      });
  }

  checkHostAvailability(hostList = []) {
    //this.retryCount = this.retryCount + 1;
    return hostList.reduce(async (accumulation, item) => {
      const result = await this.isHostAvailable(item);
      console.log({ accumulation, item, result });
      accumulation[`${item}`] = result;
      return accumulation;
    }, {});
  }

  getHistory() {
    store.sidePanel.setLoading(true);
    return fetch(
      this.getCLIHost() + this.BASE_URL_PREFIX + "/history/all",
      this.COMMON_PROPS
    )
      .then((res) => res.json())
      .then((res) => {
        store.sidePanel.setLoading(false);
        return res;
      })
      .catch((e) => {
        store.sidePanel.setLoading(false);
        return e;
      });
  }

  addBookmark(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/bookmark",
      method: "POST",
      body: JSON.stringify(doc),
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  getBookmarks = () => {
    //store && store.sidePanel.setLoading(true);
    const options = { method: "GET", ...this.COMMON_PROPS };
    return fetch(
      this.getCLIHost() + this.BASE_URL_PREFIX + "/bookmark",
      options
    )
      .then((res) => res.json())
      .then((res) => {
        store.sidePanel.setLoading(false);
        return res;
      })
      .catch((e) => {
        store.sidePanel.setLoading(false);
        return e;
      });
  };

  getCollections = () => {
    console.log({ store });
    // store &&  store.sidePanel.setLoading(true);
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/collections",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(options.url, options)
      .then((res) => res.json())
      .then((res) => {
        store.sidePanel.setLoading(false);
        return res;
      })
      .catch((e) => {
        store.sidePanel.setLoading(false);
        return e;
      });
  };

  addCollection(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/collections",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  deleteCollections(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/collections",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  getDocuments() {
    store.sidePanel.setLoading(true);
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/entity",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(options.url, options)
      .then((res) => res.json())
      .then((res) => {
        store.sidePanel.setLoading(false);
        return res;
      })
      .catch((e) => {
        store.sidePanel.setLoading(false);
        return e;
      });
  }

  addDocument(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/entity",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  deleteDocuments(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/entity",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  getEnvironments() {
    //store.sidePanel.setLoading(true);
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/environments",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return fetch(options.url, options)
      .then((res) => res.json())
      .then((res) => {
        //store.sidePanel.setLoading(false);
        return res;
      })
      .catch((e) => {
        //store.sidePanel.setLoading(false);
        return e;
      });
  }

  addEnvironments(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/environments",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  deleteEnvironments(doc) {
    const options = {
      ...this.COMMON_PROPS,
      url: this.getCLIHost() + this.BASE_URL_PREFIX + "/environments",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(doc),
    };
    return fetch(options.url, options).then((res) => res.json());
  }

  getCLIHost() {
    return this.CLIHost ? this.CLIHost : "";
  }

  fetch(url, options) {
    return fetch(url, options)
      .then(async (res) => {
        let resData, headers;
        const { status, statusText } = res;

        if (!res.ok) {
          return {
            data: await res.text(),
            headers: {},
            status,
            statusText,
          };
        }
        resData = await res.text();
        resData = JSON.parse(resData);

        return new Promise((r) =>
          r({
            data: resData.data,
            headers: resData.headers,
            status: resData.status,
            statusText: resData.statusText,
          })
        );
      })
      .catch((error, c) => {
        return new Promise((r) =>
          r({
            data: error.toString(),
            headers: {},
            status: 500,
            statusText: "fail",
          })
        );
      });
  }
}

export const httpClient = new HttpClient();
