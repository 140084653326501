import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListHeader from './list-header';
import SubList from './sub-list';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class CollectionList extends Component {

    colors = ['#cfcfcf', '#ffbc79', '#a2c8ec', '#898989', '#c85200', '#5f9ed1', '#595959', '#ababab', '#ff800e', '#006ba4'];

    handleClick = (e, item, index) => {

        this.props.handleClick(e, item, index);
    };

    onActionClick = (e, item, newVal, eventName, apiNode) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onActionClick(e, item, newVal, eventName, apiNode);
    }

    onDataListItemClick = (node, pid) => {
        this.props.openTab(node, pid);
    }

    onDrop = (e) => {

    }

    onDragover = (e) => {

    }

    getNode(nodeData, iteration, list) {
        const { classes } = this.props;
        nodeData.map((item, index) => {

            const key = item.id;
            list.push(
                <Collapse style={{ paddingLeft: `${(item.level - 1) * 10}px` }} key={key} in={item.open || item.root} timeout="auto" unmountOnExit>
                    <ListItem button={item.editable ? false : true} onClick={(e) => this.handleClick(e, item, index)} className={classes.listItem}>
                        {<ListHeader item={item} onActionClick={this.onActionClick} />}
                    </ListItem>
                </Collapse>
            );

            list.push(
                <SubList
                    style={{ paddingLeft: `${(item.level - 1) * 10 + 10}px` }}
                    key={`sub-${item.id}`}
                    item={item}
                    data={item.data}
                    open={item.showData && (item.open || item.root)}
                    editable={item.editable}
                    onClick={this.onDataListItemClick}
                    onActionClick={this.onActionClick}
                    onDrop={this.onDrop}
                    onDragover={this.onDragover}
                />);
            if (item.children && item.children.length > 0) {
                this.getNode(item.children, index, list);
                return;
            }
        })

        return list;

    }

    getData() {
        return this.props.data;
    }

    render() {
        const { classes = {} } = this.props
        return (
            <div className={classes.root}>
                <List>
                    {
                        (() => {
                            const list = [];
                            this.getNode(this.getData(), 0, list);
                            return list;
                        })()
                    }
                </List>
            </div>
        );
    }
}


const styles = theme => ({
    root: {
        width: '100%'
    },
    listItem: {
        paddingTop: '5px',
        paddingBottom: '5px',
    }
});

CollectionList.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(CollectionList);
