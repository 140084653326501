import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './rc-grid.css';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'ag-grid/dist/styles/ag-theme-balham-dark.css';
import HeaderSelectRenderer from './cell-renderer/header-select/header-select';
import HeaderValueSelectRenderer from './cell-renderer/header-value-select/header-value-select';
import HeaderDescriptionRenderer from './cell-renderer/header-description/header-description';
import * as _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class RcGridInner extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {

        this.gridApi && this.gridApi.sizeColumnsToFit()
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onGridReady = (params) => {
        this.props.onGridReady(params);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    onResize = () => {
        this.gridApi.sizeColumnsToFit()
    }

    render() {
        return (
            <div className="rc-grid">
                <div
                    className={RcGridInner.defaultProps.theme}
                    style={{ width: '100%', height: '200px', marginTop: '10px' }}
                >

                    <AgGridReact
                        columnDefs={this.props.columnDefs}
                        defaultColDef={this.props.defaultColDef}
                        rowDragManaged={true}
                        animateRows={true}
                        enableSorting={true}
                        enableFilter={true}
                        onGridReady={this.onGridReady}
                        rowData={this.props.rowData}
                        rowSelection='multiple'
                        autoColumnDef={this.props.autoGroupColumnDef}
                        gridOptions={{
                            frameworkComponents: {
                                headerSelectRenderer: HeaderSelectRenderer,
                                headerDescriptionRenderer: HeaderDescriptionRenderer,
                                headerValueSelectRenderer: HeaderValueSelectRenderer
                            },
                            ...this.props.gridOptions,
                        }}
                    />

                </div>
            </div>
        );
    }
}

RcGridInner.propTypes = {
    classes: PropTypes.object.isRequired,
};

RcGridInner.defaultProps = {
    mode: 'json',
    expanded: false,
    theme: 'xcode'
}

const styles = theme => {

    RcGridInner.defaultProps.theme = theme.custom.gridTheme[theme.palette.type];

    return {
        root: {
            display: 'flex',
            width: '100%',
        }
    };
}
export const RcGrid = withStyles(styles)(RcGridInner);
