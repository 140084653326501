import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { httpClient } from '../../rc-core'

const defaultTabList = [{
  id: _.uniqueId(new Date().getTime()), label: 'LOCAL', data: []
},
{
  id: _.uniqueId(new Date().getTime()), label: 'DEV', data: []
},
{
  id: _.uniqueId(new Date().getTime()), label: 'TEST', data: []
},
{
  id: _.uniqueId(new Date().getTime()), label: 'STAGE', data: []
},
{
  id: _.uniqueId(new Date().getTime()), label: 'UAT', data: []
},
{
  id: _.uniqueId(new Date().getTime()), label: 'PROD', data: []
}
];

const initialOtpState = {
  isLoading: false,
  defaultTabList: defaultTabList,
  tabList: [],
  selectedTab: null,
  showEnvironmentPage: false,
  selectedEnvByUser: []
};

/**
 * Thunks Actions
 */
const getEnvironments = createAsyncThunk(
  'get/environments',
  async (request, thunkAPI) => {
    let response = (await httpClient.getEnvironments(request)) || [];
    response = _.isEmpty(response) ? initialOtpState.defaultTabList : response;
    return response;
  },
);


/**
 * Reducers
 */
const environmentReducer = createSlice({
  name: 'environments',
  initialState: initialOtpState,
  reducers: {
    setSelectedTab: (state, { payload }) => {
      state.selectedTab = state.tabList[payload];
    },
    closeTab: (state, { payload }) => {
      const item = _.find(state.tabList, (value) => {
        return value.id === payload.id
      });
      state.tabList = _.reject(state.tabList, { id: payload.id })
      state.selectedTab = null;
    },
    saveTabChanges: (state, { payload }) => {
      const data = state.selectedTab;
      const index = _.findIndex(state.tabList, { id: payload.id });
      state.tabList[index] = data;
    },
    discardTabChanges: (state, { payload }) => {
      const item = _.find(state.tabList, (value) => {
        return value.id === payload.id
      });
      state.tabList.remove(item);
    },
    updateSelected: (state, { payload }) => {

      let data = state.selectedTab
      data = _.set(data, payload.key, payload.value);
      state.selectedTab = { ...state.selectedTab, ...data };
      //TODO can be refator in future
      const index = _.findIndex(state.tabList, { id: state.selectedTab.id });
      state.tabList[index] = state.selectedTab;
      return state;
    },
    setValues: (state, { payload }) => {

      const value = payload.selectedEnv || state.selectedTab;
      const updatedState = { ...value, data: payload.envData }
      const index = _.findIndex(state.tabList, { id: value.id });
      state.tabList[index] = updatedState;
      state.selectedTab = updatedState;
      httpClient.addEnvironments(state.tabList)

    },

    createNewTab: (state) => {
      const newTab =
      {
        id: _.uniqueId(new Date().getTime()),
        label: "/",
        isNew: true,
        data: []
      };
      state.tabList.push(newTab);
      state.selectedTab = state.tabList[state.tabList.length - 1];
    },

    toggleEnvironmentPage: (state, { payload }) => {
      state.showEnvironmentPage = payload;
      return state;
    },

    getEnvironmentVariables: (state) => {
      if (state.selectedEnvByUser) {
        return state.selectedEnvByUser;
      } else { };
    },

    getTabList: (state) => {
      return state.tabList;
    },
    setEnv: (state, { payload = [] }) => {
      const data = payload[0];
      const selectedEnvByUser = _.find(state.tabList, { id: data.id });
      state.selectedEnvByUser = selectedEnvByUser || {};
      state.selectedTab = selectedEnvByUser || {};
    }

  },
  extraReducers: (builder) => {
    builder.addCase(
      getEnvironments.fulfilled,
      (state, { payload }) => {
        state.tabList = payload;
        state.isLoading = true;
      },
    );
    builder.addCase(getEnvironments.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectType` in `updateUser`, the type information will be available here.

        state.error = action.error;
      }
    },
    );
  },
});

/*Actions export*/
const {
  setSelectedTab,
  closeTab,
  saveTabChanges,
  discardTabChanges,
  updateSelected,
  setValues,
  createNewTab,
  toggleEnvironmentPage,
  getEnvironmentVariables,
  getTabList,
  setEnv,
} = environmentReducer.actions;
export {
  setSelectedTab,
  closeTab,
  saveTabChanges,
  discardTabChanges,
  updateSelected,
  setValues,
  createNewTab,
  toggleEnvironmentPage,
  getEnvironmentVariables,
  getTabList,
  getEnvironments,
  setEnv
};
/*Reducer export*/
export default environmentReducer.reducer;
