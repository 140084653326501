
const members=[{ id: '234567', name: 'Amit', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Rohit', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'David', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Anne', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Angila', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Ravi', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Ram', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Arpan', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Navin', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Gaurav', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Karan', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Ravi', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Alie', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } },
{ name: 'Alex', createdBy: 'Narender', timestamp: 'Jan 7, 2018', roles: { read: true, write: true, delete: true } }
]
export const options = [
    {
        name: 'Schmodel front-end Team', logo: '', organization: '', description: '', createdBy: 'Naren Rana', timestamp: 'Jan 7, 2018',
        members_ids:members.map(m=>m.id),
        members: members,
        collections: []
    }
];

export const users=[
{ id: new Date().getMilliseconds()+1, name: 'Amit1', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+2, name: 'Amit2', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+3, name: 'Amit3', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+4, name: 'Amit4', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+5, name: 'Amit5', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+6, name: 'Amit6', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+7, name: 'Amit7', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+8, name: 'Amit8', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
{ id: new Date().getMilliseconds()+9, name: 'Amit9', createdBy: 'Narender', timestamp: 'Jan 7, 2018'},
]