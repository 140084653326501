import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import * as _ from 'lodash';
import TextField from '@material-ui/core/TextField';

class UploadPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            apiData: []
        }
    }

    onAddCollectionBulk = () => {
        const data = {
            app_id: null,
            baseUr: "",
            children: this.state.apiData,
            created: new Date().getTime(),
            data: [],
            id: new Date().getTime() + _.uniqueId(),
            level: 1,
            name: this.state.name,
            parent_id: null,
            root: true
        }
        this.props.onAddCollectionBulk(data);
    }

    componentDidMount() {

        Dropzone.autoDiscover = false;
        this.dropzone = new Dropzone(this.dropDown, {
            url: 'http://localhost:3902/sailor/api/v1/upload',
            maxFiles: 1,
            maxFilesize: 1024 * 100,
            chunking: false,
            uploadMultiple: false,
            addRemoveLinks: true,
        });
        this.dropzone.on("addedfile", this.onFileAdd);
        this.dropzone.on("removedfile", this.onRemovedfile);
        this.dropzone.on("totaluploadprogress", this.onProgress);
        this.dropzone.on("complete", this.onComplete);
    }

    onRemovedfile = (e) => {
        this.dropzone.on();
    }

    onProgress = (e) => {

    }
    onComplete = (a, b) => {
        const xhr = a.xhr;
        if (xhr.statusText === 'OK') {
            const response = JSON.parse(xhr.response)
            const apiData = response.apiData;
            this.setState({ apiData });
        }

    }

    onFileAdd = async (file) => {

        if (this.dropzone.files.length > 1) {
            this.dropzone.files[1]._removeLink.click();
        }

    }

    handleNameChange = (e) => {
        const value = e.target.value;
        this.setState({ name: value });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div ref={(val) => this.dropDown = val} className={"dropzone " + classes.uploadContainer}>
                    <Add color="primary" className={classes.uploadIcon} />
                </div>
                <TextField
                    id="name"
                    label="Enter collection name"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    fullWidth
                    margin="normal"
                />
                <Button
                    className={classes.button}
                    onClick={this.onAddCollectionBulk}
                    variant="contained"
                    color="primary"
                > Save </Button>
            </div>
        );
    }
}

const styles = theme => ({
    uploadContainer: {
        width: '100%',
        border: '1px dotted #ddd',
        height: '150px',
        zIndex: 9,
        position: 'relative',
        padding: '40px',
    },
    uploadIcon: {
        position: 'absolute',
        top: '65%',
        left: '45%',
        opacity: '0.5',
        mouseEvent: 'none',
        width: '2em',
        height: '2em',
        fontWeight: 600
    },
    buttonUpload: {
        display: 'flex',
        border: '1px dotted #ddd',
        zIndex: 1
    },
    button: {
        margin: '5px',
    },
    root: {
        '& .dz-remove': {
            color: 'gray',
            textDecoration: 'none',
            fontSize: '12px !important',
            fontWeight: '500',
            margin: '5px 0'
        },
        '& .dropzone.dz-clickable': {
            cursor: 'pointer',
            position: 'relative',
            overflowX: 'hidden',
            scrollBehavior: 'auto',
            position: 'relative'
        },
        '& .dropzone .dz-preview.dz-file-preview .dz-image': {
            background: 'linear-gradient(to bottom, #43a047, #f5f7f7)'
        },
        '& .dropzone .dz-preview .dz-details .dz-size': {
            fontSize: '12px'
        }
    }
});

export default withStyles(styles)(UploadPanel)

