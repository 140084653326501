import React, { Component } from 'react';
import { toJS } from "mobx";
import './api-payload.css';
import Button from '@material-ui/core/Button';
import RequestTab from '../request-tab/request-tab';
import ResponseTab from '../response-tab/response-tab';
import * as _ from 'lodash';
import APIAutocomplete from '../api-autocomplete/api-autocomplete';
import APIDescription from '../api-description/api-description';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Typography from '@material-ui/core/Typography';

class APIPayload extends Component {

    state = {
        open: false,
        enableRequestPayload: true
    };

    searchAllList() {
        return this.props.allAPIlist;
    }

    onPayloadChange = (e) => {
        this.setState({ enableRequestPayload: !this.state.enableRequestPayload });
    }

    onExecute = (props) => {

        this.props.onExecute();
        this.setState({ enableRequestPayload: false });
    }

    onSave = (props) => {
        this.props.onSave();
    }

    onURLChange = (value) => {
        console.log({ value });
        //this.props.updateSelected('name', _.trim(value))
    }

    onInputChange = (value) => {
        this.props.updateSelected('name', _.trim(value))
    }

    onMethodSelect = (method) => {
        this.props.updateSelected('request.method', _.trim(method))
    }

    setRequestBody = (value, type) => {
        //TODO about type
        this.props.updateSelected('request.body', value)
    }

    setResponseBody = (value, type) => {
        //TODO about type
        this.props.updateSelected('response.data', value)
    }

    setHeaders = (list) => {
        this.props.updateSelected('request.headers', list)
    }

    setScript = (value) => {
        this.props.updateSelected('request.script', value)
    }

    onDescriptionUpdate = (value) => {
        this.props.updateSelected('description', value)
    }

    onSplitView = () => {
        this.setState({ splitView: !this.state.splitView });
    }

    getStatusStyle = (status) => {
        const { classes } = this.props
        const { enableRequestPayload } = this.state;

        if (enableRequestPayload) {
            return classes.hidden;
        }

        return status !== 200 ? (classes.typography + ' ' + classes.typographyFailed) : classes.typography;
    }

    render() {
        const {
            enableRequestPayload
        } = this.state;

        const { classes } = this.props;
        const payload = toJS(this.props.payload);
        const { status, statusText } = payload.response;

        if (_.isEmpty(payload)) {
            return "";
        }

        return (
            <Grid container style={{ position: 'relative' }}>
                <Grid item xs={12}>
                    <APIAutocomplete
                        options={this.searchAllList()}
                        payload={payload}
                        onMethodSelect={this.onMethodSelect}
                        onExecute={this.onExecute}
                        onSave={this.onSave}
                        onChange={this.onURLChange}
                        onInputChange={this.onInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <div className="payload">
                            {!this.state.splitView &&
                                <Grid item xs={6} className={classes.leftPanelAction} >
                                    <div>
                                        <Button variant="contained" color="primary" size="small" id="request" className={`${enableRequestPayload ? 'payload__icon payload__icon--active' : 'payload__icon'}`} style={{ marginRight: '1px' }} onClick={this.onPayloadChange}>
                                            <span style={{ fontSize: '10px' }}>Request</span>
                                        </Button>
                                        <Button variant="contained" color="primary" size="small" id="response" className={`${!enableRequestPayload ? 'payload__icon payload__icon--active' : 'payload__icon'}`} onClick={this.onPayloadChange} >
                                            <span style={{ fontSize: '10px' }}>Response</span>
                                        </Button>
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={this.state.splitView ? 12 : 6} className={classes.rightPanelAction}>
                                <Typography component="div" className={this.getStatusStyle(status)}  >
                                    Status: {status}
                                </Typography>
                                <Typography component="div" className={this.getStatusStyle(status)}  >
                                    Status Text: {statusText}
                                </Typography>
                                <Button color="secondary" onClick={this.onSplitView} className={classes.button}>
                                    <ViewColumn className={classes.splitButton} color="secondary" />
                                </Button>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} >
                        {!this.state.splitView ?
                            (enableRequestPayload ?

                                <RequestTab payload={payload}
                                    setBody={this.setRequestBody}
                                    setHeaders={this.setHeaders}
                                    setScript={this.setScript}
                                    getPayloadData={this.props.getPayloadData}
                                />

                                :

                                <ResponseTab payload={payload}
                                    getPayloadData={this.props.getPayloadData}
                                    setBody={this.setResponseBody}
                                    isExecuting={this.props.isExecuting}
                                />

                            )
                            : <Grid container spacing={12} >
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <div className={classes.verticalBar}>
                                        <Button fullWidth size="small"   >
                                            <span variant="caption" style={{ fontSize: "10px" }}>Request</span>
                                        </Button>
                                        <RequestTab payload={payload}
                                            setBody={this.setRequestBody}
                                            setHeaders={this.setHeaders}
                                            setScript={this.setScript}
                                            getPayloadData={this.props.getPayloadData}
                                            splitView={this.state.splitView}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className={classes.verticalBar}>
                                        <Button fullWidth size="small"  >
                                            <span variant="caption" style={{ fontSize: "10px" }}>Response</span>
                                        </Button>
                                        <ResponseTab payload={payload}
                                            getPayloadData={this.props.getPayloadData}
                                            setBody={this.setResponseBody}
                                            splitView={this.state.splitView}
                                            isExecuting={this.props.isExecuting}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'none' }}>
                    <APIDescription payload={payload} onDescriptionUpdate={this.onDescriptionUpdate} onSave={this.onSave} />

                </Grid>
            </Grid>
        );
    }
}

APIPayload.propTypes = {
    classes: PropTypes.object,
    list: PropTypes.array,
    payload: PropTypes.object,
    onSave: PropTypes.func,
};

APIPayload.defaultProps = {
    list: [],
    payload: {}
}

const styles = theme => ({
    container: {
        borderBottom: theme.custom.border[theme.palette.type && theme.palette.type === 'light']
    },
    leftPanelAction: {
        display: "flex",
        justifyContent: "flex-start"
    },
    rightPanelAction: {
        display: "flex",
        justifyContent: "flex-end"
    },

    verticalBar: {
        border: '1px solid #ddd',
        margin: '0 2px',
        padding: '2px',
        '&:first-child': {
            //borderRight:0,
        },
        '&:last-child': {
        }
    },
    button: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#999'
    },
    splitButton: {
        color: '#4ca047'
    },
    typography: {
        fontSize: '10px',
        lineHeight: '32px',
        fontWeight: 500
    },
    typographyFailed: {
        color: '#ff0000c9'
    },
    hidden: {
        display: 'none'
    }
});

export default withStyles(styles)(APIPayload);

