import React, { Component } from 'react';
import './rc-login.css';
import { Modal } from '../rc-core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { observer, inject } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const LoginComponent = inject('rootStore')(observer(
    class LoginInner extends Component {

        state = {

        }

        onModalClose = () => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.toggleLoginPage(false);
        }

        toggleLogin = () => {
            debugger;
            this.setState({ showLogin: !this.state.showLogin });
        }

        onChange = (e) => {
            const { rootStore: { commonStore } } = this.props;
            const key = e.target.id;
            const value = e.target.value;
            commonStore.updateLoginPageState(key, value);
        }

        onLogin = () => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.login();
        }

        onSignup = () => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.signup();
        }

        onLoginWithGoogle=() => {
            
            const { rootStore: { commonStore } } = this.props;
             commonStore.googleSignInWithPopup();
        }

        onLoginWithFacebook=() => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.facebookSignInWithPopup();  
        }

        
        onLoginWithTwitter=() => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.twitterSignInWithPopup();  
        }

        onLoginWithGithub=() => {
            const { rootStore: { commonStore } } = this.props;
            commonStore.githubSignInWithPopup();  
        }   


        render() {
            const { classes } = this.props;
            const { spacing } = this.state;
            const { rootStore: { commonStore: { showLoginPage} } } = this.props;
            return (
                <div className="rc-login">
                    <Modal open={showLoginPage}
                        onClose={this.onModalClose}

                        maxWidth="md"
                        fullWidth
                        classes={classes.root}
                        actionNode={(
                            <div>
                                <Button variant="contained" color="primary" size="small" onClick={this.onModalClose} color="primary">
                                    Close
                                </Button>
                            </div>
                        )}
                    >
                        <div className="modal-body" style={{ margin: '10px' }}>
                            <form>
                            <Grid container spacing={16}>

                                <Grid item xs={12} sm container>
                                    <Grid xs={4} item>
                                        <Grid item>
                                            <ButtonBase className={classes.image}>
                                                <img className={classes.img} alt="complex" src="/static/logo.png" />
                                            </ButtonBase>
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant="contained" onClick={this.onLoginWithFacebook} color="primary" size="small" className={classNames(classes.button, classes.fbRoot)}>
                                                Login with facebook
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant="contained" onClick={this.onLoginWithGoogle}  color="primary"  size="small" className={classNames(classes.button, classes.gleRoot)}>
                                                Login with google
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant="contained" onClick={this.onLoginWithTwitter}  color="primary"  size="small" className={classNames(classes.button, classes.twtRoot)}>
                                                Login with twitter
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Button variant="contained" onClick={this.onLoginWithGithub}  color="primary"  size="small" className={classNames(classes.button, classes.githubRoot)}>
                                                Login with github
                                            </Button>
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid xs={8} item>
                                        {!this.state.showLogin ? (
                                            <div>
                                                <p>Please Log In</p>
                                                <TextField
                                                    id="email"
                                                    label="Username"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter username "
                                                    suggested= "username"
                                                    //helperText="Full width!"
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={this.onChange}
                                                />
                                                <TextField
                                                    id="password"
                                                    label="Password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="password"
                                                    placeholder="Enter password "
                                                    fullWidth
                                                    autoComplete="current-password"
                                                 margin="normal"
                                                    onChange={this.onChange}
                                                    defaultValue=""
                                                />
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    className={classNames(classes.button, classes.fbRoot)}
                                                    onClick={this.onLogin}
                                                >
                                                    Login
                                            </Button>
                                                <p> <a href="#">Forgot Password?</a>. Don't have an account?<a href="#" onClick={this.toggleLogin}> Get started</a></p>
                                            </div>


                                        ) :
                                            (
                                                <div>
                                                    <TextField
                                                        id="email"
                                                        label="Username"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        placeholder="Enter username "
                                                        suggested= "username"
                                                        //helperText="Full width!"
                                                        onChange={this.onChange}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                    <TextField
                                                        id="password"
                                                        label="Password"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        type="password"
                                                        placeholder="Enter password "
                                                        fullWidth
                                                        autoComplete="current-password"
                                                        margin="normal"
                                                        onChange={this.onChange}
                                                        defaultValue=""
                                                    />
                                                    <TextField
                                                        id="repassword"
                                                        label="Re-Enter Password"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        type="password"
                                                        placeholder="Re-Enter Password "
                                                        fullWidth
                                                        autoComplete="current-password"
                                                        margin="normal"
                                                        onChange={this.onChange}
                                                    />
                                                    <TextField
                                                        id="mobile"
                                                        label="Mobile"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={this.onChange}
                                                        placeholder="Enter mobile number "
                                                        //helperText="Full width!"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                    <Button
                                                        variant="contained" 
                                                        color="primary" 
                                                        size="small"
                                                        className={classNames(classes.button, classes.fbRoot)}
                                                        onClick={this.onSignup}
                                                    >
                                                        Sign up
                                                    </Button>
                                                    <p> Already have an account? <a href="#" onClick={this.toggleLogin}>Login here</a> </p>
                                                    <p>By clicking ‘Sign up, <a href="#">I agree to terms of Service.</a></p>
                                                </div>
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            </form>
                        </div>
                    </Modal>

                </div>
            );
        }
    }));

const styles = theme => ({
    root: {
        flexGrow: 1,
        minWidth: 700,
        padding: theme.spacing(2),
    },
    button: {
        margin: '5px',
        minWidth: '200px'
    },
    fbRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '6px 12px',
        border: '1px solid',
        backgroundColor: '#3a6ace',
        borderColor: '#007bff',
    },
    gleRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '6px 12px',
        border: '1px solid',
        backgroundColor: '#dc363c',
        borderColor: '#dc363c',
    },
    twtRoot: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '6px 12px',
        border: '1px solid',
        backgroundColor: '#20a8e2',
        borderColor: '#20a8e2',
    },
    githubRoot:{
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        padding: '6px 12px',
        border: '1px solid',
        backgroundColor: '#24292e',
        borderColor: '#20a8e2',
    },
    image: {
        width: 128,
        height: 128,
        textAlign: 'center',
        marginLeft: '30px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    modalRoot: {
        flexGrow: 1,
        minWidth: 600,
    }
});

LoginComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const Login = withStyles(styles)(LoginComponent);

