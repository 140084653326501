import './team.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Modal } from '../../../rc-core';
import { observer, inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Group from '@material-ui/icons/Group';
import { CreateNewTeam } from './manage-team/create-team';
import ManageTeamMembers from './manage-team/edit-team-members';
import { options } from './team-data';
import * as _ from 'lodash';

class ManageTeam extends Component {

    state = {
        show: false,
        newTeam: {},
        enableEditMode: true,
        options: options[0].members,
        team: {}
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            enableEditMode: nextProps.enableEditMode, 
            team: nextProps.enableEditMode===true?nextProps.team:[],//in case of edit mode pick selected team
            show: nextProps.show 
        });
    }

    onModalClose = () => {
        this.setState({ show: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    onCreateNewTeam = async (newTeam) => {
        const { sidePanel } = this.props.rootStore;
        try {
            const team = await sidePanel.createTeam(newTeam);
            sidePanel.setTeamPageProps({
                enableEditMode: true 
            });
            this.setState({team});
        }
        catch (e) { console.log(e); }
    }


    onEditTeamMembers = (team) => {
        const { sidePanel:{updateTeam} } = this.props.rootStore;
        this.setState({ team }, ()=>{
            updateTeam(team);
        });
    }

    render() {
        const { classes = {} } = this.props;

        return (
            <div className={classes.container}>
                <Modal open={this.state.show}
                    onClose={this.onModalClose}
                    maxWidth="sm"
                    fullWidth
                    classes={classes.root}
                    actionNode={(
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={this.onModalClose}
                            color="primary"
                        >Close </Button>
                    )}
                >
                    <div className="modal-body" style={{ margin: '10px' }}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm container>
                                <Logo classes={classes} />
                                {!this.state.enableEditMode ?
                                    <CreateNewTeam onCreateNewTeam={this.onCreateNewTeam} classes={classes} /> :
                                    <ManageTeamMembers
                                        options={this.state.options}
                                        team={this.state.team}
                                        onChange={this.onEditTeamMembers}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        flexGrow: 1,
        minWidth: 500,
        padding: theme.spacing(2),
    },
    editTeamContainer: {
        minWidth: '500px'
    },
    heading: {
        paddingLeft: '25px'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    logoContainer: {
        background: '#ddd',
        display: 'flex',
        justifyContent: 'center',
        padding: '6px',
        width: '100%'
    },
    avatar: {
        width: '50px',
        height: '50px',
        background: '#43a047',
        color: '#fff'
    },
    editRoles: {
        cursor: 'pointer'
    },
    searchBox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0px 0px 25px',
        maxWidth: '400px',
        height: 'auto'
    }

});

ManageTeam.propTypes = {
    classes: PropTypes.object,
};


const ManageTeamWrap = inject("rootStore")(observer(ManageTeam));
export default withStyles(styles)(ManageTeamWrap);




const Logo = ({ classes = {}, }) => {
    return (<div className={classes.logoContainer}>
        <Avatar className={classes.avatar}>
            <Group />
        </Avatar>
    </div>)
}