import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import '../rc-playground.css';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import Button from '@material-ui/core/Button';
import * as _ from 'lodash';



class ApiAutoComplete extends Component {
    state = {
        disabled: false,
        dropup: false,
        flip: false,
        highlightOnlyResult: false,
        minLength: 0,
        selectHintOnEnter: false,
        open: false,
        selectedMethod: 'GET'
    };

    componentWillReceiveProps(nextProps) {
        const method = (nextProps.payload
            && nextProps.payload.request &&
            nextProps.payload.request.method) || 'GET'

        this.setState({ selectedMethod: method });
    }

    handleToggle = (e) => {
        //console.log({ e: e.target, e })
        //e.stopImmediatePropagation();
        // e.defaultPrevented();
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = e => {
        this.setState({ open: false });
    };


    onMethodSelect = (event) => {
        const option = event.target.innerText;
        this.setState({ open: false, selectedMethod: !_.isEmpty(option) ? option : 'GET' },
            () => {
                this.props.onMethodSelect(this.state.selectedMethod);
            });

    }

    onInputChange = (value) => {
        this.props.onInputChange(value)
    }

    onChange = (value) => {
        const label = _.get(value, '[0].name');
        if (label) {
            this.props.onInputChange(label)
        }
    }

    handleKeyPress = (e) => {
        if (e.key == 'Enter') {
            this.props.onExecute();
        }
    }


    render() {

        const { classes = {} } = this.props
        const {
            open,
        } = this.state;

        return (<div className="query_bar">
            <div className="query__method">
                <Button
                    onClick={this.handleToggle}
                    className="query__method-btn"
                    color="primary"
                    variant="contained"
                    size="medium"
                >
                    {this.state.selectedMethod}
                    <ArrowDropDown className="query__method-icon" onClick={() => { }} />
                </Button>

                <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                    <Paper className="query__method-list" style={!open ? { overflow: 'hidden', height: '0px' } : {}} >
                        <MenuList role="menu">
                            <MenuItem onClick={this.onMethodSelect}>GET</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>POST</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>PUT</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>DELETE</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>CONNECT</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>HEAD</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>OPTIONS</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>DELETE</MenuItem>
                            <MenuItem onClick={this.onMethodSelect}>TRACE</MenuItem>
                        </MenuList>
                    </Paper>
                </Grow>
            </div>
            <Typeahead
                labelKey="name"
                onChange={this.onChange}
                options={this.props.options}
                selected={[this.props.payload.name]}
                onInputChange={this.onInputChange}
                placeholder="Filter"
                multiple={false}


                id={'rc-playground-typehead-v2'}
            />
            <Button
                aria-owns={open ? 'menu-list-grow' : null}
                aria-haspopup="true"
                onClick={this.props.onExecute}
                className="query__method-btn test"
                color="primary"
                variant="contained"
                size="medium"
            >
                <PlayCircleFilled />
            </Button>
            <Button
                aria-owns={open ? 'menu-list-grow' : null}
                aria-haspopup="true"
                onClick={this.props.onSave}
                className="query__method-btn"
                style={{ marginLeft: '2px' }}
                color="primary"
                variant="contained"
                size="medium"
            >
                {'SAVE'}
            </Button>
        </div>);
    }
}


ApiAutoComplete.propTypes = {
    classes: PropTypes.object.isRequired,
    payload: PropTypes.object
};

ApiAutoComplete.defaultProps = {
    payload: {},
    options: []
};

const styles = theme => ({
    root: {

    },
    typeahead: {

    },
    typeaheadDisabled: {
        pointerEvents: 'none'
    },
    customInput: {
        border: '1px solid rgb(221, 221, 221)',
        textAlign: 'left',
        fontSize: '12px',
        color: '#999',
        fontWeight: '500',
        outline: 'none',
        minHeight: '30px',
        maxWidth: '680px',
        padding: '5px',
    }
});

export default withStyles(styles)(ApiAutoComplete);

