import { decorate, observable, computed, action, autorun, toJS } from "mobx";
import { dbService } from '../services/db-service';
import { localDBService } from "../services/local-db-service";
import * as _ from 'lodash';
import treeLookup from '../../rc-side-panel/services/tree-lookup';


class Store {

    isAnonymous = false;
    showLoginPage = false;
    nightMode = false;
    serverAvailable = true;
    loginPageState = {};
    appID = null;

    apiData = [];
    documents = [];

    user = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        /*dbService.registerSyncEvent({
            setLoginInfo: this.setLoginInfo,
            syncData: (abc)=>{} //this.syncData
        })*/

        //this.loadData();
    }

    loadData = async () => {

        const collections = await localDBService.getCollections();

        const documents = await localDBService.getDocuments();

        const mapCollections = _.map(collections, (collection) => {
            const data = _.filter(documents, (document) => document.parent_id === collection.id) || '';

            return { ...collection, data: _.orderBy(data, 'created') };
        });

        this.syncData({ collection: mapCollections });
    }

    setLoginInfo = ({ user, appID }) => {
        this.isAnonymous = user.isAnonymous;
        this.isLogedIn = !_.isEmpty(user);
        this.user = user;
        this.appID = appID;

        if (this.isLogedIn) {
            this.showLoginPage = false;
        }
    }

    syncData = ({ collection, api }) => {

        /*let apidata = _.isArray(collection) ? collection : [collection];

      apidata.map(data=> {
 
        if(data.root){
            this.apiData= treeLookup.syncCollection(this.apiData,data);
          }else {
            this.apiData=treeLookup.syncSubCollection(this.apiData,data);
          }
 
          //this.apiData=treeLookup.syncSubCollection(this.apiData,data);
       });*/

        this.processData(this.apiData, { collection });

        this.rootStore.sidePanel.notifySidePanel();

    }

    processData(list, { collection }) {
        let apidata = _.isArray(collection) ? collection : [collection];
        apidata = _.sortBy(apidata, "created");
        apidata.map(data => {

            if (data.root) {
                treeLookup.syncCollection(list, data);
            } else {
                treeLookup.syncSubCollection(list, data);
            }
        });
    }

    updateLoginPageState = (key, value) => {
        this.loginPageState[key] = value;
    }

    login = () => {
        dbService.login(toJS(this.loginPageState));
    }

    signup = () => {
        dbService.signup(toJS(this.loginPageState));
    }

    setLoggedInFlag() {

    }

    toggleLoginPage = (state = false) => {
        this.showLoginPage = state;
    }


    syncStoreState(data) {

    }

    syncRemoteState(data) {

    }

    setServerAvailable = (status) => {
        this.serverAvailable = status;
    }

    toggleNightMode = (flag = false) => {
        this.nightMode = flag;
    }

    getUser = () => {
        return toJS(this.user)
    }

    async googleSignInWithPopup() {
        await dbService.googleSignInWithPopup();
    }

    async facebookSignInWithPopup() {
        await dbService.facebookSignInWithPopup();
    }

    async twitterSignInWithPopup() {
        await dbService.twitterSignInWithPopup();
    }

    async githubSignInWithPopup() {
        dbService.githubSignInWithPopup();
    }

    async signOut() {
        await dbService.sighOut();
    }

    isAnonymousUser = () => {
        return this.user.isAnonymous;
    }

    filterSelected = (view, action) => {
        console.log({ view, action });
    }
}

decorate(Store, {
    isLogedIn: observable,
    isAnonymous: observable,
    showLoginPage: observable,
    user: observable,
    serverAvailable: observable,

    nightMode: observable,
    loginPageState: observable,

    setLoginInfo: action,
    syncAppState: action,
    toggleLoginPage: action,
    updateLoginPageState: action,
    login: action,
    signup: action,
    toggleNightMode: action,
    getUser: action,
    isAnonymousUser: action,
    setServerAvailable: action,
    filterSelected: action,
    loadData: action,
});


export const commonStore = Store;