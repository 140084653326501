import React, { Component } from 'react';
import './header-value-select.css';
import { Typeahead } from 'react-bootstrap-typeahead';

class HeaderSelect extends Component {

    state = {
        options: [],
        //selected: "",
    }

    onInputChange = (value) => {
       
    }

    onChange = (value) => {
        
    }

    onSearch = (value) => {

    }

    onBlur = (event) => {
        this.props.setValue(event.target.value);
    }

    getOptions() {
        const selectedHeader = this.props.data.header;
        const value = this.props.context.headerData[selectedHeader];
        this.setState({ options: value || [] });
    }

    loadOptions = () => {
        this.getOptions()
    }

    getDefaultSelected() {
        return this.props.getValue()?[this.props.getValue()]:[];
       }

    render() {
        return (
            <div className="header-value-select">
                <Typeahead
                    {...this.state}
                    //emptyLabel={''}
                    labelKey="name"
                    placeholder="Value"
                    bsSize="small"
                    clearButton={true}
                    positionFixed={true}
                    allowNew= {false}
                    options={this.state.options}
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    onInputChange={this.onInputChange}
                    onBlur={this.onBlur}
                    onFocus={this.loadOptions}
                    defaultSelected={this.getDefaultSelected()}
                    id={'header-value-typehead'}
                />
            </div>
        )
    }


}


export default HeaderSelect