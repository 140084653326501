import React, { Component } from "react";
import "./rc-playground.css";
import OpenTabs from "../components/open-tabs/open-tabs";
import * as _ from "lodash";
import { observer, inject } from "mobx-react";
import Header from "./header/header";
import ApiPayload from "./api-payload/api-payload";
import { withStyles } from "@material-ui/core/styles";
import treeLookupService from "../../rc-side-panel/services/tree-lookup";
import SavePayloadModal from "./save-payload-modal/save-payload-modal";
import { Modal } from "../../rc-core";
import Button from "@material-ui/core/Button";
import CLISteps from "./cli-steps/cli-steps";

const RcPlayground = inject("rootStore")(
  observer(
    class RcPlaygound extends Component {
      state = {
        open: false,
        openCLIConfirm: true,
      };

      getStore() {
        return this.props.rootStore.playground;
      }

      onTabSelected = ({ id, label }) => {
        this.getStore().openTab({ id, label });
      };

      onTabClose = ({ id, pid }) => {
        const { openedTabs } = this.getStore();
        const tabTobeClosed = _.find(openedTabs.slice(), { id });

        if (tabTobeClosed && tabTobeClosed.changed) {
          this.setState({ openSaveConfirm: true, id, pid });
          return;
        }
        this.getStore().closeTab({ id });
      };

      onModalClose = () => {
        this.setState({ openSaveConfirm: false, id: undefined });
      };

      onLabelEdit = ({ id, label }) => {
        this.getStore().onLabelEdit({ id, label });
      };

      setSelectedTab = (index) => {
        this.getStore().setSelectedTab(index);
      };

      updateSelected = (key, value) => {
        this.getStore().updateSelected(key, value);
        //this.setState({stateUpdated: true});
      };

      onExecute = () => {
        this.getStore().onExecute();
      };

      getPayloadData = (key) => {
        return this.getStore().getPayloadData(key);
      };

      createNewTab = () => {
        this.getStore().createNewTab();
      };

      onCollectionSelect = (value) => {
        this.setState({ selectedCollection: value });
      };

      getSelectedCollections() {
        //const selectedAPI  = this.props.selectedAPI;
        const collections = this.getCollectionList() || [];
        const collection = _.find(collections, { id: this.state.pid });

        return (
          this.state.selectedCollection || (collection ? [collection] : [])
        );
      }

      onSaveApiConfirm = (parent_id) => {
        const { id, label } = this.state;
        this.getStore().saveTabChanges({
          id,
          label,
          parent_id,
          selectedCollection: this.getSelectedCollections(),
        });
        //this.getStore().closeTab({id});
      };

      onDiscard = () => {
        const { id, label } = this.state;
        this.getStore().discardTabChanges({ id, label });
      };

      getCollectionList() {
        const apiData = this.getStore().getApiData();
        const collection = [];
        treeLookupService.findCollection(apiData, collection);
        return collection;
      }

      getAllAPIList() {
        const apiData = this.getStore().getApiData();
        const apiList = treeLookupService.findAllAPIList(apiData);
        return apiList;
      }

      onPayloadSave = () => {
        const { selectedAPI } = this.getStore();
        if (selectedAPI.parent_id) {
          this.setState({ pid: selectedAPI.parent_id }, () => {
            this.getStore().saveTabChanges({
              collections_id: selectedAPI.parent_id,
              id: selectedAPI.id,
              label: selectedAPI.label,
              selectedCollection: this.getSelectedCollections(),
              parent_id: selectedAPI.parent_id,
            });
          });
        } else {
          this.setState({ openSaveConfirm: true, id: selectedAPI.id });
        }
        //TODO save me
      };
      onCliModalClose = (e) => {
        this.setState({ openCLIConfirm: false });
      };

      render() {
        const { classes = {} } = this.props;
        const {
          isExecuting,
          openedTabs,
          selectedAPI,
          rootStore: {
            commonStore: { serverAvailable },
          },
        } = this.getStore();
        const { emptyLabel, enableRequestPayload } = this.state;

        return (
          <div className="rcpg">
            <Header createNewTab={this.createNewTab} />
            <div className="main">
              <OpenTabs
                list={openedTabs.slice()}
                onTabSelected={this.onTabSelected}
                selectedAPI={selectedAPI}
                onTabClose={this.onTabClose}
                onLabelEdit={this.onLabelEdit}
                setSelectedTab={this.setSelectedTab}
                updateSelected={this.updateSelected}
              >
                {selectedAPI && (
                  <ApiPayload
                    payload={selectedAPI}
                    updateSelected={this.updateSelected}
                    isExecuting={isExecuting}
                    onExecute={this.onExecute}
                    getPayloadData={this.getPayloadData}
                    onSave={this.onPayloadSave}
                    allAPIlist={this.getAllAPIList()}
                  />
                )}
              </OpenTabs>
              <SavePayloadModal
                open={this.state.openSaveConfirm}
                onClose={this.onModalClose}
                onDiscard={this.onDiscard}
                onSaveApiConfirm={this.onSaveApiConfirm}
                options={this.getCollectionList() || []}
                onChange={this.onCollectionSelect}
                selectedAPI={selectedAPI}
                selectedCollection={this.getSelectedCollections()}
              />
              <div className={"cli-box"}>
                {!serverAvailable && <CLISteps />}
              </div>
              <Modal
                open={!serverAvailable && this.state.openCLIConfirm}
                onClose={this.onCliModalClose}
                maxWidth="md"
                fullWidth
                classes={classes.root}
                actionNode={
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.onCliModalClose}
                      color="primary"
                    >
                      Close
                    </Button>
                  </div>
                }
              >
                <div className={"cli-box"}>
                  <CLISteps />
                </div>
              </Modal>
            </div>
          </div>
        );
      }
    }
  )
);

const styles = (theme) => ({
  button: {
    margin: "0 5px",
    color: "#fff !important",
  },
});

export default withStyles(styles)(RcPlayground);
