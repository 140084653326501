import './team.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import { RcSelect } from '../../../rc-core';
import Typography from '@material-ui/core/Typography';
import ManageTeam from './manage-team';
import CollectionList from '../list/list';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import * as _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
 

class Team extends Component {

    state = {
 
    }

    componentWillMount() {
        const { commonStore: { user } } = this.getStore();
        if (user && user.uid) {
            this.getStore().sidePanel.loadTeams(user.uid);
        }
    }

    onChange = (team) => {
       
      this.getStore().sidePanel.setSelectedTeam(team);
        
    }

    onEditTeam = () => {
        this.getStore().sidePanel.setTeamPageProps({
            showModal: true, enableEditMode: true
        });
    }

    onTeamAdd = () => {
        this.getStore().sidePanel.setTeamPageProps({
            showModal: true, enableEditMode: false
        });
    }

    onClose = () => {
        this.getStore().sidePanel.setTeamPageProps({
            showModal: false, enableEditMode: true
        });
    }

    getStore() {
        return this.props.rootStore;
    }

    handleClick = (e, item, index) => {
        //const {teamData}= this.getStore().sidePanel;
        const data=this.getStore().sidePanel.getTeamData();
        this.getStore().sidePanel.toggaleExpand(item,data);
    };

    onActionClick = (e, item, updatedValue, eventName, apiNode) => {

        if (!_.isEmpty(updatedValue) && !_.isEmpty(eventName)) {
            if (eventName === 'addFolder') {
                item.name = updatedValue;
                this.getStore().sidePanel.updateSubCollection(item);
            }
            else if (eventName === 'addAPI') {
                apiNode.name = updatedValue;
                apiNode.label = updatedValue;
                this.getStore().sidePanel.addAPI(item, apiNode);
            }
            return
        }
        this.setMenuOptions({ e, item, apiNode, eventName });

    }

    openTab = (node, pid) => {
        this.getStore().playground.openTab(node, pid);
    }

    getData() {
        return this.getStore().sidePanel.getTeamData() || [];
    }

    isUpdated() {
        return this.getStore().sidePanel.storeUpdated;
    }

    renderVthumb = ({ style, props }) => {
        const { classes = {} } = this.props
        return (<div {...props} style={{ ...style }} className={classes.vthumb} />);
    }

    handleScroll = (e) => {
        if (e.target.scrollTop > 0) {
            this.setState({ showShadow: true })
        } else {
            this.setState({ showShadow: false })
        }
    }

    getTeams() {
        const { sidePanel: { teams } } = this.getStore();
        return teams.map(team => ({ id: team.id, label: team.name, value: team.id })) || [];
    }

    getTeamSize() {
        const { sidePanel: { teams } } = this.getStore();
        return teams.length;
    }

    getSelectedValue() {
        const { sidePanel: { selectedTeam } } = this.getStore();
        const team = toJS(selectedTeam);
        if (team) { return { id: team.id, label: team.name, value: team.id }; }
        return _.get(this.getTeams(), "[0].id", undefined);
    }

    getSelectedTeam() {
        const { sidePanel: { selectedTeam, getTeams } } = this.getStore();
        const team = toJS(selectedTeam)
       
        return team;
    }

    getTeamMembers() {
        const selectedTeam = this.getSelectedTeam();
        return _.get(selectedTeam, "participants", []).length;
    }

    getTeamPageState() {
        const { sidePanel: { teamPage } } = this.getStore();
        return teamPage;
    }

    getLoadingStatus() {
        const { sidePanel: { isLoadingTeamData,storeUpdated } } = this.getStore();
        return storeUpdated || isLoadingTeamData;
    }

    render() {
        const { classes = {} } = this.props;
        const { showShadow } = this.state;
        const { enableEditMode, showModal } = this.getTeamPageState()

        return (
            <div className={`team ${classes.container}`}>
                <div className={showShadow ? classes.buttomShadow : ''}>
                    <Typography className={classes.heading+ " "+this.getLoadingStatus()}>
                        Select Team({this.getTeamSize()})
                    </Typography>
                    <div className={classes.header}>
                        <RcSelect
                            options={this.getTeams()}
                            onChange={this.onChange}
                            value={this.getSelectedValue()}
                        />

                        <Typography variant="body2" color="secondary" className={classes.caption} onClick={this.onEditTeam}>
                            See team members({this.getTeamMembers()})
                    </Typography>
                        <Tooltip id="tooltip-icon" title="Create new team" placement="top" >
                            <Button size="small" color="primary" className={classes.button} onClick={this.onTeamAdd} >
                                <Add />
                                Add Team
                    </Button>
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.main + " " + this.isUpdated()}>
                    <Scrollbars
                        style={{ height: 'calc(100vh - 200px)' }}
                        renderThumbVertical={this.renderVthumb}
                        onScroll={this.handleScroll}
                    >
                        <CollectionList
                            data={this.getData()}
                            openTab={this.openTab}
                            handleClick={this.handleClick}
                            onActionClick={this.onActionClick}
                        />
                    </Scrollbars>
                </div>
                <ManageTeam
                    show={showModal}
                    enableEditMode={enableEditMode}
                    onClose={this.onClose}
                    team={this.getSelectedTeam()}
                />
            </div>
        );
    }
}

const styles = theme => ({
    container: {
    },
    button: {
        //fontSize: '10px',
        fontWeight: 500,
        minHeight: '25px',
        borderRadius: '20px',
        padding: 0,
        //color: theme.palette.primary.main
    },
    main: {
        display: 'flex',
        width: '100%'
    },
    header: {
        display: 'flex',
        padding: '5px 16px',
        justifyContent: 'space-between'
    },
    heading: {
        display: 'flex',
        justifyContent: 'left',
        fontSize: '0.7rem',
        margin: '10px 25px 0px 25px',
        fontWeight: 500,
        color: '#999',
        textTransform: 'uppercase'
    },
    caption: {
        cursor: 'pointer',
        color: '#999',
        fontWeight: 400
    },
    vthumb: {
        backgroundColor: theme.custom.sidePanelScrollbar[theme.palette.type],
    },
    buttomShadow: {
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)'
    }
});

Team.propTypes = {
    classes: PropTypes.object,
};


const TeamWrap = inject("rootStore")(observer(Team));
export default withStyles(styles)(TeamWrap);


