
import { RcPlaygroundStore } from './rc-playground/store';
import { RcSidePanelStore } from './rc-side-panel/store';
import { commonStore, dbStore } from './rc-core/store';



class RCStore {
    sidePanel = new RcSidePanelStore(this);
    playground = new RcPlaygroundStore(this);
    commonStore = new commonStore(this);
    dbStore = new dbStore(this)
}

export default new RCStore();




