import './open-tabs.css';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import * as _ from 'lodash';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 10}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class ScrollableTabsButtonAuto extends React.Component {

  state = {
    openSaveConfirm: false,
    mouseEvent: {},
    selectedCollection: undefined,
    editMode: {}
  }

  handleChange = (e, index) => {
    const mouseEvent = {
      id: e.target.id,
      name: e.target.innerHTML,
      pid: (e.target.attributes.pid && e.target.attributes.pid.nodeValue)
    }
    const { id, name, pid } = mouseEvent;
    if (name === 'cancel') {
      this.onTabClose({ id, pid });
    }
    else if (name === 'edit') {
      this.toggaleEditMode(index, true);
    }
    else {
      this.props.setSelectedTab(index);

      if (this.state.editMode[index]) {
        //Make all other tab uneditabke in case of focus lost.
        this.toggaleEditMode(index, true);
      } else {
        this.toggaleEditMode(index, false);
      }

    }

  };

  getAttrData(e) {
    const mouseEvent = {
      id: e.target.id,
      name: e.target.innerHTML,
      pid: (e.target.attributes.pid && e.target.attributes.pid.nodeValue)
    };
    return mouseEvent;
  }


  onTabClose = ({ id, pid }) => {
    this.props.onTabClose({ id, pid })
  }



  onEditLabel = (e, val) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onEditLabel(val);
  }

  getActiveTabValue = () => {
    const { selectedAPI } = this.props;
    const index = _.findIndex(this.props.list, (value) => {
      return value.id === selectedAPI.id
    });
    return index >= 0 ? index : 0
  }

  onTabSelected = (e) => {
    //console.log({ val: e.target.id })
  }

  onMouseEnter = (e) => {
    const mouseEvent = {
      id: e.target.id,
      name: e.target.innerHTML,
      pid: e.target.attributes.pid.nodeValue
    }
    //this.setState({mouseEvent});
  }

  onMouseLeave = (e) => {
    //this.setState({mouseEvent: {}});
  }

  onSave = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.updateSelected('label', this.inputRef.value);
    this.toggaleEditMode(index, false);
  }

  onCancel = (e, index) => {

    e.stopPropagation();
    e.preventDefault();
    const { id, pid } = this.getAttrData(e);
    this.onTabClose({ id, pid });
  }

  onEdit = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    this.toggaleEditMode(index, true);
  }

  onInputKeyPress = (e, index) => {
    if (e.key == 'Enter') {
      this.onSave(e, index);
    }
    if (e.key == 'Esc') {
      this.toggaleEditMode(index, false);
    }
  }

  toggaleEditMode = (index, flag) => {
    const obj = {};
    obj[index] = flag;
    this.setState({ editMode: obj });
  }

  getAdjecentClass(index) {
    const {classes}= this.props;

    if(index-1===this.getActiveTabValue()){
      return " "+classes.tabRightToActive;
    }
    else if(index+1===this.getActiveTabValue()){
      return " "+classes.tabLeftToActive;
    }

    else if(index===this.getActiveTabValue()){
      return " "+classes.tabActive;
    }
    return "";

  }


  render() {
    const { classes } = this.props;

    if (this.props.list.length == 0) {
      return "";
    }

    return (
      <div className={classes.root} onClick={this.onTabSelected}>
        <AppBar position="static" color="default" classes={{
          root: classes.appBar
        }}>
          <Tabs
            value={this.getActiveTabValue()}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            scrollable="true"
            scrollButtons="on"
            fullwidth="true"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.flexContainer
            }}
          >

            {
              (this.props.list.slice() || []).map((tab, index) => {
                return <Tab
                  classes={{
                    root: classes.tabRoot+this.getAdjecentClass(index),
                    selected: classes.tabSelected,
                    wrapper: this.getActiveTabValue() === index?classes.tabWrapperActive:classes.tabWrapper
                  }}
                  key={index+tab.id}
                  color="primary"
                  label={
                    !this.state.editMode[index] ?
                      <div className="tab__container">
                        <div className="tab__label">
                          {tab.label}
                        </div>
                        <div className="tab__actions">
                          {this.getActiveTabValue() === index &&
                            <Icon
                              className={"tab__close-icon " + (tab.changed ? "tab__close-icon--changed" : "")}
                              id={tab.id}
                              pid={tab.pid}
                              onClick={(e) => this.onEdit(e, index)}
                            >edit
                        </Icon>
                          }
                          <Icon
                            className={"tab__close-icon " + (tab.changed ? "tab__close-icon--changed" : "")}
                            id={tab.id}
                            pid={tab.pid}
                            onClick={(e) => this.onCancel(e, index)}
                          >cancel
                        </Icon>
                        </div>
                      </div>
                      :
                      <div className="tab__container">
                        <input defaultValue={tab.label}
                          className="tab__inbox"
                          ref={(v) => this.inputRef = v}
                          onKeyPress={(e) => this.onInputKeyPress(e, index)}
                        />
                        <div className="tab__actions">
                        <Icon
                          className={"tab__close-icon "}
                          id={tab.id}
                          pid={tab.pid}
                          onClick={(e) => this.onSave(e, index)}
                        >check_circle
                        </Icon>
                         
                        </div>
                      </div>

                  }

                >
                </Tab>
              })
            }
          </Tabs>
        </AppBar>
        <TabContainer>
          {this.props.children}
        </TabContainer>
      </div>
    );
  }
}

ScrollableTabsButtonAuto.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array,
  value: PropTypes.number,
  onTabClose: PropTypes.func,
  onLabelEdit: PropTypes.func,
};

const styles = theme => {
  return ({
    root: {
      flexGrow: 1,
      width: '100%',
      //backgroundColor: theme.palette.background[theme.palette.type],
    },
    tabsRoot: {
      minHeight: '25px',
      //backgroundColor: theme.palette.background[theme.palette.type],
    },
    tabWrapper:{
      lineHeight: '25px',
      minWidth: '100px',
      padding: '0 10px',
      backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
    },
    tabWrapperActive: {
      backgroundColor: '#fff',
      borderRadius: '5px 5px 0px 0px',
      lineHeight: '25px',
      minWidth: '150px',
      padding: '0 10px',
    },
    tabRoot: {
      padding: '0',
      position: 'relative',
      overflow: 'hidden',
      maxWidth: '264px',
      minWidth: '72px',
      fontSize: '0.8rem',
      minHeight: '30px',
      fontWeight: '400',
      flexShrink: 0,
      textTransform: 'capitalize',
      backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
      borderRight: '1px solid #ddd',
      borderBottom: 0,

    },
    tabActive:{
      //background: '#fff',
      border:0
    },
    tabLeftToActive:{
      borderRight: 0,
      borderRadius: '0px 0px 10px 0px'
    },
    tabRightToActive:{
      borderRadius: '0px 0px 0px 10px'
    },
    labelContainer: {
      paddingTop: '0px',
      paddingBottom: '0px',
      position: 'relative',
      width: '100%',
      padding: '0 10px',
      minHeight:'30px'
    },
    tabLabel: {
      whiteSpace: 'nowrap',
      //position:'relative'
    },
    appBar: {
      boxShadow: 'none',
      borderBottom: 0,//theme.custom.border[theme.palette.type],
      //backgroundColor: '#fff'
      backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],

    },
    scrollButtons: {
      flex: '0 0 23px !important',
      backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
    },
    tabsIndicator: {
      backgroundColor:  '#fff'
    },
   
  }
  );
}

export default withStyles(styles)(ScrollableTabsButtonAuto);