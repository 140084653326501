import store from "../../store";

class WebSocketService {
  connect = (httURL) => {
    let URL = httURL.replace("https://", "wss://");
    URL = URL.replace("http://", "ws://");
    console.log({ httURL, URL });

    this.ws = new WebSocket(URL); //TODO get from config
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      store.commonStore.setServerAvailable(true);
      store.commonStore.loadData();
      console.log("connected");
    };

    this.ws.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages

      console.log({ data: evt.data });
      //this.addMessage(message)
    };
    this.ws.onclose = () => {
      console.log("disconnected");
      console.log("Socket is closed. Reconnect will be attempted in 1 second.");
      setTimeout(() => this.connect(URL), 1000);
      store.commonStore.setServerAvailable(false);
      // automatically try to reconnect on connection loss
    };
    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      store.commonStore.setServerAvailable(false);
      this.ws.close();
    };
  };
}

export const webSocketService = new WebSocketService();
