import React  from 'react'; 
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';

const ListHeader = ({ item, onActionClick }) => {
    const {id, name, root, open,editable, created } = item;

    let inputRef=null; 
  
    const getIconText = () => {
  
      if (root) {
        const list = (name || "").split(' ');
        if (list.length > 1) { return list[0].substring(0, 1).toUpperCase() + list[1].substring(0, 1).toUpperCase() }
        else if(list.length===1 && name.length>1){
          return name.substring(0, 2).toUpperCase();
        }
      }
      else {
        return <Icon>{open ? 'folder' : 'folder'}</Icon>;
      }
  
    }

    const handleKeyPress = (e) => {
      if(e.key == 'Enter'){
         onActionClick(e, item, inputRef.value,'addFolder')
      }
    }

   const getLastUpdated=()=> {
     return moment(created || new Date().getTime()).format('MM/DD/YYYY');
   }
   const getTotal= () => {
     return (item.chilren|| []).length+(item.data|| []).length;
   }

  return (
    <div className="item_header">
      <Avatar> {getIconText()} </Avatar>
      { 
      !editable?<ListItemText primary={name} 
        className="item_header_label" 
        secondary={`Last updated: ${getLastUpdated()}, Total: ${getTotal()}`}  />
      :
      <div>
        <input className={'editable_name'}  defaultValue={name}  ref={(v)=>inputRef=v}  onKeyPress={handleKeyPress}  />
        <CheckIcon id={id} className="save_icon" onClick={(e)=>onActionClick(e, item, inputRef.value,'addFolder')} />
      </div>
      }
      <div className="action">
        <Icon id={id} className="action__icon" onClick={(e)=>onActionClick(e, item)} >more_horiz</Icon>
      </div>
    </div>
    );
  }
  
  export  default ListHeader;