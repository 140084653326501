import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import HeaderView from './header-view/header-view';
import BodyView from './body-view/body-view';
import ScriptView from './script-view/script-view';
import Documentation from './documentaion/documentation';

import APIDescription from '../api-description/api-description';



function TabContainer(props) {
  return (
    <Typography component="div"  >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class CustomizedTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          scrollable="true"
          scrollButtons="on"
          
        >
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Headers"
            className="request-tab"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Body"
          />
          <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Test Suit"
          />
           <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Documentation"
          />
           <Tab
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Description"
          />

        </Tabs>
        {value === 0 && <TabContainer>
          <HeaderView  payload={this.props.payload}   setHeaders={this.props.setHeaders} />
        </TabContainer>}
        {value === 1 && <TabContainer>
          <BodyView  splitView={this.props.splitView}  payload={this.props.payload}  setBody={this.props.setBody}  getPayloadData={this.props.getPayloadData} />
        </TabContainer>}
        {value === 2 && <TabContainer>
          <ScriptView  payload={this.props.payload} setScript={this.setScript} />
        </TabContainer>}
       {value === 3 && <TabContainer>
          <Documentation   payload={this.props.payload}  setDocs={this.props.setBody}  getPayloadData={this.props.getPayloadData} />
        </TabContainer>}
        {value === 4 && <TabContainer>
           <APIDescription payload={this.props.payload} onDescriptionUpdate={this.props.onDescriptionUpdate} onSave={this.props.onSave} />
        </TabContainer>}

      </div>
    );
  }
}

CustomizedTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};



const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.custom.sidePanelBackground[theme.palette.type],
    minHeight: '30px',
    border: '1px solid #ddd'
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    minHeight: '30px',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    minHeight: '30px !important',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing(3),
  },
});
export default withStyles(styles)(CustomizedTabs);
