import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import './rc-grid.css';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'ag-grid/dist/styles/ag-theme-balham-dark.css';
import HeaderSelectRenderer from './cell-renderer/header-select/header-select';
import HeaderValueSelectRenderer from './cell-renderer/header-value-select/header-value-select';
import HeaderDescriptionRenderer from './cell-renderer/header-description/header-description';
import * as _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const RcGridInner = (props) => {

    let gridApi = null;
    let gridColumnApi = null;

    const onGridReady = (params) => {
        props.onGridReady(params);
        gridApi = params.api;
        gridColumnApi = params.columnApi;
    }

    const onResize = () => {
        this.gridApi.sizeColumnsToFit()
    }


    return (
        <div className="rc-grid">
            <div
                className={RcGridInner.defaultProps.theme}
                style={{ width: '100%', height: '200px', marginTop: '10px' }} >
                <AgGridReact
                    columnDefs={props.columnDefs}
                    defaultColDef={props.defaultColDef}
                    rowDragManaged={true}
                    animateRows={true}
                    enableSorting={true}
                    enableFilter={true}
                    onGridReady={onGridReady}
                    rowData={props.rowData}
                    rowSelection='multiple'
                    autoColumnDef={props.autoGroupColumnDef}
                    immutableData={true}
                    getRowNodeId={data => data.id}
                    gridOptions={{
                        frameworkComponents: {
                            headerSelectRenderer: HeaderSelectRenderer,
                            headerDescriptionRenderer: HeaderDescriptionRenderer,
                            headerValueSelectRenderer: HeaderValueSelectRenderer
                        },
                        ...props.gridOptions,
                    }}
                />
            </div>
        </div>
    );

}

RcGridInner.propTypes = {
    classes: PropTypes.object.isRequired,
};

RcGridInner.defaultProps = {
    mode: 'json',
    expanded: false,
    theme: 'xcode'
}

const styles = theme => {
    RcGridInner.defaultProps.theme = theme.custom.gridTheme[theme.palette.type];
    return {
        root: {
            display: 'flex',
            width: '100%',
        }
    };
}
export const RcGridSL = withStyles(styles)(RcGridInner);
