import React, { Component } from 'react';
import Select from 'react-select';
import './react-select.css';
import 'react-select/dist/react-select.css';

class ReactSelect extends Component {

    state = {
        id: "state-select",
        onBlurResetsInput: false,
        onSelectResetsInput: false,
        autoFocus: true,
        simpleValue: true,
        clearable: false,
        name: "selected-state",
        disabled: false,
        options: this.props.options,
        value: this.props.value,
        searchable: true
    }

    componentWillReceiveProps(nextProps){
        this.setState({options: nextProps.options, value: nextProps.value});
    }

    onChange = (val) => {
        this.setState({ value: val });
        this.props.onChange(val);
    }

    render() {
        return (<div className="rselect">
            <Select
                id="state-select"
                ref={(ref) => { this.select = ref; }}
                onBlurResetsInput={false}
                onSelectResetsInput={false}
                 
                options={this.state.options}
                clearable={this.state.clearable}
                name="selected-state"
                disabled={this.state.disabled}
                value={this.state.value}
                onChange={this.onChange}
                rtl={this.state.rtl}
                searchable={this.state.searchable}


            />
        </div>);

    }
}

export const RcSelect = ReactSelect