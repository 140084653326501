import React, { Component } from 'react';
import './header-description.css';
 
 

class HeaderSelect extends Component {

    state={
        position:{},
        open: false
    }

   //TO remove if note used
    fetchPostion() {
 
        if(this.description){
            const position= this.description.getBoundingClientRect();
            const {top, left,width, right, bottom, height} =position;
            
            this.setState({position:{top, left,width, right, bottom, height}});
          }
        
    }
 

    onKeyDown = (event) => {
        event.stopPropagation();
    }

    onBlur=()=>{
        this.props.setValue(this.textarea.value);
    }

    getFixedStyle() {
         
        return  {
            position:'relative',
            height: '25px',
            width:'100%',
            zIndex: '999',
            padding: '5px 5px',
          
        }
            
    }

    getContainer(){
        const cont= this.props.context && this.props.context.getHeaderContainer();
        console.log({cont});
        return cont;
    }

    render() {
        return (
            <div className="header-description" >
             
                     <textarea
                        onKeyDownCapture={this.onKeyDown}
                        onFocus={this.handleOpen}
                        style={this.getFixedStyle()}
                        ref={(v) => this.textarea = v}
                        defaultValue={this.props.getValue()}
                        onBlur={this.onBlur}
                        autoFocus={true}
                    />
                
            </div>
        )
    }

}


export default HeaderSelect